import { useMutation, useQueryClient } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import { z } from 'zod'
import { useTranslation } from 'react-i18next'

import { SettingsFormScema } from '../components/Settings/validation'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import { updatePaymentLinkConfiguration } from '@sweetspot/shared/data-access/api-platform'
import { PaymentLinkConfigurationPayload } from '../sheets/types'

export const usePaymentLinkUpdate = (courseId: number | undefined) => {
  const { addToast } = useToasts()
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const { mutate, isLoading, isSuccess, data } = useMutation(
    (payload: PaymentLinkConfigurationPayload) => updatePaymentLinkConfiguration(payload, courseId)
  )

  const updateConfiguration = async (formData: z.infer<typeof SettingsFormScema>) => {
    const payload: PaymentLinkConfigurationPayload = {
      down_payment_percent: formData.partial_payments ? formData.percentage : null,
      second_payment_duration: formData.last_payment_duration_days * 24 * 60 * 60,
      first_payment_duration: formData.first_payment_duration_days * 24 * 60 * 60,
    }

    mutate(payload, {
      onSuccess: async () => {
        queryClient.invalidateQueries([CLUB_QUERIES.COURSES, courseId])
        addToast(t('paymentLinks.paymentSettingsUpdated'), { appearance: 'success' })
      },
      onError: () => {
        addToast(t('paymentLinks.paymentSettingsUpdateFailed'), { appearance: 'error' })
      },
    })
  }

  const isIdle = !isSuccess && !isLoading

  return {
    updateConfiguration,
    isLoading,
    isSuccess,
    isIdle,
    data,
  }
}
