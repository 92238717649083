import { DURATION_TIME } from '../../consts'
import { secondsToDays } from '../../utils'

export const setFirstPaymentDurationFromSeconds = (seconds: number) => {
  if (!seconds) return DURATION_TIME.DAY

  const days = secondsToDays(seconds)

  return days === 1
    ? DURATION_TIME.DAY
    : days === 2
    ? DURATION_TIME.TWO_DAYS
    : DURATION_TIME.CUSTOM_DAYS
}

export const setLastPaymentDurationFromSeconds = (seconds: number) => {
  if (!seconds) return DURATION_TIME.MONTH
  const days = secondsToDays(seconds)

  return days === 1
    ? DURATION_TIME.DAY
    : days === 30
    ? DURATION_TIME.MONTH
    : DURATION_TIME.CUSTOM_DAYS
}
