import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'

export const holisticFilters = {
  rangeDispenser: {
    embedCode: 'c6ab4170581b7a796ce3f37c',
    secretKey:
      '7517bbe21c9b757181dbf4fdebe331161da9bd1221a98a62aff56926d445ba635cbf18e108d5148dce4f74247316c6678b1ab10e59151ed4c4211aaac8940fff',
    path: '/range-dispenser',
    accessKey: ACCESS_KEYS.PAGES.INSIGHTS.RANGE_DISPENSER,
    drillthroughs: null,
    filter: {
      organisation: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
    },
  },
  payouts: {
    embedCode: '2954d5a35a04fd87d986a6bb',
    secretKey:
      '7764e746567d92b52be3c98301ea1400a0f9314272794475d7469082042dc3d31d1006bb11084b1b683ca9721a65a6fd03c449645bb3667f51ed6b9cdf1b0a1d',
    path: '/transactions-and-payouts',
    accessKey: ACCESS_KEYS.PAGES.INSIGHTS.TRANSACTIONS,
    drillthroughs: null,
    filter: {
      club: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
      club_id: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
    },
  },
  revenuePerTypeofbookableresource: {
    embedCode: '6cbb81d545e7c97d5db95a9a',
    secretKey:
      'de40f162c365a2f6c0b0334073bd9a89495d56b9239ed992ba857db61fc5947a694b6751a5b580c6c3b257c21cb3409a80fd9a15ee7da2b4e7d50b472bafb2fe',
    path: '/club-overview',
    accessKey: ACCESS_KEYS.PAGES.INSIGHTS.CLUB_OVERVIEW,
    drillthroughs: {
      //Course
      '57784': {
        settings: {
          enable_export_data: false,
        },
        filters: {
          organisation: {
            hidden: true,
            default_condition: {
              operator: 'is',
              values: [] as number[],
              modifier: null,
              options: null,
            },
            venue_type: {
              hidden: true,
              default_condition: {
                operator: 'is',
                values: ['Golf course'],
                modifier: null,
                options: null,
              },
            },
          },
        },
      },
      //Sim
      '57796': {
        settings: {
          enable_export_data: false,
        },
        filters: {
          organisation: {
            hidden: true,
            default_condition: {
              operator: 'is',
              values: [] as number[],
              modifier: null,
              options: null,
            },
            venue_type: {
              hidden: true,
              default_condition: {
                operator: 'is',
                values: ['Simulator'],
                modifier: null,
                options: null,
              },
            },
          },
        },
      },
    },
    filter: {
      venue_id: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
      organisation: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
    },
  },
  golfCourse: {
    embedCode: '27545001127095373892afc8',
    secretKey:
      'a78418ac8507d4ad80909a0eee0dbbf05b2749c6e5a908d334f986c1212e4e7ca03f32ab6a7acfdab30e6f0474f86698a4f2cc72e7e9cfa0af9a93fe99e5433f',
    path: '/club-overview/golf-courses',
    accessKey: ACCESS_KEYS.PAGES.INSIGHTS.GOLF_COURSES,
    drillthroughs: null,
    filter: {
      venue_type: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: ['Golf course'],
          modifier: null,
          options: null,
        },
      },
      organisation: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
      venue_id: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
    },
  },
  simulator: {
    embedCode: 'd5644ed51193013f88203178',
    secretKey:
      '74a7b83bd3da03dbab77be736f9363a4ef3dd4d94a51f1d0bc38571f19d061d1c75388aad680f47f5c13b6004f818937f99d8a169cd09d92ce2cf894ca307fd6',
    path: '/club-overview/simulators',
    accessKey: ACCESS_KEYS.PAGES.INSIGHTS.SIMULATORS,
    drillthroughs: null,
    filter: {
      venue_type: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: ['Simulator'],
          modifier: null,
          options: null,
        },
      },
      organisation: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
      venue_id: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
    },
  },
  discountBreakdown: {
    embedCode: 'ec0f0ce7e9d5e24310cdbb45',
    secretKey:
      'a81a48c2a1a0cc8786058ffdf1c9965349fdd0b1225b12903dbf1f3a2c1ebfc6cffbe8ac31f0cb82dc2049f18b7c2cabc344d5bccae416e4b90d217841524db3',
    path: '/club-overview/discount-breakdown',
    accessKey: ACCESS_KEYS.PAGES.INSIGHTS.DISCOUNT_BREAKDOWN,
    drillthroughs: null,
    filter: {
      club_id: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
    },
  },
  occupancy: {
    embedCode: '6776e32aefae2f22d04b3c02',
    secretKey:
      '827599beb6ef6e3aba2f2db73d1ef5008fd5503424813461c137786c6f10f201fd9e5797dce0fba3bda3e2a4a7d27cba01f7188e063def7c8990c7f9cdc680f3',
    path: '/club-overview/occupancy',
    accessKey: ACCESS_KEYS.PAGES.INSIGHTS.OCCUPANCY,
    drillthroughs: null,
    filter: {
      club_id: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
      club_name: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
      venue_id: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
    },
  },
  membershipsAndMembershipPlayingPatterns: {
    embedCode: '322b4870ff00a529b4667206',
    secretKey:
      '2fe3fe1095ecaf76afa6298558a4f526a699951bdfbcfd46cd5a1d42b48778881319c699ea262a9ee5dd6e90ccc26daa4fa8de69bad89c767f15f094c7ead974',
    path: '/club-overview/membership-and-members',
    accessKey: ACCESS_KEYS.PAGES.INSIGHTS.MEMBERSHIPS_AND_MEMBERS,
    drillthroughs: null,
    filter: {
      club_id: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
      player_type: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: ['Member'],
          modifier: null,
          options: null,
        },
      },
    },
  },
  partnerships: {
    embedCode: 'fbf29a54f3c897d8d7c024e8',
    secretKey:
      '8ff2262a01a8c00f368d8e22f8345234e6711a1a69fb40ff064871e29bb16dcc6693cd7496657dd8223867a49b21f46f2f3fb3910d0155937f60d157330a44bd',
    path: '/club-overview/partnership',
    accessKey: ACCESS_KEYS.PAGES.INSIGHTS.PARTNERSHIPS,
    drillthroughs: null,
    filter: {
      club_id: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
      player_type: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: ['Partner'],
          modifier: null,
          options: null,
        },
      },
    },
  },
  membershipSales: {
    embedCode: '31d367e2da0ffe51634be4aa',
    secretKey:
      '03a541f82daf6a0657b3a8970e45987fe56db170559a1cefa173064eb40ecbc44356ba3e4ce9bc0e7489affbf78f8ef6b665fc94b99b8730e1c81befa3f1fdd7',
    path: '/membership-sales',
    accessKey: ACCESS_KEYS.PAGES.INSIGHTS.MEMBERSHIP_SALES,
    drillthroughs: null,
    filter: {
      club_name: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
      club_id: {
        hidden: true,
        default_condition: {
          operator: 'is',
          values: [],
          modifier: null,
          options: null,
        },
      },
    },
  },
}
