import { useState, useMemo, useCallback } from 'react'
import {
  Button,
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetHeaderRightIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { useBaysContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/BaysProvider'
import NumberOfBays from './NumberOfBays'
import Summary from './Summary'
import Details from './Details'
import { BaySideBarMode } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/types'
import { TrackingTech } from '@sweetspot/shared/types'
import { useTranslation } from 'react-i18next'
import { createBay } from '@sweetspot/shared/data-access/api-platform'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { useToasts } from 'react-toast-notifications'

const CreateBay = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [isBulkCreate, setIsBulkCreate] = useState<boolean>(false)
  const [numberOfBays, setNumberOfBays] = useState<number>(1)
  const [floor, setFloor] = useState<number>(0)
  const [roof, setRoof] = useState<boolean>(true)
  const [trackingTech, setTrackingTech] = useState<TrackingTech>(TrackingTech.NONE)
  const { selectedRange, openBaySideBar, toggleBaySideBar, reFetchBays } = useBaysContext()

  const { title, createButtonTitle } = useMemo(() => {
    if (numberOfBays === 1) {
      return {
        title: `${t('create')} 1 ${t('new')} ${t('settings.bays.bay')}`,
        createButtonTitle: `${t('create')}`,
      }
    }

    return {
      title: `${t('create')} [${numberOfBays}] ${t('new')} ${t('settings.bays.bays')}`,
      createButtonTitle: `${t('create')} ${numberOfBays} ${t('settings.bays.bays')}`,
    }
  }, [numberOfBays, t])

  const toggleBulkCreate = useCallback(() => {
    setIsBulkCreate((prev) => !prev)
    setNumberOfBays(isBulkCreate ? 1 : 2)
  }, [isBulkCreate])

  const handleCreateBay = useCallback(async () => {
    if (!selectedRange?.id) {
      return
    }

    try {
      const [, error] = await to(
        createBay({
          rangeId: selectedRange?.id,
          bay: {
            floor,
            roof,
            tracking_technology: trackingTech,
          },
        })
      )

      if (error?.errors?.length) {
        addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
        return
      }
      await reFetchBays()
      toggleBaySideBar()
    } catch (error) {
      addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
    }
  }, [addToast, floor, reFetchBays, roof, selectedRange?.id, toggleBaySideBar, t, trackingTech])

  return (
    <Sheet open={openBaySideBar} onOpenChange={() => toggleBaySideBar(BaySideBarMode.CREATE)}>
      <SheetContent>
        <SheetHeader>
          <SheetHeaderLeftIcon onClick={() => toggleBaySideBar()}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{title}</SheetTitle>
          <SheetHeaderRightIcon onClick={toggleBulkCreate}>
            <i
              key={String(isBulkCreate)}
              className={`${isBulkCreate ? 'fa-solid' : 'fa-regular'} fa-layer-group`}
            />
          </SheetHeaderRightIcon>
        </SheetHeader>
        <SheetCustomContent className="flex flex-col gap-6">
          {isBulkCreate && (
            <NumberOfBays numberOfBays={numberOfBays} setNumberOfBay={setNumberOfBays} />
          )}
          <Summary numberOfBays={numberOfBays} />
          <Details
            floor={floor}
            setFloor={setFloor}
            roof={roof}
            setRoof={setRoof}
            trackingTech={trackingTech}
            setTrackingTech={setTrackingTech}
          />
        </SheetCustomContent>
        {isBulkCreate && (
          <p className="text-background-mono-dark text-center">Bulk create is coming soon...</p>
        )}
        <SheetFooter>
          <Button
            disabled={isBulkCreate}
            onClick={handleCreateBay}
            className="min-h-touch-height-lg w-full"
          >
            {createButtonTitle}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

export default CreateBay
