import { Divider, EmptyState, EmptyStateTitle } from '@sweetspot/scramble-ds'
import { SearchInput } from './SearchInput'
import { PaymentItem } from './PaymentItem'
import { useGetPaymentLinks } from 'libs/club-portal/feature-payment-links/src/hooks/useGetPaymentLinks'
import { useTranslation } from 'react-i18next'
import { InView, useInView } from 'react-intersection-observer'
import { useEffect } from 'react'

export const LeftView = () => {
  const { t } = useTranslation()
  const { ref, inView } = useInView()
  const { paymentLinks, setSearchTerm, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetPaymentLinks()

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage && !isLoading) {
      fetchNextPage()
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage, isLoading])

  const handleSearch = (searchValue: string) => {
    if (searchValue.length > 3 || searchValue.length === 0) {
      setSearchTerm(searchValue)
    }
  }

  return (
    <div className="flex w-1/2 flex-col xl:w-2/3 ">
      <SearchInput onSearch={handleSearch} />
      <Divider variant="basic" />
      {isLoading ? (
        <div className=" mb-[200px] flex h-full w-full items-center justify-center">
          <EmptyState loading={isLoading} iconName="fa-loader" className="w-full">
            <EmptyStateTitle>{t('paymentLinks.paymentLoading')}</EmptyStateTitle>
          </EmptyState>
        </div>
      ) : (
        <div>
          {paymentLinks && paymentLinks.length > 0 ? (
            <>
              {paymentLinks?.map((paymentLink, index) => (
                <PaymentItem paymentLink={paymentLink} index={index} />
              ))}
              <InView>
                <div ref={ref}></div>
              </InView>
            </>
          ) : (
            <div className="mb-[300px] flex h-full w-full items-center justify-center">
              <EmptyState className="w-full">
                <EmptyStateTitle>{t('paymentLinks.paymentLinksShowInList')}</EmptyStateTitle>
              </EmptyState>
            </div>
          )}
          {isFetchingNextPage && (
            <div className="mt-10 flex w-full items-center justify-center">
              <div className="animate-[spin_3s_linear_infinite]">
                <i className="fa-regular text-text-dark text-brand-5xl fa-loader" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
