import { RadioGroup, RadioGroupItem, Stepper } from '@sweetspot/scramble-ds'
import { Controller, ControllerRenderProps, useWatch } from 'react-hook-form'
import { DURATION_TIME } from '../../consts'
import { LastPaymentDurationProps } from '../types'
import { SettingsFormScema } from './validation'
import { z } from 'zod'

export const LastPaymentDuration = ({ control, setValue }: LastPaymentDurationProps) => {
  const lastPaymentDuration = useWatch({
    control,
    name: 'last_payment_duration',
  })

  const lastPaymentCustomDays = useWatch({
    control,
    name: 'last_payment_duration_days',
  })
  const incrementCustomDaysPayment = () => {
    if (lastPaymentDuration !== DURATION_TIME.CUSTOM_DAYS) return
    if (lastPaymentCustomDays >= 60) return
    setValue('last_payment_duration_days', lastPaymentCustomDays + 1)
  }

  const decrementCustomDaysPayment = () => {
    if (lastPaymentDuration !== DURATION_TIME.CUSTOM_DAYS) return
    if (lastPaymentCustomDays <= 1) return
    setValue('last_payment_duration_days', lastPaymentCustomDays - 1)
  }

  const handleStepperChange =
    (
      field: ControllerRenderProps<z.infer<typeof SettingsFormScema>, 'last_payment_duration_days'>,
      upperLimit: number
    ) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      if (/^\d*$/.test(value)) {
        const numValue = Number(value)
        field.onChange(
          numValue === 0 ? '' : numValue > 0 && numValue <= upperLimit ? numValue : field.value
        )
      }
    }

  return (
    <Controller
      name="last_payment_duration"
      control={control}
      render={({ field }) => (
        <RadioGroup
          className="flex flex-row"
          onValueChange={(value) => {
            field.onChange(value)
            if (value === DURATION_TIME.DAY) {
              setValue('last_payment_duration_days', 1)
            } else if (value === DURATION_TIME.MONTH) {
              setValue('last_payment_duration_days', 30)
            } else if (value === DURATION_TIME.CUSTOM_DAYS) {
              setValue('last_payment_duration_days', 60)
            }
          }}
          value={field.value}
          defaultValue={field.value}
        >
          <div className="border-stroke-pale p-md h-full w-1/2 items-start border-r-2">
            <RadioGroupItem
              value={DURATION_TIME.DAY}
              id={`FINAL-${DURATION_TIME.DAY}`}
              label="24 hours"
              isActive={field.value === DURATION_TIME.DAY}
            />
            <RadioGroupItem
              value={DURATION_TIME.MONTH}
              id={DURATION_TIME.MONTH}
              label="30 days"
              isActive={field.value === DURATION_TIME.MONTH}
            />
          </div>
          <div className="py-md w-1/2 items-start">
            <RadioGroupItem
              value={DURATION_TIME.CUSTOM_DAYS}
              id={`FINAL-${DURATION_TIME.CUSTOM_DAYS}`}
              label="Custom days"
              isActive={field.value === DURATION_TIME.CUSTOM_DAYS}
            />
            <Controller
              name="last_payment_duration_days"
              control={control}
              render={({ field: fieldStepper }) => {
                return (
                  <Stepper
                    value={field.value === DURATION_TIME.CUSTOM_DAYS ? fieldStepper.value : 60}
                    increment={incrementCustomDaysPayment}
                    decrement={decrementCustomDaysPayment}
                    className={`ml-3 w-[140px] ${
                      lastPaymentDuration !== DURATION_TIME.CUSTOM_DAYS && 'opacity-60'
                    }`}
                    readOnly={lastPaymentDuration !== DURATION_TIME.CUSTOM_DAYS}
                    onChange={handleStepperChange(fieldStepper, 60)}
                    onBlur={() => {
                      const value = Number(fieldStepper.value)
                      if (value < 1) {
                        fieldStepper.onChange(1)
                      } else if (value > 60) {
                        fieldStepper.onChange(60)
                      }
                    }}
                  />
                )
              }}
            />
          </div>
        </RadioGroup>
      )}
    />
  )
}
