import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import {
  Button,
  Divider,
  Sheet,
  SheetContent,
  SheetCustomContent,
  SheetFooter,
  SheetHeader,
  SheetHeaderLeftIcon,
  SheetTitle,
} from '@sweetspot/scramble-ds'
import { useBallPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/BallPricing/BallPricingProvider'
import SpinnerLoader from '@sweetspot/club-portal-legacy/components/SpinnerLoader/SpinnerLoader'

import OpeningHours from './OpeningHours/OpeningHours'
import BucketSizes from './BucketSizes/BucketSizes'
import BallPrice from './BallPrice/BallPrice'
import PriceRounding from './PriceRounding/PriceRounding'
import { useCallback, useMemo } from 'react'

type BucketConfigurationProps = {
  open: boolean
  onOpenChange: () => void
}
const BucketConfiguration = ({ open, onOpenChange }: BucketConfigurationProps) => {
  const { t } = useTranslation()
  const { selectedRange, bucketConfiguration } = useBallPricingContext()
  const { onSave, isFetching, isUpdating } = bucketConfiguration

  const isLoading = useMemo(() => isFetching || isUpdating, [isFetching, isUpdating])
  const loaderText = useMemo(
    () => (isFetching ? t('sentences.fetchingPrices') : t('sentences.updatingPrices')),
    [isFetching, t]
  )
  const loaderDescription = useMemo(
    () =>
      isFetching
        ? t('sentences.fetchingPricesDescription')
        : t('sentences.updatingPricesDescription'),
    [isFetching, t]
  )

  const handleOnSave = useCallback(async () => {
    onSave?.(onOpenChange)
  }, [onSave, onOpenChange])

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="bg-background-base-neutral !max-w-[360px]">
        {isLoading && (
          <SpinnerLoader
            text={loaderText}
            description={loaderDescription}
            isOverlay
            button={{
              children: t('words.cancel'),
              onClick: onOpenChange,
              variant: 'inverted',
            }}
          />
        )}
        <SheetHeader className={cx({ 'blur-sm': isLoading })}>
          <SheetHeaderLeftIcon onClick={onOpenChange}>
            <i className="fa-regular fa-close" />
          </SheetHeaderLeftIcon>
          <SheetTitle>{selectedRange?.name}</SheetTitle>
        </SheetHeader>
        <SheetCustomContent className={cx('flex flex-col gap-4', { 'blur-sm': isLoading })}>
          <OpeningHours />
          <Divider className="mb-2" />
          <BucketSizes />
          <Divider className="mb-2" />
          <BallPrice />
          <Divider className="mb-2" />
          <PriceRounding />
        </SheetCustomContent>
        <SheetFooter className={cx({ 'blur-sm': isLoading })}>
          <Button onClick={handleOnSave} className="w-full">
            {t('sentences.updatePricingRules')}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

export default BucketConfiguration
