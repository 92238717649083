export const AMPLITUDE_EVENTS = {
  LOGIN_SCREEN_SEEN: 'Login Screen Seen',
  SIGN_IN_TAPPED: 'Sign In Tapped',
  OPERATIONAL_STATUS_TAPPED: 'Operational Status Tapped',
  TEE_SHEET_COURSES_SEEN: 'Tee Sheet Courses Seen',
  TEE_TIME_TAPPED: 'Tee Time Tapped',
  TEE_TIME_DOUBLE_TAPPED: 'Tee Time Double Tapped',
  COURSE_BOOKING_SCREEN_SEEN: 'Course Booking Screen Seen',
  TEE_TIME_SLOT_SELECTED: 'Tee Time Slot Selected',
  TEE_SLOTS_ADDED: 'Tee Slots Added',
  CONFIRM_BOOKING_TAPPED: 'Confirm Booking Tapped',
  PLAYERS_ADDED: 'Players Added',
  ABORT_BOOKING_TAPPED: 'Abort Booking Tapped',
  COURSES_TEE_SHEET_SEARCHED: 'Courses Tee Sheet Searched',
  TODAY_BUTTON_TAPPED: 'Today Button Tapped',
  COPY_LINK_TO_TEE_SHEET_TAPPED: 'Copy Link To Tee Sheet Tapped',
  EDIT_TEE_TIMES_TAPPED: 'Edit Tee Times Tapped',
  CANCEL_BOOKING_TAPPED: 'Cancel Booking Tapped',
  REFUND_AND_CANCEL_BOOKING_TAPPED: 'Refund And Cancel Booking Tapped',
  MARKED_AS_PAID_BOOKING_TAPPED: 'Marked As Paid Booking Tapped',
  CALENDARS_TAPPED: 'Calendars Tapped',
  RENTALS_TAPPED: 'Rentals Tapped',
  PAYMENTS_TAPPED: 'Payments Tapped',
  BOOKINGS_TAPPED: 'Bookings Tapped',
  NO_SHOW_TAPPED: 'No Show Tapped',
  PLAYERS_TAPPED: 'Players Tapped',
  MEMBERSHIPS_TAPPED: 'Memberships Tapped',
  STATISTICS_TAPPED: 'Statistics Tapped',
  INSIGHTS_TAPPED: 'Insights Tapped',
  REPORTS_TAPPED: 'Reports Tapped',
  COMMUNICATION_TAPPED: 'Communication Tapped',
  VOUCHERS_TAPPED: 'Vouchers Tapped',
  PARTNERSHIPS_TAPPED: 'Partnerships Tapped',
  PAYMENT_LINKS: {
    COPY_TAPPED: 'Copy Payment Link Tapped',
    NOTE_SAVED: 'Payment Link Note Saved',
    GO_TO_BOOKING_TAPPED: 'Go to Booking From Payment Link Tapped',
    SEARCHED: 'Payment Links Searched',
    SETTINGS_TAPPED: 'Payment Links Settings Tapped',
    CONFIGURATION_SAVED: 'Payment Links Configuration Saved',
    SPLIT_PAYMENTS_ENABLED: 'Split Payments Enabled',
    FIRST_PAYMENT: 'First Payment',
    FINAL_PAYMENT: 'Final Payment',
    BUTTON_IN_BOOKING_WINDOW_TAPPED: 'Payment Link In Booking Window Tapped',
    DELIVERY_METHOD: 'Delivery Method',
    RECIEVER_EDITED: 'Receiver Edited',
    SENT: 'Payment Link Sent',
    DEFAULT_SETTINGS_OVERRIDEN: 'Default Settings Overriden',
    NOTE_ADDED: 'Note Added',
    AUTOMATIC_LINKS_ACTIVATED: 'Automatic Payment Links Activated',
    PARTNER_NAME: 'Partner Name',
  },
}

export const AMPLITUDE_USER_PROPERTIES = {
  USER_ROLE: 'user_role',
  VENUE_TYPE: 'venue_type',
  SCREEN_SIZE: 'screen_size',
  CUSOTMER: 'customer',
  FEDERATION: 'federation',
  RELEASE_VERSION: 'release_version',
  ACTIVE_PARTNERSHIPS_WITH_PAYMENT_LINKS: 'active_partnerships_with_payment_links',
}

export const AMPLITUDE_OPERATION_METHODS = {
  SET: 'set',
  POST_INSERT: 'post_insert',
}
