import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import _ from 'lodash'
import m from 'moment'

import SearchInput from '@sweetspot/club-portal-legacy/components/SearchInput'
import Table from '@sweetspot/club-portal-legacy/components/Table'
import Text from '@sweetspot/club-portal-legacy/components/Text'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import Utilities from '@sweetspot/club-portal-legacy/helpers/Utilities'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { searchPlayers } from '@sweetspot/sweetspot-js/features/players/services/api-platform'
import { fetchBookings } from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import {
  getStartAndEndOfDateLocal,
  toISOStringWithOffset,
} from '@sweetspot/sweetspot-js/common/functions/dateUtils'
import { priceToLocal } from '@sweetspot/sweetspot-js/common/functions/utils'
import { withClubCurrency } from '@sweetspot/club-portal-legacy/hoc/withClubCurrency'

const bookingHeaders = {
  course: {
    name: '.course',
    type: 'label',
    valueType: 'string',
    width: '300px',
    selected: true,
    isSortable: true,
  },
  time: {
    name: '.singleDate',
    type: 'label',
    valueType: 'string',
    width: '175px',
    selected: true,
    isSortable: true,
  },
  noOfPlayers: {
    name: '.noOfPlayers',
    type: 'label',
    valueType: 'number',
    width: '150px',
    selected: true,
    isSortable: true,
  },
  price: {
    name: '.price',
    type: 'label',
    valueType: 'number',
    width: '150px',
    selected: true,
    isSortable: true,
  },
  status: {
    name: '.bookingStatus',
    type: 'label',
    valueType: 'string',
    width: '200px',
    selected: true,
    isSortable: true,
  },
  _courseID: {
    type: 'label',
    valueType: 'number',
    selected: false,
    isSortable: true,
  },
}

const playerHeaders = {
  name: {
    name: '.name',
    type: 'label',
    valueType: 'string',
    width: '250px',
    selected: true,
    isSortable: true,
  },

  phone: {
    name: '.phone',
    type: 'label',
    valueType: 'string',
    width: '180px',
    selected: true,
    isSortable: true,
  },
  golfId: {
    name: '.golfId',
    type: 'label',
    valueType: 'string',
    width: '150px',
    selected: true,
    isSortable: true,
  },
  hcp: {
    name: '.hcp',
    type: 'label',
    valueType: 'number',
    selected: true,
    isSortable: true,
  },
}

class BookingsTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: '',
      selectedPlayer: null,
      playerTable: [],
      bookingTable: [],
      bookingHeaders: this.getBookingHeaders(),
      playerHeaders: this.getPlayerHeaders(),
      isLoading: false,
      externalCourses: [],
    }

    this.handleChange = this.handleChange.bind(this)
    this.initializeSearch = this.initializeSearch.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.findPlayer = this.findPlayer.bind(this)
    this.renderPlayers = this.renderPlayers.bind(this)
    this.onPlayerRowClick = this.onPlayerRowClick.bind(this)
    this.onBookingRowClick = this.onBookingRowClick.bind(this)
  }

  getBookingHeaders() {
    let bookingHeader = {}
    Object.keys(bookingHeaders).forEach((key) => {
      if (bookingHeaders[key].selected) {
        bookingHeader[key] = Object.assign({}, bookingHeaders[key])
      }
    })
    return bookingHeader
  }

  getPlayerHeaders() {
    const playerHeader = {}
    Object.keys(playerHeaders).forEach((key) => {
      if (playerHeaders[key].selected) {
        if (this.props.hasCdhNumber && key === 'golfId') {
          playerHeader.cdh = {
            name: '.cdhNumber',
            type: 'label',
            valueType: 'string',
            width: '150px',
            selected: true,
            isSortable: true,
          }
        } else {
          playerHeader[key] = { ...playerHeaders[key] }
        }
      }
    })
    return playerHeader
  }

  handleChange(input) {
    this.setState({ searchText: input })
  }

  handleKeyPress({ key }) {
    if (key === 'Enter' && this.state.searchText) this.findPlayer()
  }

  initializeSearch() {
    this.setState({
      searchText: '',
      selectedPlayer: null,
    })
    if (this._playerInfoInput) {
      this._playerInfoInput.value = ''
    }
  }

  findPlayer() {
    const { searchText } = this.state
    const { golfClubId } = this.props

    this.setState({ isLoading: true, selectedPlayer: null })
    if (!searchText) return

    if (
      Utilities.isAPhoneNumber(searchText) ||
      Utilities.isAGolfId(searchText) ||
      Utilities.isACdhNumber(searchText)
    ) {
      searchPlayers({ search: searchText, limit: 1, page: 1 })
        .then((res) => {
          if (res?.[0]) {
            this.setState({ selectedPlayer: res[0], isLoading: false })
            this.renderPlayers([res[0]])
            this.fetchPlayersBookings(res[0])
          } else {
            this.renderPlayers([])
          }
        })
        .catch(() => {
          this.renderPlayers([])
        })
    } else if (searchText) {
      searchPlayers({ search: searchText, relatedGolfClub: golfClubId, limit: 15, page: 1 })
        .then((res) => {
          this.renderPlayers(
            res.sort((a, b) => {
              const firstName = a.first_name === null ? '' : a.first_name.toUpperCase()
              const secondName = b.first_name === null ? '' : b.first_name.toUpperCase()
              return firstName < secondName ? -1 : firstName > secondName ? 1 : 0
            })
          )
        })
        .catch(() => {
          this.renderPlayers([])
        })
    }
  }

  renderPlayers(fetchedPlayers) {
    const players = []
    let filteredPlayers = []

    if (!fetchedPlayers.length) {
      players.push({
        id: -1,
        name: this.props.lang === 'en' ? 'No player found' : 'Ingen spelare hittades',
      })
    } else {
      fetchedPlayers.forEach((player) => {
        players.push({
          id: player.id,
          uuid: player.uuid,
          name: `${player.first_name} ${player.last_name}`,
          phone: player.phone,
          golfId: player?.golf_id,
          cdh: player?.cdh_id,
          hcp: player.hcp,
        })
      })

      const queryWords = this.state.searchText.toLowerCase().split(' ')

      filteredPlayers = players.filter((item) => {
        const fullName = item.name.toLowerCase()
        return queryWords.every((word) => fullName.includes(word))
      })
    }

    this.setState({
      playerTable: filteredPlayers,
      isLoading: false,
    })
  }

  onPlayerRowClick(rowData) {
    if (!rowData) return

    this.setState({ selectedPlayer: rowData })
    this.fetchPlayersBookings(rowData)
  }

  onBookingRowClick(rowData) {
    if (!rowData || rowData.id === -1) return

    const { selectedGolfCourse, getTeeTimesForDayFromGolfCourse, showPopup } = this.props
    if (selectedGolfCourse === 0) return
    this.setState({ isLoading: true }, () => {
      const dailyTimeStamp = new Date(rowData.startTime)
      const [startDay, endDay] = getStartAndEndOfDateLocal(dailyTimeStamp)
      getTeeTimesForDayFromGolfCourse(
        rowData.courseUuid ? rowData.courseUuid : selectedGolfCourse,
        toISOStringWithOffset(startDay),
        toISOStringWithOffset(endDay)
      )
        .then((teeTimes) => {
          const timestamp = m.utc(rowData.startTime).unix()
          const teeTime = teeTimes.find((time) => m.utc(time.from).unix() === timestamp)
          if (!teeTime) return
          this.setState({ isLoading: false, selectedPlayer: null })
          showPopup(teeTime, rowData?.status)
        })
        .catch((err) => {
          this.setState({ isLoading: false })
        })
    })
  }

  async fetchPlayersBookings(player) {
    const { isSuperAdmin, golfCourses, currentClubCurrency } = this.props

    this.setState({ isLoading: true })

    const bookingsList = []
    const bookings = await fetchBookings({
      'customer.uuid': player.uuid,
      'order[booking.start_time]': 'desc',
      limit: 300,
    })

    bookings.forEach((bookingItem) => {
      const golfCourse = golfCourses.find((course) => {
        if (!course || !bookingItem.course) return false
        return course.id === bookingItem.course.id
      })
      if (isSuperAdmin || golfCourse) {
        const { booking, course } = bookingItem
        bookingsList.push({
          id: booking.id,
          course: course?.search_field?.split('|')[1],
          courseId: course?.id,
          courseUuid: course?.uuid,
          time: DateHelpers.toDateStringWithTimezone(
            booking.start_time,
            course?.timezone,
            'YYYY-MM-DD HH:mm'
          ),
          status: booking.status.charAt(0).toUpperCase() + booking.status.slice(1),
          price: priceToLocal(booking?.total_price || 0, currentClubCurrency, true),
          noOfPlayers: booking.number_of_participants,
          startTime: new Date(booking.start_time),
        })
      }
    })

    if (!bookingsList.length) {
      bookingsList.push({
        id: 0,
        name: this.props.lang === 'en' ? 'No bookings made' : 'Inga bokningar gjorda',
      })
    }

    this.setState({
      bookingTable: _.orderBy(bookingsList, ['startTime'], ['desc']),
      isLoading: false,
    })
  }

  render() {
    return (
      <div className={style.container}>
        <div className={style.sideText}>
          <Text textId="playersText" />
          <Text textId="bookingsText" />
        </div>
        <div className={style.mainContent}>
          <div className={style.searchInput}>
            <SearchInput
              placeholder={
                this.props.hasCdhNumber ? '.searchByPhoneCdhName' : '.searchByPhoneIDName'
              }
              onChange={(input) => this.handleChange(input)}
              onKeyPress={this.handleKeyPress}
              autoFocus
            />
            <PulseLoader showIf={this.state.isLoading} />
          </div>
          <div className={style.tables}>
            <Table
              headers={this.state.playerHeaders}
              selectedRow={this.state.selectedPlayer?.id}
              values={this.state.playerTable}
              defaultSort="name"
              fullWidth
              fullHeight
              onRowClick={this.onPlayerRowClick}
            />
            <Table
              headers={this.state.bookingHeaders}
              values={this.state.bookingTable}
              defaultSort="name"
              fullWidth
              fullHeight
              onRowClick={this.onBookingRowClick}
            />
          </div>
        </div>
      </div>
    )
  }
}

BookingsTable.propTypes = {
  token: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  golfClubId: PropTypes.number.isRequired,
  golfCourses: PropTypes.array.isRequired,
  selectedGolfCourse: PropTypes.number.isRequired,
  getTeeTimesForDayFromGolfCourse: PropTypes.func.isRequired,
  showPopup: PropTypes.func.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  currentClubCurrency: PropTypes.string.isRequired,
  hasCdhNumber: PropTypes.bool.isRequired,
}

export default withClubCurrency(BookingsTable)
