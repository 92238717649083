import { BucketConfigurationDemand } from './types'

export const BUCKET_DATA = {
  maxNrOfBalls: 999,
  maxNrOfBuckets: 3,
  discount: [...Array(21).keys()].map((i) => i * 5),
}

export const BUCKET_SIZES_DEFAULT = [
  {
    nrOfBalls: 25,
    discount: 0,
  },
]

export const BALL_PRICE_DATA = {
  maxBallPrice: 9.99,
  demands: [
    BucketConfigurationDemand.LOW,
    BucketConfigurationDemand.MID,
    BucketConfigurationDemand.HIGH,
  ],
}

export const BALL_PRICE_TECHNOLOGIES_DEFAULT = ['none']

export const PRICE_ROUNDING_DATA = [0.5, 1, 5, 10]

export const PRICE_ROUNDING_DEFAULT = 100
