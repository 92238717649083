import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import { UpdateBucketConfigurationPayload, RangeWithConfiguration } from './types'

export const queryBucketConfiguration = (rangeId?: string): Promise<RangeWithConfiguration> => {
  const URL = `${getBaseUrlPlatform()}/ranges/${rangeId}/configuration`
  return request(URL) as Promise<RangeWithConfiguration>
}

export function updateBucketConfiguration(
  payload: UpdateBucketConfigurationPayload
): Promise<void> {
  const { rangeId, bucketConfiguration } = payload

  const URL = `${getBaseUrlPlatform()}/ranges/${rangeId}/configuration`
  return request(URL, {
    method: 'PUT',
    contentType: 'application/json',
    body: bucketConfiguration,
  })
}
