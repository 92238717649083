import { useCallback, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { _getGitFederationId } from '@sweetspot/club-portal-legacy/services/adminApi'
import { getPlayerData } from '@sweetspot/club-portal-legacy/store/actions'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { _setPlayerCard, _setPlayerRight } from '@sweetspot/club-portal-legacy/services/playerApi'
import { useToasts } from 'react-toast-notifications'
import { FederationPlayer, MatchParams, Player } from '@sweetspot/shared/types'
import { GolfClubItem, RootState } from '@sweetspot/club-portal-legacy/store/types'
import { useTranslation } from 'react-i18next'
import {
  isAllowedGolfIDFormat,
  isCdhNumberFormat,
} from '@sweetspot/sweetspot-js/common/functions/utils'

/**
 * `usePlayerCard` is a custom hook that manages the fetching and updating of player card details.
 * It uses various services for fetching player data and integrates react-query for data fetching
 * and mutation. The hook also handles the logic for player rights and notifications for updates.
 */
const usePlayerCard = (playerId?: string) => {
  // Local states for the player card details, federation player, and flags to track updates.
  const [isPlayerDataUpdating, setIsPlayerDataIsUpdating] = useState<boolean>(false)
  const [cardInfo, setCardInfo] = useState<Player | null>(null)
  const [federationInfo, setFederationInfo] = useState<FederationPlayer | null>(null)
  const [isPlayerCardUpdated, setIsPlayerCardUpdated] = useState(false)
  const { t } = useTranslation()

  // Hooks for route and redux store.
  const match = useRouteMatch<MatchParams>()
  const golfClubs = useSelector((state: RootState) => state.golfClub)
  const { addToast } = useToasts()

  // Query to fetch federation id for the player.
  const gitIdQuery = useQuery('gitId', _getGitFederationId)
  const gitId = gitIdQuery.data && gitIdQuery.data[0]

  // Mutations for updating player card and play rights.
  const setPlayerCardMutation = useMutation((payload: Player) => _setPlayerCard(payload))
  const updatePlayRightMutation = useMutation(
    (payload: { id?: number; playRight: boolean; club?: GolfClubItem }) => _setPlayerRight(payload)
  )

  // Query to fetch player data based on the matched route and selected golf club.
  const { isLoading: isPlayerDataLoading } = useQuery(
    ['playerData', playerId, match?.params?.id, gitId, golfClubs.selectedId],
    () => getPlayerData(playerId || match?.params?.id, gitId, golfClubs.selectedId),
    {
      enabled: !!gitId && (!!playerId || !!match?.params?.id),
      onSuccess: ({ cardInfo, playRight, federationInfo }) => {
        if (federationInfo[0]) {
          const { email, phone, git_home_club } = (federationInfo as FederationPlayer[])[0]

          setFederationInfo({
            email,
            phone,
            homeClub: git_home_club?.name,
          })
        }

        setCardInfo({
          ...cardInfo,
          homeClub: cardInfo?.home_club?.name || '-',
          birthday: cardInfo.birthday
            ? DateHelpers.onlyDateString(new Date(cardInfo.birthday))?.substr(0, 10)
            : '',
          playRight: !!playRight.length,
          golf_id: federationInfo[0]?.golf_id || '-',
          federation: isCdhNumberFormat(federationInfo[0]?.golf_id)
            ? 'SGU'
            : isAllowedGolfIDFormat(federationInfo[0]?.golf_id)
            ? 'SGF'
            : '-',
          gender: cardInfo?.gender ? cardInfo?.gender : null,
          memberType: cardInfo.member_category ? cardInfo.member_category : null,
        })
      },
    }
  )

  /**
   * Handle the updating of the player card. It calls the mutation for updating the player card
   * and handles success and error scenarios, updating the local state and triggering notifications.
   */
  const handleUpdateCard = useCallback(
    (payload: Player) => {
      setIsPlayerDataIsUpdating(true)

      setPlayerCardMutation.mutate(payload, {
        onSuccess: (data) => {
          setCardInfo({
            ...cardInfo,
            ...(data as Player),
            birthday: payload.birthday,
            memberType: data.member_category,
          })
          setIsPlayerCardUpdated(true)
          addToast(t('toast.setPlayerCardSuccess'), { appearance: 'success' })
        },
        onError: () => {
          addToast(t('toast.setPlayerCardError'), { appearance: 'error' })
        },
        onSettled: () => {
          setIsPlayerDataIsUpdating(false)
        },
      })
    },
    [setPlayerCardMutation, cardInfo, addToast, t]
  )

  /**
   * Handle the updating of the player's play rights. It uses the mutation to update the play rights
   * and takes care of success and error scenarios.
   */
  const handleUpdatePlayRight = useCallback(
    (playRight: boolean) => {
      if (!cardInfo) return
      const { list: clubs, selectedId } = golfClubs
      const club = clubs.find((club) => club.id === selectedId)

      setIsPlayerDataIsUpdating(true)

      updatePlayRightMutation.mutate(
        { id: cardInfo?.id, playRight, club },
        {
          onSuccess: () => {
            setCardInfo({ ...cardInfo, playRight })
            setIsPlayerCardUpdated(true)
            addToast(t('toast.setPlayerCardSuccess'), { appearance: 'success' })
          },
          onError: () => {
            addToast(t('toast.setPlayerCardError'), { appearance: 'error' })
          },
          onSettled: () => {
            setIsPlayerDataIsUpdating(false)
          },
        }
      )
    },
    [cardInfo, golfClubs, addToast, updatePlayRightMutation, t]
  )

  return {
    handleUpdateCard,
    handleUpdatePlayRight,
    isPlayerDataUpdating,
    isPlayerDataLoading,
    federationInfo,
    isPlayerCardUpdated,
    cardInfo,
  }
}

export default usePlayerCard
