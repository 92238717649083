import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { z } from 'zod'
import { ControllerRenderProps, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import {
  Accordion,
  AccordionLabel,
  Divider,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  InputBase,
  TextareaContainer,
  TextareaCounter,
  Textarea,
} from '@sweetspot/scramble-ds'
import { ToggleInput } from '../../../../components/Settings'
import { calculateCreatedDate, copyToClipboard, formatDeliveryMethod } from '../../../../utils'
import { useMutation, useQueryClient } from 'react-query'
import {
  deactivatePaymentLink,
  updatePaymentLink,
} from '@sweetspot/shared/data-access/api-platform'
import { track } from '@amplitude/analytics-browser'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'
import { SinglePaymentDetails } from 'libs/club-portal/feature-payment-links/src/sheets/PaymentCreateSheet/components/SinglePaymentDeatils'
import { PaymentLinkSummaryProps } from './types'

export const PaymentFormSchema = z.object({
  deactivate_payment: z.boolean(),
})

const PaymentLinkSummary = ({ paymentLinkData }: PaymentLinkSummaryProps) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const queryClient = useQueryClient()
  const [textAreaValue, setTextAreaValue] = useState<string>(paymentLinkData?.note)

  const { mutate: updateNote } = useMutation(
    async (payload: { note: string | undefined }) =>
      updatePaymentLink(paymentLinkData?.uuid, payload),
    {
      onSuccess: async () => {
        addToast(t('paymentLinks.updatedNote'), { appearance: 'success' })
      },
      onError: () => {
        addToast(t('paymentLinks.couldNotUpdateNote'), { appearance: 'error' })
      },
    }
  )

  const { mutate: handleDeactivatePaymentLink } = useMutation(
    async () => deactivatePaymentLink(paymentLinkData?.uuid),
    {
      onSuccess: async () => {
        addToast(t('paymentLinks.paymentLinkDeactivated'), { appearance: 'success' })

        await queryClient.invalidateQueries(['SINGLE_PAYMENT_LINK'])
        await queryClient.invalidateQueries(['PAYMENT_LINKS'])
      },
      onError: () => {
        addToast(t('paymentLinks.couldNotBeDeactivated'), { appearance: 'error' })
      },
    }
  )

  const { control, setValue } = useForm<z.infer<typeof PaymentFormSchema>>({
    resolver: zodResolver(PaymentFormSchema),
    defaultValues: {
      deactivate_payment: paymentLinkData?.payment?.state === 'new' && !paymentLinkData?.is_active,
    },
    mode: 'onChange',
  })

  const handleToggleChange = (
    value: boolean,
    field: ControllerRenderProps<z.infer<typeof PaymentFormSchema>, 'deactivate_payment'>
  ) => {
    if (!field.value && value) {
      handleDeactivatePaymentLink()
      setValue('deactivate_payment', true)
    }
  }

  const handleCopyToClipboard = () => {
    copyToClipboard({
      text: paymentLinkData?.url || '',
      onSuccess: () => addToast(t('teeSheet.copied'), { appearance: 'success' }),
    })
  }

  return (
    <div className="sticky top-0 flex h-screen w-1/2 flex-col gap-2 overflow-y-auto bg-stone-50 px-6 pt-4 xl:w-1/3 [&::-webkit-scrollbar]:hidden">
      <div className="text-content-base font-bold">{t('paymentLinks.paymentSummary')}</div>
      <Divider variant="basic" />
      <div className="flex w-full flex-col">
        <SinglePaymentDetails firstPayment={paymentLinkData} fromPaymentSummary />
      </div>
      <div className="flex w-full flex-col">
        <div className="flex flex-col">
          <p className="text-content-sm font-bold leading-5">{t('paymentLinks.source')}</p>
          <p>
            {paymentLinkData.origin.source === 'club_portal' ? 'Club Portal' : t('words.other')}
          </p>
        </div>

        <div className="mt-2 flex flex-col">
          <p className="text-content-sm font-bold leading-5">{t('paymentLinks.createdBy')}</p>
          <p>{paymentLinkData.created_by}</p>
        </div>

        <div className="mt-2 flex flex-col">
          <p className="text-content-sm font-bold leading-5">{t('booking.bookingOwner')}</p>
          <p>{paymentLinkData?.delivery_methods?.email}</p>
        </div>
        <Link
          to={`/booking/${paymentLinkData.payment.order.uuid}`}
          onClick={() => track(AMPLITUDE_EVENTS.PAYMENT_LINKS.GO_TO_BOOKING_TAPPED)}
        >
          <Button className="mt-2 flex w-1/3 font-semibold" variant="inverted" size="small">
            {t('sentences.goToBooking')}
          </Button>
        </Link>
        <div className="mt-2 flex flex-col">
          <p className="text-content-sm font-bold leading-5">{t('paymentLinks.deliveredVia')}</p>
          <p>
            {formatDeliveryMethod(
              paymentLinkData?.delivery_methods?.email,
              paymentLinkData?.delivery_methods?.phone
            )}
          </p>
        </div>
        <Divider variant="basic" className="mt-2" />
        <div className="mt-2 flex flex-col">
          <p className="text-content-sm font-bold leading-5">{t('paymentLinks.lastUpdatedAt')}</p>
          <p>{calculateCreatedDate(paymentLinkData?.updated_at)}</p>
        </div>
        <Accordion type="multiple" orientation="horizontal" className="mx-[-24px] mt-2">
          <AccordionItem value="item-1" className=" w-full max-w-full px-2.5">
            <AccordionTrigger className="py-2">
              <AccordionLabel>{t('paymentLinks.internalNotes')}</AccordionLabel>
            </AccordionTrigger>
            <AccordionContent className="bg-sand-50 border-t">
              <InputBase className="mt-4 max-h-[190px] py-3 pl-3 pr-2">
                <TextareaContainer maxCharacters={255}>
                  <Textarea
                    placeholder={t('paymentLinks.internalNotesInfo')}
                    value={textAreaValue}
                    onChange={(e) => setTextAreaValue(e.target.value)}
                  />
                  <TextareaCounter />
                </TextareaContainer>
              </InputBase>

              <Button
                variant="inverted"
                size="small"
                className="ml-3 mt-2 w-min font-semibold"
                onClick={() => {
                  track(AMPLITUDE_EVENTS.PAYMENT_LINKS.NOTE_SAVED)
                  updateNote({ note: textAreaValue })
                }}
              >
                <i className="fa-regular fa-note" />
                {paymentLinkData?.note ? t('paymentLinks.updateNote') : t('paymentLinks.addNote')}
              </Button>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <div className="mt-4 flex w-full flex-col ">
          {paymentLinkData?.payment?.state !== 'completed' &&
            paymentLinkData?.payment?.state !== 'refunded' && (
              <>
                <p className="text-content-base mb-2 font-bold">
                  {t('paymentLinks.paymentLinkStatus')}
                </p>
                <ToggleInput
                  control={control}
                  name="deactivate_payment"
                  text={t('paymentLinks.deactivate')}
                  infoText={t('paymentLinks.activateOrDeactivate')}
                  onToggle={handleToggleChange}
                />
              </>
            )}
        </div>
        <Button
          className="flex w-full py-4 focus:!ring-2"
          variant="ghost-dark"
          disabled={!paymentLinkData?.is_active || paymentLinkData?.is_expired}
          onClick={() => {
            track(AMPLITUDE_EVENTS.PAYMENT_LINKS.COPY_TAPPED)
            handleCopyToClipboard()
          }}
        >
          <i className="fa-regular fa-copy" />
          {t('paymentLinks.copyPaymentLink')}
        </Button>
      </div>
    </div>
  )
}

export { PaymentLinkSummary }
