import { createContext, useMemo, FC, PropsWithChildren, useContext } from 'react'
import useRanges from '@sweetspot/club-portal-legacy/hooks/useRanges'
import { BallPricingContextValue } from '@sweetspot/club-portal-legacy/pages/Settings/components/BallPricing/types'
import useBucketConfigurationSettings from './hooks/useBucketConfigurationSettings'
import { BUCKET_DATA } from './constants'

export const BallPricingContext = createContext<BallPricingContextValue>({
  areRangesLoading: false,
  areBallPricingLoading: false,
  ranges: [],
  bucketConfiguration: {
    openingHours: {},
    bucketSizes: [],
    discountsOptions: [],
    ballPrice: {},
    maxNrOfBalls: BUCKET_DATA.maxNrOfBalls,
    maxNrOfBuckets: BUCKET_DATA.maxNrOfBuckets,
  },
})

export const BallPricingProvider: FC<PropsWithChildren> = ({ children }) => {
  const { ranges, areRangesLoading, selectedRange, setSelectedRange } = useRanges()
  const bucketConfigurationSettings = useBucketConfigurationSettings({ rangeId: selectedRange?.id })

  const value = useMemo(
    () => ({
      ranges,
      areRangesLoading,
      selectedRange,
      setSelectedRange,
      areBallPricingLoading: false,
      bucketConfiguration: {
        ...bucketConfigurationSettings,
      },
    }),
    [areRangesLoading, ranges, selectedRange, setSelectedRange, bucketConfigurationSettings]
  )

  return <BallPricingContext.Provider value={value}>{children}</BallPricingContext.Provider>
}

export const useBallPricingContext = () => useContext(BallPricingContext)
