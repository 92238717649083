import { useTranslation } from 'react-i18next'

import { Divider } from '@sweetspot/scramble-ds'
import { formatDeliveryMethod } from '../../../utils'
import { PartialPaymentDetails } from './PartialPaymentDetails'
import { SinglePaymentDetails } from './SinglePaymentDeatils'
import { PaymentLinkDetailsProps } from '../../types'

export const PaymentLinkDetails = ({
  firstPayment,
  secondPayment,
  booking,
}: PaymentLinkDetailsProps) => {
  const { t } = useTranslation()

  if (!firstPayment) return

  return (
    <div className="mt-3 flex flex-col">
      {secondPayment ? (
        <PartialPaymentDetails
          firstPayment={firstPayment}
          secondPayment={secondPayment}
          booking={booking}
        />
      ) : (
        <SinglePaymentDetails firstPayment={firstPayment} booking={booking} />
      )}
      <Divider variant="basic" className="my-3" />

      <div className="flex w-full flex-col">
        <div className="mt-2 flex flex-col">
          <p className="text-content-sm font-bold leading-5">{t('paymentLinks.source')}</p>
          <p>{firstPayment.origin.source === 'club_portal' ? 'Club Portal' : t('other')}</p>
        </div>

        <div className="mt-2 flex flex-col">
          <p className="text-content-sm font-bold leading-5">{t('paymentLinks.createdBy')}</p>
          <p>
            <p>{firstPayment.created_by}</p>
          </p>
        </div>

        <div className="mt-2 flex flex-col">
          <p className="text-content-sm font-bold leading-5">{t('booking.bookingOwner')}</p>
          <p>{firstPayment?.delivery_methods?.email}</p>
        </div>

        <div className="mt-2 flex flex-col">
          <p className="text-content-sm font-bold leading-5">{t('paymentLinks.deliveredVia')}</p>
          <p>
            {formatDeliveryMethod(
              firstPayment?.delivery_methods?.email,
              firstPayment?.delivery_methods?.phone
            )}
          </p>
        </div>
      </div>
    </div>
  )
}
