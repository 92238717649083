import React from 'react'
import { useBaysContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/BaysProvider'
import { Trans, useTranslation } from 'react-i18next'
import {
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'
import { Link } from 'react-router-dom'
import BaysTable from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/BaysTable/Table'
import { BaySideBarMode } from '@sweetspot/club-portal-legacy/pages/Settings/components/Bays/types'

const BaysTableContainer = () => {
  const { t } = useTranslation()
  const {
    ranges,
    selectedRange,
    bays,
    areRangesLoading,
    areBaysLoading,
    areBaysFetching,
    toggleBaySideBar,
  } = useBaysContext()
  const emptyStateKey = `loading-${areBaysLoading || areRangesLoading}-ranges-${
    ranges?.length || 0
  }-bays-${bays?.length || 0}`

  if (areBaysFetching || areBaysLoading || areRangesLoading) {
    return (
      <EmptyState
        key={emptyStateKey}
        iconName="fa-light fa-flip-both fa-loader bg-background-mono-lighter mb-4 flex rounded-full p-4"
        loading
      >
        <EmptyStateTitle>{t('settings.bays.loadingBays')}</EmptyStateTitle>
        <EmptyStateDescription>{t('settings.bays.hangOnTight')}</EmptyStateDescription>
      </EmptyState>
    )
  }

  if (!ranges?.length) {
    return (
      <EmptyState
        key={emptyStateKey}
        iconName="fa-light fa-flip-both fa-circle-exclamation bg-background-mono-lighter mb-4 flex rounded-full p-4"
      >
        <EmptyStateTitle>{t('settings.bays.noRangesCreated')}</EmptyStateTitle>
        <EmptyStateDescription>{t('settings.bays.createAtLeastOneRange')}</EmptyStateDescription>
        <EmptyStateButton className="text-content-sm font-medium hover:no-underline focus:no-underline">
          <Link
            className="height-full flex w-full items-center justify-center"
            to={'/settings/manage-range'}
            target={'_blank'}
          >
            <i className="fa-regular fa-arrow-up-right-from-square mr-2"></i>
            {t('settings.bays.createFirstRange')}
          </Link>
        </EmptyStateButton>
      </EmptyState>
    )
  }

  if (!bays?.length) {
    return (
      <EmptyState key={emptyStateKey} iconName="fa-kit fa-range-light">
        <EmptyStateTitle>{t('settings.bays.noBaysCreated')}</EmptyStateTitle>
        <EmptyStateDescription>
          <div className="mb-4 text-center">
            <Trans
              i18nKey="settings.bays.createBay"
              components={{ 1: <strong /> }}
              values={{ rangeName: selectedRange?.name || '' }}
            />
          </div>
        </EmptyStateDescription>
        <EmptyStateButton
          onClick={() => toggleBaySideBar(BaySideBarMode.CREATE)}
          className="text-content-sm font-medium"
        >
          {t('settings.bays.createFirstBay')}
        </EmptyStateButton>
      </EmptyState>
    )
  }

  return <BaysTable bayData={bays} />
}

export default BaysTableContainer
