import React, { useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import { useQuery, useQueries } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import { queryClubCourses } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import { getSpaces } from '@sweetspot/sweetspot-js/features/spaces/services/api-platform'
import { useSelector } from 'react-redux'
import { QUERY_KEYS } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import { queryRanges } from '@sweetspot/sweetspot-js/features/ranges/services'
import { queryBays } from '@sweetspot/sweetspot-js/features/bays/services'

import ValueText from '../../ValueText'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import DarkBlueSubtitle from '../../DarkBlueSubtitle'
import InnerBoxWrapper from '../../InnerBoxWrapper'

const CoursesAndSpaces = ({ rule }) => {
  const { t } = useTranslation()
  const { clubId, clubUuid } = useSelector((state) => {
    const currentClub = state?.golfClub?.list?.find?.(
      (club) => club.id === state?.golfClub?.selectedId
    )
    return { clubId: state?.golfClub?.selectedId, clubUuid: currentClub?.uuid }
  })

  const config = rule?.configuration?.venues

  const { data: courses, isFetching: isFetchingCourses } = useQuery(
    [CLUB_QUERIES.COURSES, { club: clubId, page: 1, limit: 50, 'order[name]': 'asc' }],
    ({ pageParam = 1 }) =>
      queryClubCourses(clubId, {
        page: pageParam,
        limit: 50,
        'order[name]': 'asc',
      }),
    {
      enabled: !!clubId && !!config,
    }
  )

  const { data: bayRanges, isFetched: isFetchingRanges } = useQuery(
    [QUERY_KEYS.RANGES, clubId],
    async () => {
      return queryRanges(clubUuid)
    },
    {
      enabled: !!clubUuid,
      select: (data) => {
        return data?.ranges
      },
    }
  )

  const baysResult = useQueries(
    bayRanges?.map((range) => ({
      queryKey: [CLUB_QUERIES.BAYS, { club: clubId, range: range?.id }],
      queryFn: async () => {
        let data = await queryBays({ drivingRangeId: range?.id })
        return data?.bays
      },
      enabled: !!clubId && !!isFetchingRanges,
    })) || []
  )

  const { data: spacesResult, isFetching: isFetchingSpaces } = useQuery(
    [CLUB_QUERIES.SPACES, { club: clubId, page: 1, limit: 50, 'order[name]': 'asc' }],
    ({ pageParam = 1 }) =>
      getSpaces(clubId, {
        page: pageParam,
        limit: 50,
        'order[name]': 'asc',
      }),
    {
      enabled: !!clubId && !!config,
    }
  )

  const bays = useMemo(() => {
    if (!baysResult?.length) return []
    let loading = false
    let baysArray = []
    baysResult.forEach((result) => {
      const { isFetching, isLoading, isIdle, data } = result
      if (isFetching || isLoading || isIdle) loading = true
      else {
        baysArray = [...baysArray, ...data]
      }
    })

    if (loading) return []
    return baysArray
  }, [baysResult])

  const spaces = useMemo(() => {
    const spacesAndBays = spacesResult?.map((space) => {
      const bay = bays.find((b) => b?.id === space.uuid)
      if (bay) {
        return { ...bay, ...space, name: `${t('words.bay')} ${bay.bay_number}` }
      }
      return space
    })
    bays.forEach((bay) => {
      if (spacesAndBays && !spacesAndBays?.find((space) => space.uuid === bay.id)) {
        spacesAndBays.push({ ...bay, name: `${t('words.bay')} ${bay.bay_number}`, uuid: bay.id })
      }
    })
    return spacesAndBays
  }, [spacesResult, bays, t])

  const getCourseName = (uuid) => {
    return courses.find((x) => x.uuid === uuid)?.name || ''
  }
  const getSpaceName = (uuid) => {
    return spaces.find((x) => x.uuid === uuid)?.name || ''
  }

  return (
    <div className={cx(styles.container)}>
      <DarkBlueSubtitle>{t('sentences.coursesAndSpaces')}</DarkBlueSubtitle>
      {config ? (
        <React.Fragment>
          {!isFetchingCourses && !isFetchingSpaces ? (
            Object.keys(config).map((courseUuid) => (
              <InnerBoxWrapper key={courseUuid} className={cx(styles.row)}>
                <ValueText>{getCourseName(courseUuid)}</ValueText>
                <div className={cx(styles.spacesWrapper)}>
                  {config[courseUuid]?.spaces.map((spaceUuid) => (
                    <ValueText
                      key={spaceUuid}
                      className={cx(styles.robotoFont)}
                      fontWeight="medium"
                      fontSize="13"
                    >
                      {getSpaceName(spaceUuid)}
                    </ValueText>
                  ))}
                </div>
              </InnerBoxWrapper>
            ))
          ) : (
            <PulseLoader showIf={true} />
          )}
        </React.Fragment>
      ) : (
        <ValueText>{t('sentences.noCoursesOrSpacesAllowed')}</ValueText>
      )}
    </div>
  )
}

CoursesAndSpaces.propTypes = {
  rule: PropTypes.object,
}

CoursesAndSpaces.defaultProps = {}

export default CoursesAndSpaces
