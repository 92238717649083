import { useQuery } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import { getPaymentLink } from '@sweetspot/shared/data-access/api-platform'
import { PaymentEmptyPage } from './PaymentEmptyPage'
import { PaymentLinkSummary } from './PaymentLinkSummary'
import { usePaymentIdStore } from '../../store'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'

export const RightView = () => {
  const { selectedPaymentId, setSelectedPaymentId } = usePaymentIdStore((state) => state)
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const clubId = useSelector((state: RootState) => state.golfClub.selectedId)

  useEffect(() => {
    setSelectedPaymentId(null)
  }, [clubId, setSelectedPaymentId])

  const { data: paymentLinkData, isLoading: isPaymentLinkLoading } = useQuery({
    queryKey: ['SINGLE_PAYMENT_LINK', selectedPaymentId],
    queryFn: () => {
      return getPaymentLink(selectedPaymentId)
    },
    enabled: !!selectedPaymentId,
    cacheTime: 0,
    refetchOnWindowFocus: true,
    onError: () =>
      addToast(t('paymentLinks.errorGettingPaymentLinksDetails'), { appearance: 'error' }),
  })

  return isPaymentLinkLoading || !paymentLinkData ? (
    <PaymentEmptyPage isLoading={isPaymentLinkLoading} />
  ) : (
    <PaymentLinkSummary paymentLinkData={paymentLinkData} />
  )
}
