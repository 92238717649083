import {
  Divider,
  EmptyState,
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateTitle,
} from '@sweetspot/scramble-ds'
import { Header, LeftView, RightView } from './partials'
import { FlagNames, useFlag } from '@sweetspot/shared/util/feature-flag'

export const PaymentLinksOverview = () => {
  const isPaymentLinksFlagEnabled = useFlag(FlagNames.PaymentLinks)

  if (isPaymentLinksFlagEnabled)
    return (
      <div>
        <Header />
        <div className="relative flex h-full w-full">
          <LeftView />
          <Divider variant="basic" orientation="vertical" className="h-full" />
          <RightView />
        </div>
      </div>
    )

  return (
    <div className="flex items-center justify-center pb-[80px]">
      <EmptyState>
        <EmptyStateTitle>Payment links</EmptyStateTitle>
        <EmptyStateDescription>
          We are making it possible to send payment links for a booking. If this is of interest to
          you then please reach out to our customer success team, so that we can get you setup to
          take advantage of this new feature as it goes live.
        </EmptyStateDescription>
        <EmptyStateButton>
          <a href="mailto:hello@sweetspot.io">Contact us</a>
        </EmptyStateButton>
      </EmptyState>
    </div>
  )
}
