import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useToasts } from 'react-toast-notifications'

import {
  updateBucketConfiguration,
  queryBucketConfiguration,
  BucketConfigurationPayload,
} from '@sweetspot/shared/data-access/api-platform'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'

import { OPENING_HOURS } from '../mockData'
import { BUCKET_DATA, BALL_PRICE_DATA, PRICE_ROUNDING_DATA } from '../constants'
import { BallPrice, BucketSize, OpeningHours } from '../types'
import {
  constructBucketConfigurationData,
  constructBucketConfigurationPayload,
  validateBucketConfiguration,
} from '../utils'

const useBucketConfigurationSettings = ({ rangeId }: { rangeId?: string }) => {
  const [openingHours, setOpeningHours] = useState<OpeningHours>(OPENING_HOURS)
  const [bucketSizes, setBucketSizes] = useState<BucketSize[]>([])
  const [ballPrice, setBallPrice] = useState<BallPrice>({})
  const [priceRounding, setPriceRounding] = useState<number>(1)
  const [trackingTechnologies, setTrackingTechnologies] = useState<string[]>([])
  const { addToast } = useToasts()
  const { t } = useTranslation()

  const handleSetOpeningHours = useCallback((newData: OpeningHours) => {
    setOpeningHours((prev) => ({
      ...prev,
      ...Object.entries(newData).reduce(
        (acc, [day, values]) => ({
          ...acc,
          [day]: { ...prev[day], ...values },
        }),
        {}
      ),
    }))
  }, [])

  const handleAddBucketSize = useCallback(() => {
    setBucketSizes((prev) => [
      ...prev,
      {
        nrOfBalls: 25,
        discount: 0,
      },
    ])
  }, [])

  const handleRemoveBucketSize = useCallback((index: number) => {
    setBucketSizes((prev) => prev.filter((_, i) => i !== index))
  }, [])

  const handleSetBucketSizes = useCallback((newData: Partial<BucketSize>, index: number) => {
    setBucketSizes((prev) => {
      const newSizes = [...prev]
      newSizes[index] = { ...newSizes[index], ...newData }
      return newSizes
    })
  }, [])

  const handleSetBallPrice = useCallback((newData: BallPrice) => {
    setBallPrice((prev) => ({
      ...prev,
      ...Object.entries(newData).reduce(
        (acc, [tech, prices]) => ({
          ...acc,
          [tech]: { ...prev[tech], ...prices },
        }),
        {}
      ),
    }))
  }, [])

  const { mutateAsync, isLoading: isUpdating } = useMutation(
    (payload: BucketConfigurationPayload) => {
      return updateBucketConfiguration({
        rangeId,
        bucketConfiguration: payload,
      })
    },
    {
      onError: () => {
        addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      },
    }
  )

  const handleOnSave = useCallback(
    async (cb: () => void) => {
      const payload = constructBucketConfigurationPayload({
        bucketSizes,
        ballPrice,
        priceRounding,
        openingHours,
      })
      const errorText = validateBucketConfiguration(payload)

      if (errorText) {
        addToast(t(errorText), { appearance: 'error' })
      } else {
        mutateAsync(payload).then(() => {
          addToast(t('toast.updateBucketConfigurationSuccess'), { appearance: 'success' })
          cb()
        })
      }
    },
    [mutateAsync, ballPrice, bucketSizes, openingHours, priceRounding, t, addToast]
  )

  const { isFetching, refetch: getBucketConfiguration } = useQuery(
    [CLUB_QUERIES.BUCKET_CONFIGURATION, rangeId],
    () => queryBucketConfiguration(rangeId),
    {
      enabled: false,
      onSuccess: (data) => {
        const bucketConfigurationData = constructBucketConfigurationData(data)
        setBucketSizes(bucketConfigurationData.bucketSizes)
        setBallPrice(bucketConfigurationData.ballPrice)
        setPriceRounding(bucketConfigurationData.priceRounding)
        setTrackingTechnologies(bucketConfigurationData.trackingTechnologies)
      },
      onError: () => {
        addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      },
    }
  )

  return {
    openingHours,
    setOpeningHours: handleSetOpeningHours,
    bucketSizes,
    setBucketSizes: handleSetBucketSizes,
    addBucketSize: handleAddBucketSize,
    removeBucketSize: handleRemoveBucketSize,
    maxNrOfBalls: BUCKET_DATA.maxNrOfBalls,
    maxNrOfBuckets: BUCKET_DATA.maxNrOfBuckets,
    discountsOptions: BUCKET_DATA.discount,
    ballPrice,
    setBallPrice: handleSetBallPrice,
    ballPriceData: {
      demands: BALL_PRICE_DATA.demands,
      technologies: trackingTechnologies,
      maxBallPrice: BALL_PRICE_DATA.maxBallPrice,
    },
    priceRounding,
    setPriceRounding,
    priceRoundingData: PRICE_ROUNDING_DATA,
    onSave: handleOnSave,
    getBucketConfiguration,
    isUpdating,
    isFetching,
  }
}

export default useBucketConfigurationSettings
