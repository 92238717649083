import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'

import { useBallPricingContext } from '@sweetspot/club-portal-legacy/pages/Settings/components/BallPricing/BallPricingProvider'
import { useClubCurrency } from '@sweetspot/sweetspot-js/common/hooks/useClubCurrency'
import { TrackingTech } from '@sweetspot/shared/types'
import { Input, InputBase, InputContainer } from '@sweetspot/scramble-ds'
import { BucketConfigurationDemand } from '../../../types'

const BallPrice = () => {
  const { t } = useTranslation()
  const {
    bucketConfiguration: { ballPrice, ballPriceData, setBallPrice },
  } = useBallPricingContext()
  const [currency] = useClubCurrency()

  const handleOnBlur = useCallback(
    (value: number | string, tech: string, demand: BucketConfigurationDemand) => {
      if (typeof value !== 'number') {
        value = parseFloat(value)
      }
      setBallPrice?.({ [tech]: { [demand]: value.toFixed(2) } })
    },
    [setBallPrice]
  )

  const handleOnFocus = useCallback(
    (value: number | string, tech: string, demand: BucketConfigurationDemand) => {
      if (!value || value === '0.00') {
        setBallPrice?.({ [tech]: { [demand]: '' } })
      }
    },
    [setBallPrice]
  )

  const preventedSymbols = useMemo(() => ['e', 'E', '+', '-'], [])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const isPreventedSymbol = preventedSymbols.includes(e.key)
      if (isPreventedSymbol) {
        e.preventDefault()
      }
    },
    [preventedSymbols]
  )

  const handleOnChange = useCallback(
    (value: number | string, tech: string, demand: BucketConfigurationDemand) => {
      const prevValue: number | string | undefined = ballPrice[tech]?.[demand]
      const shouldUpdateValue = !value || (value && `${value}`.length < 4)
      const hasTwoDecimals = prevValue && `${prevValue}`.split('.')[1]?.length >= 2
      if (hasTwoDecimals && !shouldUpdateValue) {
        value = prevValue
      } else if (value !== '') {
        if (typeof value !== 'number') {
          value = parseFloat(value).toFixed(2)
        }
        value = Math.min(ballPriceData?.maxBallPrice as number, value as number)
      }
      setBallPrice?.({ [tech]: { [demand]: value } })
    },
    [setBallPrice, ballPriceData?.maxBallPrice, ballPrice]
  )

  return (
    <div className="text-content-base text-text-dark font-bold">
      <span>{t('sentences.ballPriceCurrency', { currency: `(${currency})` })}</span>
      {ballPriceData?.technologies?.map((tech) => (
        <div className="bg-background-mono-lighter mt-2 rounded-md border p-4 pt-2">
          <span className="capitalize">
            {tech === TrackingTech.NONE ? t('words.standard') : tech}
          </span>
          <div className="mb-[2px] mt-1 flex gap-1">
            {ballPriceData?.demands?.map((demand) => (
              <span className="flex-1 px-2">
                {t(`words.${demand === BucketConfigurationDemand.MID ? 'medium' : demand}`)}
              </span>
            ))}
          </div>
          <div className="flex items-center gap-1">
            {ballPriceData?.demands?.map((demand) => (
              <InputBase>
                <InputContainer>
                  <Input
                    type="number"
                    value={ballPrice[tech]?.[demand]}
                    className="text-content-base py-1' h-8"
                    onBlur={() => handleOnBlur(ballPrice[tech]?.[demand] || 0, tech, demand)}
                    onFocus={() => handleOnFocus(ballPrice[tech]?.[demand] || 0, tech, demand)}
                    onChange={(e) => handleOnChange(e.target.value, tech, demand)}
                    defaultValue={parseFloat('0').toFixed(2)}
                    onKeyDown={handleKeyDown}
                    min="0"
                  />
                </InputContainer>
              </InputBase>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default BallPrice
