import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import { HEADERS } from './headers'
import { useFailedPayments } from '@sweetspot/club-portal/feature/payment'
import { useState } from 'react'
import { differenceInCalendarMonths } from 'date-fns'

const retryFilter = [
  {
    id: 1,
    name: 'transactions.header_retries',
    options: [
      { id: 0, name: '0', filter: '0' },
      { id: 1, name: '1', filter: '1' },
      { id: 2, name: '2', filter: '2' },
      { id: 3, name: '3', filter: '3' },
      { id: 4, name: '4', filter: '4' },
      { id: 5, name: '5', filter: '5' },
    ],
  },
]

const FailedPayments = () => {
  const [activeFilters, setActiveFilters] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const { data, isFetched } = useFailedPayments({
    query: {
      'retry.attempts': activeFilters,
      page: currentPage,
      limit: 50,
    },
    queryOptions: {
      keepPreviousData: true,
    },
  })

  const handleFilters = (filter) => {
    if (!filter) {
      setActiveFilters([])
    } else if (activeFilters.includes(filter)) {
      const current = activeFilters.filter((item) => item !== filter)
      setActiveFilters(current)
    } else {
      setActiveFilters((prev) => [...prev, filter])
    }
    setCurrentPage(1)
  }

  const checkDateDifferenceInMonths = (date) => {
    if (!date) return null

    return differenceInCalendarMonths(new Date(), new Date(date))
  }

  /*
    Since MembershipCard is created before the payment, a filter is added to
    filter players that have tried to buy but the payment was refused,
    therefore hiding their cases in the UI.
  */
  const filteredPayments = data['hydra:member'].filter(
    (payment) =>
      payment?.retry?.attempts > 0 &&
      checkDateDifferenceInMonths(payment?.installment_loan?.payment_date) < 9
  )

  return (
    <div className={'relative grid h-full gap-8 p-[10px] text-base'}>
      <Grid
        activeFilters={activeFilters}
        handleFilters={handleFilters}
        filters={retryFilter}
        filtersEnabled
        filtersTitle={'transactions.header_filter'}
        values={filteredPayments}
        pageTitle={'transactions.header'}
        headers={HEADERS}
        isLoading={!isFetched}
        pagination
        totalPages={Math.ceil(data['hydra:totalItems'] / data['hydra:itemsPerPage'])}
        rowsPerPage={
          data['hydra:itemsPerPage'] > data['hydra:totalItems']
            ? data['hydra:totalItems']
            : data['hydra:itemsPerPage']
        }
        totalRows={filteredPayments?.length}
        displayRangeOfRows
        setCurrentPage={(val) => setCurrentPage(val)}
      />
    </div>
  )
}

export { FailedPayments }
