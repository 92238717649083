export const OPENING_HOURS = {
  monday: {
    open: '08:00',
    close: '18:00',
  },
  tuesday: {
    open: '08:00',
    close: '18:00',
  },
  wednesday: {
    open: '08:00',
    close: '18:00',
  },
  thursday: {
    open: '08:00',
    close: '18:00',
  },
  friday: {
    open: '08:00',
    close: '18:00',
  },
  saturday: {
    open: '08:00',
    close: '18:00',
  },
  sunday: {
    open: '08:00',
    close: '18:00',
  },
}

export enum Status {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export type StatusObject = {
  status: Status
}

export type PriceObject = {
  status: Status
  price: number
}

const statuses: Status[] = [Status.High, Status.Medium, Status.Low]

const getRandomStatus = (): Status => {
  return statuses[Math.floor(Math.random() * statuses.length)]
}

export const generateStatusList = (length: number): StatusObject[] => {
  return Array.from({ length }, () => ({ status: getRandomStatus() }))
}

const priceStatusMap: { [key: string]: number } = {
  [Status.High]: 50,
  [Status.Medium]: 40,
  [Status.Low]: 25,
}

export const generatePriceList = (length: number): PriceObject[] => {
  return Array.from({ length }, () => {
    const status = getRandomStatus()
    return { price: priceStatusMap[status], status }
  })
}

export const statusColorMap: { [key in Status]: string } = {
  [Status.High]: 'bg-background-sunset-light',
  [Status.Medium]: 'bg-background-sand-light',
  [Status.Low]: 'bg-background-grass-light',
}
