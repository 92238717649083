import { useQuery } from 'react-query'
import { _getBookingPeriodsByGolfcourse } from '@sweetspot/club-portal-legacy/services/bookingPeriodApi'

import { getSpaces } from '@sweetspot/sweetspot-js/features/spaces/services/api-platform'
import { getTranslatedTeeTimeCategories } from '@sweetspot/sweetspot-js/features/teeTimeCategories/js/getTranslatedTeeTimeCategories'
import { createContext, useContext, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { QUERY_KEYS } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import { queryRanges } from '@sweetspot/sweetspot-js/features/ranges/services'
import { GolfCourseTypes } from '@sweetspot/shared/types'
import { queryBays } from '@sweetspot/sweetspot-js/features/bays/services'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import { useTranslation } from 'react-i18next'

const TimePeriodsContext = createContext({
  selectedCourse: null,
  isLoading: true,
  periodsList: null,
  periodOverrides: null,
  activePeriod: null,
  categories: [],
  spaces: [],
  lang: null,
  token: null,
})

export const useTimePeriodsInit = () => {
  const { t } = useTranslation()
  const [globalState, setGlobalState] = useContext(TimePeriodsContext)
  const reduxState = useSelector(mapStateToProps)
  const { clubId, selectedCourse, lang, token, golfClub } = reduxState

  useQuery(['TEE-TIME-CATEGORIES', clubId], () =>
    getTranslatedTeeTimeCategories({ locale: lang, allPages: true, clubId }).then((categories) => {
      setGlobalState((state) => ({
        ...state,
        categories,
      }))
    })
  )

  const { data: bayRanges } = useQuery(
    [QUERY_KEYS.RANGES, clubId, selectedCourse?.id],
    async () => {
      return queryRanges(golfClub.uuid)
    },
    {
      enabled: !!golfClub?.uuid,
      select: (data) => {
        return data?.ranges
      },
    }
  )

  const currentBayRange = useMemo(() => {
    return bayRanges?.find((r) => r?.external_reference === selectedCourse?.uuid)
  }, [bayRanges, selectedCourse?.uuid])

  const isNewRange = useMemo(() => {
    return selectedCourse?.type === GolfCourseTypes.DRIVING_RANGE && !!currentBayRange
  }, [selectedCourse?.type, currentBayRange])

  useQuery([isNewRange ? CLUB_QUERIES.BAYS : 'SPACES', selectedCourse?.id], async () => {
    if (selectedCourse && selectedCourse.id) {
      let spacesRes = await (isNewRange
        ? queryBays({ drivingRangeId: currentBayRange?.id })
        : getSpaces({ course: selectedCourse.id }))
      const spaces = isNewRange ? spacesRes?.bays : spacesRes

      return setGlobalState((state) => ({
        ...state,
        spaces: spaces.map((s) => {
          if (isNewRange) {
            return { ...s, uuid: s.id, name: `${t('words.bay')} ${s.bay_number}` }
          }
          return { ...s, id: s.uuid }
        }),
      }))
    }
  })

  useQuery(
    ['PERIODS', selectedCourse?.id],
    () => {
      if (selectedCourse && selectedCourse.id) {
        return _getBookingPeriodsByGolfcourse(selectedCourse.id)
      }
    },
    {
      onSuccess: (periods) => {
        setGlobalState((state) => ({
          ...state,
          periodsList: periods || [],
          activePeriod: periods ? periods[0] : null,
        }))
      },
    }
  )

  useEffect(() => {
    setGlobalState((state) => ({ ...state, lang, token, selectedCourse }))
  }, [lang, selectedCourse, selectedCourse?.id, setGlobalState, token])

  return globalState
}

export default TimePeriodsContext

const mapStateToProps = (state) => {
  return {
    golfClub: state.golfClub.list.find((club) => club.id === state.golfClub.selectedId),
    clubId: state.golfClub.selectedId,
    golfCourses: state.golfCourse.list,
    selectedCourse: state.golfCourse.list.find((c) => c.id === state.golfCourse.selectedId),
    token: state.auth.token,
    lang: state.auth.me.lang,
  }
}
