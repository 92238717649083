export const DURATION_TIME = {
  DAY: 'DAY',
  TWO_DAYS: 'TWO_DAYS',
  CUSTOM_DAYS: 'CUSTOM_DAYS',
  MONTH: 'MONTH',
  TWO_MONTHS: 'TWO_MONTHS',
}

export const FIRST_PAYMENT_PAY = {
  PERCENTAGE: 'percentage',
  AMOUNT: 'amount',
}

export const SECONDS_IN_DAY = 86400
