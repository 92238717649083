import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import DropdownTable from '@sweetspot/club-portal-legacy/components/DropdownTable'

const bookingHeaders = {
  name: {
    name: '.name',
    type: 'label',
    valueType: 'string',
    selected: true,
    width: '150px',
  },
  golfId: {
    name: '.golfId',
    type: 'label',
    valueType: 'string',
    selected: true,
  },
  time: {
    name: '.time',
    type: 'label',
    valueType: 'string',
    selected: true,
  },
  day: {
    name: '.singleDate',
    type: 'label',
    valueType: 'string',
    selected: true,
  },
  course: {
    name: '.course',
    type: 'label',
    valueType: 'number',
    selected: true,
  },
}

export default class BookingsTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      bookingHeaders: this.getBookingHeaders(),
    }
  }

  getBookingHeaders() {
    let bookingHeader = {}
    Object.keys(bookingHeaders).forEach((key) => {
      if (bookingHeaders[key].selected) {
        if (key === 'golfId' && this.props.hasCdhNumber) {
          bookingHeader['cdhId'] = {
            name: '.cdhNumber',
            type: 'label',
            valueType: 'string',
            selected: true,
          }
        } else {
          bookingHeader[key] = Object.assign({}, bookingHeaders[key])
        }
      }
    })
    return bookingHeader
  }

  render() {
    return (
      <div className={style.container}>
        <DropdownTable
          headers={this.state.bookingHeaders}
          values={this.props.bookings}
          onRowClick={this.props.onRowClick}
          hideArrows
        />
      </div>
    )
  }
}

BookingsTable.propTypes = {
  lang: PropTypes.string.isRequired,
  bookings: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
}
