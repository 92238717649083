import { RadioGroup, RadioGroupItem, Stepper } from '@sweetspot/scramble-ds'
import { Controller, ControllerRenderProps, useWatch } from 'react-hook-form'
import { DURATION_TIME } from '../../consts'
import { useCallback } from 'react'
import { SettingsFormScema } from './validation'
import { z } from 'zod'
import { FirstPaymentDurationProps } from '../types'

export const FirstPaymentDuration = ({ control, setValue }: FirstPaymentDurationProps) => {
  const firstPaymentDuration = useWatch({
    control,
    name: 'first_payment_duration',
  })

  const firstPaymentCustomDays = useWatch({
    control,
    name: 'first_payment_duration_days',
  })

  const incrementCustomDaysFirstPayment = () => {
    if (firstPaymentDuration !== DURATION_TIME.CUSTOM_DAYS) return
    if (firstPaymentCustomDays > 30) return
    setValue('first_payment_duration_days', firstPaymentCustomDays + 1)
  }

  const decrementCustomDaysFirstPayment = () => {
    if (firstPaymentDuration !== DURATION_TIME.CUSTOM_DAYS) return
    if (firstPaymentCustomDays < 4) return
    setValue('first_payment_duration_days', firstPaymentCustomDays - 1)
  }

  const handleStepperChange =
    (
      field: ControllerRenderProps<
        z.infer<typeof SettingsFormScema>,
        'first_payment_duration_days'
      >,
      upperLimit: number
    ) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      if (/^\d*$/.test(value)) {
        const numValue = Number(value)
        field.onChange(
          numValue === 0 ? '' : numValue > 0 && numValue <= upperLimit ? numValue : field.value
        )
      }
    }

  return (
    <Controller
      name="first_payment_duration"
      control={control}
      render={({ field }) => (
        <RadioGroup
          className="flex flex-row"
          onValueChange={(value) => {
            field.onChange(value)
            if (value === DURATION_TIME.DAY) {
              setValue('first_payment_duration_days', 1)
            } else if (value === DURATION_TIME.TWO_DAYS) {
              setValue('first_payment_duration_days', 2)
            } else if (value === DURATION_TIME.CUSTOM_DAYS) {
              setValue('first_payment_duration_days', 10)
            }
          }}
          value={field.value}
          defaultValue={field.value}
        >
          <div className="border-stroke-pale p-md h-full w-1/2 items-start border-r-2">
            <RadioGroupItem
              value={DURATION_TIME.DAY}
              id={DURATION_TIME.DAY}
              label="24 hours"
              isActive={field.value === DURATION_TIME.DAY}
            />
            <RadioGroupItem
              value={DURATION_TIME.TWO_DAYS}
              id={DURATION_TIME.TWO_DAYS}
              label="48 hours"
              isActive={field.value === DURATION_TIME.TWO_DAYS}
            />
          </div>
          <div className="py-md w-1/2 items-start">
            <RadioGroupItem
              value={DURATION_TIME.CUSTOM_DAYS}
              id={DURATION_TIME.CUSTOM_DAYS}
              label="Custom days"
              isActive={field.value === DURATION_TIME.CUSTOM_DAYS}
            />
            <Controller
              name="first_payment_duration_days"
              control={control}
              render={({ field: fieldStepper }) => {
                return (
                  <Stepper
                    value={field.value === DURATION_TIME.CUSTOM_DAYS ? fieldStepper.value : 10}
                    increment={incrementCustomDaysFirstPayment}
                    decrement={decrementCustomDaysFirstPayment}
                    className={`ml-3 w-[140px] ${
                      firstPaymentDuration !== DURATION_TIME.CUSTOM_DAYS && 'opacity-60'
                    }`}
                    readOnly={firstPaymentDuration !== DURATION_TIME.CUSTOM_DAYS}
                    onChange={handleStepperChange(fieldStepper, 31)}
                    onBlur={() => {
                      const value = Number(fieldStepper.value)
                      if (value < 3) {
                        fieldStepper.onChange(3)
                      } else if (value > 31) {
                        fieldStepper.onChange(31)
                      }
                    }}
                  />
                )
              }}
            />
          </div>
        </RadioGroup>
      )}
    />
  )
}
