import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import { Payment, PaymentRequestPayload } from './types'
import { Hydra } from '@sweetspot/shared/types'
import queryString from 'query-string'
import { QueryRecord } from '@sweetspot/shared/types'

/**
 * Get all failed payments
 */
export const getFailedMembershipPayments = (
  query: PaymentRequestPayload
): Promise<Hydra<Payment>> => {
  const url = queryString.stringifyUrl(
    {
      url: `${getBaseUrlPlatform()}/membership-payments?type=installment_loan&payment_provider_state=refused&state=awaiting_payment`,
      query: query as QueryRecord<typeof query>,
    },
    {
      arrayFormat: 'bracket',
    }
  )

  return request(url, {
    method: 'GET',
    headers: {
      Accept: 'Application/ld+json',
    },
  })
}

/**
 * Get payment links
 */

export const getPaymentLink = (paymentId: string | undefined) => {
  const url = `${getBaseUrlPlatform()}/payment-links/${paymentId}`

  return request(url, {
    method: 'GET',
    headers: {
      Accept: 'Application/ld+json',
    },
  })
}

/**
 * Update payment links
 */

export const updatePaymentLink = (paymentId: string, payload: unknown) => {
  const url = `${getBaseUrlPlatform()}/payment-links/${paymentId}`

  return request(url, {
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: payload,
  })
}

/**
 * Deactivate payment link
 */
export const deactivatePaymentLink = (paymentId: string): any => {
  const URL = `${getBaseUrlPlatform()}/payment-links/${paymentId}/deactivate`

  return request(URL, {
    method: 'PUT',
  })
}

/**
 * Create payment link
 */
export const createPaymentLink = (payload: any, paymentId: string): any => {
  const URL = `${getBaseUrlPlatform()}/payments/${paymentId}/generate-link`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

/**
 * Create split payment link
 */
export const splitPaymentLink = (payload: any, paymentId: string): any => {
  const URL = `${getBaseUrlPlatform()}/payments/${paymentId}/split`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}

/**
 * Update payment link configuration
 */
export const updatePaymentLinkConfiguration = (payload: any, courseUuid: string): any => {
  const URL = `${getBaseUrlPlatform()}/courses/${courseUuid}/payment-link-configuration`

  return request(URL, {
    method: 'PUT',
    body: payload,
  })
}
