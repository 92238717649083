import { track } from '@amplitude/analytics-browser'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'
import { format } from 'date-fns'

import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { searchPlayers } from '@sweetspot/sweetspot-js/features/players/services/api-platform'
import { fetchBookings } from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { Booking, Player } from '@sweetspot/shared/types'
import DateHelpers from '@sweetspot/club-portal-legacy/helpers/DateHelpers'
import { createTeeSheetLink } from '@sweetspot/shared/data-access/api-platform'

interface FilteredBookingsProps {
  id: number
  name: string
  golfId: string
  day: string
  time: string
  startTime: Date
  courseUuid: string
  course: string
  cdhId: string
}

interface SearchProps {
  phone?: string
  golfId?: string
  relatedGolfClub: number
  search?: string | null
  email?: string | null
  golfClub?: string | null
  'type[members]'?: string | null
  'type[guests]'?: string | null
  'membership[golf_club_id]'?: string | null
  membership?: string | null
  'membershipPaid[paid]'?: string | null
  limit?: number
}

/**
 * Searches for a player based on the provided search value and search properties.
 * @param searchValue - The value to search for.
 * @param searchProps - The search properties.
 * @returns A promise that resolves to the response if successful, or undefined if there was an error.
 */
export const searchForPlayer = async (searchValue: string, searchProps: SearchProps) => {
  if (!searchValue) return
  track(AMPLITUDE_EVENTS.COURSES_TEE_SHEET_SEARCHED)
  const [response, error] = await to(searchPlayers(searchProps))

  if (error) {
    return
  }

  return response
}

/**
 * Searches for bookings related to a specific player.
 * @param player - The player object.
 * @returns A promise that resolves to an array of bookings related to the player, or undefined if an error occurs.
 */
export const searchForPlayerRelatedBookings = async (player: Player) => {
  if (!player) return

  try {
    const bookings = await fetchBookings({ 'customer.uuid': player.uuid })
    if (bookings.length === 0) return

    return bookings
  } catch (error) {
    return
  }
}

/**
 * Filters the bookings based on certain conditions and returns an array of filtered bookings.
 *
 * @param bookings - The array of bookings to be filtered.
 * @param player - The player object used to filter the bookings.
 * @returns An array of filtered bookings.
 */
export const filterBookings = (bookings: Booking[], player: Player): FilteredBookingsProps[] => {
  const filteredBookings: FilteredBookingsProps[] = []

  bookings.forEach((bookingObj: Booking) => {
    const { booking, course } = bookingObj

    if (
      course &&
      booking.status !== 'canceled' &&
      (DateHelpers.toDateObject(booking.start_time) >= DateHelpers.today() ||
        DateHelpers.isSameDate(DateHelpers.toDateObject(booking.start_time), DateHelpers.today()))
    ) {
      const name = `${player?.first_name} ${player.last_name}`
      const golfId = player?.golf_id
      const cdhId = player?.cdh_id

      const bookingTime = booking.start_time.split('T')
      const day = bookingTime[0]
      const time = DateHelpers.toTimeString(new Date(booking.start_time))
      const startTime = booking.start_time

      filteredBookings.push({
        id: booking.id,
        name,
        golfId,
        cdhId,
        day,
        time,
        startTime,
        courseUuid: course?.uuid,
        course: course.name,
      })
    }
  })

  return filteredBookings
}

/**
 * Generates a share link for a selected golf course and current date.
 * @param {object} selectedGolfCourse - The selected golf course object.
 * @param {Date} currentDate - The current date.
 * @returns {Promise<string|null>} - A promise that resolves to the share link or null if an error occurs.
 */
export const getShareLink = async (selectedGolfCourse, currentDate, lang) => {
  if (!selectedGolfCourse) return

  const date = format(currentDate, 'yyyy-MM-dd HH:mm:ss')

  const response = await createTeeSheetLink(selectedGolfCourse?.id, {
    available_date: date,
    locale: lang === 'se' ? 'sv' : lang,
  })

  return response?.hash
}
