import { CONSTANTS } from '../constants'
import { addToast } from '@sweetspot/club-portal-legacy/store/actions/appStateActions'
import { selectGolfCourse } from '@sweetspot/club-portal-legacy/store/actions/golfCourseActions'
import {
  _addGolfCourseToGolfClub,
  _createGolfClub,
  _getRecipients,
  _createBookingEmail,
  _sendBookingEmail,
  _updateBookingEmail,
  _removeBookingEmail,
  _getMemberships,
  _getMembershipTotalPages,
  _getPartnerships,
  _getWebBookingSetting,
  _setWebBookingSetting,
  _getGitMemberships,
} from '@sweetspot/club-portal-legacy/services/golfClubApi'
import MembershipHelpers from '@sweetspot/club-portal-legacy/helpers/MembershipHelpers'
import PartnershipHelpers from '@sweetspot/club-portal-legacy/helpers/PartnershipHelpers'
import { getGolfClubById } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import {
  getCourseById,
  queryCourses,
} from '@sweetspot/sweetspot-js/features/courses/services/api-platform'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { loginToClub } from '@sweetspot/sweetspot-js/features/auth/services/api-platform'
import { me } from '.'
import jwtDecode from 'jwt-decode'
import COURSE_TYPES from '@sweetspot/sweetspot-js/features/courses/constants/courseTypes'
import { history } from '..'
import { setAuthToken } from '@sweetspot/sweetspot-js/common/services/token-service'

import {
  getPartnerTypes as _getPartnerTypes,
  createPartnerType as _createPartnerType,
  updatePartnerType as _updatePartnerType,
  deletePartnerType as _deletePartnerType,
} from '@sweetspot/sweetspot-js/features/partnerTypes/services/api-platform'
import { hasRole } from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { ROLES_DEF } from '@sweetspot/sweetspot-js/features/userAccess/constants/roles'
import { getPathFromCourses } from '@sweetspot/club-portal-legacy/utils/golfClub'
import { trackClubPropertiesToAmplitude } from '@sweetspot/club-portal-legacy/utils/amplitude'
import { filtersMap } from '@sweetspot/club-portal-legacy/constants/config'

export function selectGolfClub(clubId, initial = false, redirect = false) {
  return async (dispatch, getState) => {
    let { token } = getState().auth
    const { list: golfClubsList } = getState().golfClub

    if (!initial) {
      const golfClub = golfClubsList.find((club) => club.id === clubId)
      trackClubPropertiesToAmplitude(golfClub)
      const [res, err] = await to(loginToClub(golfClub?.uuid))

      if (err) {
        return dispatch(addToast(err.message, 'error'))
      }

      setAuthToken(res.token)
      token = res.token

      const loginData = {
        token: token,
        loggedIn: true,
        isSuperAdmin: false,
      }

      const { roles_current_club: roles } = jwtDecode(token)
      const isSuperAdmin = roles && roles.includes('ROLE_SA')
      if (isSuperAdmin) loginData.isSuperAdmin = true

      await dispatch({
        type: CONSTANTS.AUTH.LOGIN,
        payload: loginData,
      })
      await dispatch({
        type: CONSTANTS.AUTH.MY_ROLES,
        payload: roles,
      })

      dispatch(me())
    }

    dispatch({
      type: CONSTANTS.GOLF_CLUB.SELECT,
      payload: clubId,
    })

    dispatch(getMemberships())
    dispatch(getPartnerships(token))

    if (clubId) {
      dispatch(getGolfCoursesFromGolfClub(clubId, redirect))
    } else {
      dispatch({
        type: CONSTANTS.GOLF_COURSE.GET_LIST,
        payload: [],
      })
      dispatch(selectGolfCourse(0))
    }
    history.replace('/')
  }
}

export function getGolfCoursesFromGolfClub(id, redirect = false) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      queryCourses({ 'club.id': id, 'order[name]': 'asc' })
        .then((res) => {
          dispatch({
            type: CONSTANTS.GOLF_COURSE.GET_LIST,
            payload: res,
          })

          const courseToSelect = res.find((c) => c?.type === COURSE_TYPES.COURSE.value)
          if (courseToSelect) {
            dispatch(selectGolfCourse(courseToSelect.id))
          } else {
            dispatch(selectGolfCourse(res[0] ? res[0].id : 1))
          }

          if (redirect) {
            const path = getPathFromCourses(res)
            history.push(path)
          }

          return resolve(res)
        })
        .catch(() => {
          dispatch(addToast('fetchGolfcoursesError', 'error'))
          return reject()
        })
    })
  }
}

export function getSpecificGolfClub(token, id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getGolfClubById(id)
        .then((res) => {
          dispatch({
            type: CONSTANTS.GOLF_CLUB.GET_SPECIFIC,
            payload: res,
          })
          return resolve(res)
        })
        .catch(() => {
          dispatch(addToast('fetchSpecificGolfclubError', 'error'))

          return reject()
        })
    })
  }
}

export function createGolfClub(token, payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _createGolfClub(payload)
        .then((res) => {
          dispatch({
            type: CONSTANTS.GOLF_CLUB.CREATE,
            payload: { id: res.id, ...payload, ...res },
          })
          dispatch(addToast('golfclubCreated', 'success'))

          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('golfclubCreateError', 'error'))

          return reject(err)
        })
    })
  }
}

export function addGolfCourseToGolfClub(token, clubId, payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _addGolfCourseToGolfClub(clubId, payload)
        .then(async (res) => {
          const newCourse = await getCourseById(res.id)
          dispatch({
            type: CONSTANTS.GOLF_COURSE.CREATE,
            payload: { id: res.id, ...newCourse },
          })
          dispatch(addToast('golfcourseCreated', 'success'))

          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('golfcourseCreateError', 'error'))

          return reject(err)
        })
    })
  }
}

export function getPartnerTypes() {
  return (dispatch, getState) => {
    const userRoles = getState().auth.roles
    return new Promise((resolve, reject) => {
      if (
        hasRole(
          [ROLES_DEF.ROLE_SA.value, ROLES_DEF.ROLE_ADMIN.value, ROLES_DEF.ROLE_CLUB_OWNER.value],
          userRoles
        )
      ) {
        _getPartnerTypes()
          .then((res) => {
            return resolve(res)
          })
          .catch((err) => {
            return reject(err)
          })
      } else {
        return resolve([])
      }
    })
  }
}

export function createPartnerType(token, golfClubId, name) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _createPartnerType({ club: `/api/clubs/${golfClubId}`, name })
        .then((res) => {
          dispatch(addToast('createPartnerSuccess', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          if (err.message === 'Partner type already exists') {
            dispatch(addToast('tagAlreadyExists', 'error'))
          } else if (err?.message) {
            dispatch(addToast(err.message, 'error'))
          } else {
            dispatch(addToast('createPartnerError', 'error'))
          }
          return reject(err)
        })
    })
  }
}

export function updatePartnerType(token, golfClubId, partnerId, name) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _updatePartnerType(partnerId, { name })
        .then((res) => {
          dispatch(addToast('updatePartnerSuccess', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('updatePartnerError', 'error'))
          return reject(err)
        })
    })
  }
}

export function removePartnerType(token, golfClubId, partnerId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _deletePartnerType(partnerId)
        .then((res) => {
          dispatch(addToast('removePartnerSuccess', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('removePartnerError', 'error'))
          return reject(err)
        })
    })
  }
}

export function getRecipients(token, query) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getRecipients(query)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('getRecipientsError', 'error'))
          return reject(err)
        })
    })
  }
}

export function createBookingEmail(token, payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _createBookingEmail(payload)
        .then((res) => {
          dispatch(addToast('createBookingEmailSuccess', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('createBookingEmailError', 'error'))
          return reject(err)
        })
    })
  }
}

export function sendBookingEmail(token, emailId) {
  return () => {
    return new Promise((resolve, reject) => {
      _sendBookingEmail(emailId)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          return reject(err)
        })
    })
  }
}

export function updateBookingEmail(token, payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _updateBookingEmail(payload)
        .then((res) => {
          dispatch(addToast('updateBookingEmailSuccess', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('updateBookingEmailError', 'error'))
          return reject(err)
        })
    })
  }
}

export function removeBookingEmail(token, emailId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _removeBookingEmail(emailId)
        .then((res) => {
          dispatch(addToast('removeEmailSuccess', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('removeEmailError', 'error'))
          return reject(err)
        })
    })
  }
}

export function getMembershipsTotal(golfClubSelectedID) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getMembershipTotalPages(golfClubSelectedID)
        .then((res) => {
          dispatch({
            type: CONSTANTS.MEMBERSHIP.SET_TOTAL_PAGES,
            payload: res,
          })
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('getMembershipsError', 'error'))
          return reject(err)
        })
    })
  }
}

export function getMemberships(query) {
  return (dispatch, getState) => {
    const clubId = getState().golfClub.selectedId
    const currentClub = getState().golfClub.list.find((x) => x.id === clubId)
    const currentClubCurrency = currentClub.currency_code
    const getQuery = query || `?club.id=${clubId}&page=1`
    const gitQuery = `?club=${clubId}`

    const promises = [_getMemberships(getQuery), _getGitMemberships(gitQuery)]

    return new Promise((resolve, reject) => {
      Promise.all(promises)
        .then((responses) => {
          dispatch(getMembershipsTotal(getQuery))
          dispatch({
            type: CONSTANTS.MEMBERSHIP.SET_LIST,
            payload: MembershipHelpers.formatMemberships(responses[0], currentClubCurrency),
          })
          dispatch({
            type: CONSTANTS.MEMBERSHIP.SET_GIT_MEMBERSHIPS,
            payload: responses[1] || [],
          })
          dispatch({
            type: CONSTANTS.MEMBERSHIP.SET_TOTAL_LIST,
            payload: MembershipHelpers.formatMemberships(responses[0], currentClubCurrency),
          })
          return resolve(responses[0])
        })
        .catch((err) => {
          dispatch({
            type: CONSTANTS.MEMBERSHIP.SET_LIST,
            payload: [],
          })
          dispatch({
            type: CONSTANTS.MEMBERSHIP.SET_TOTAL_LIST,
            payload: [],
          })
          dispatch({
            type: CONSTANTS.MEMBERSHIP.SET_GIT_MEMBERSHIPS,
            payload: [],
          })
          dispatch({
            type: CONSTANTS.MEMBERSHIP.SET_TOTAL_PAGES,
            payload: { totalItems: 0, itemsPerPage: 50 },
          })
          dispatch(addToast('getMembershipsError', 'error'))
          return reject(err)
        })
    })
  }
}

export function getMoreMemberships(query) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getMemberships(query)
        .then((response) => {
          dispatch({
            type: CONSTANTS.MEMBERSHIP.ADD_LIST,
            payload: MembershipHelpers.formatMemberships(response),
          })

          return resolve(response)
        })
        .catch((err) => {
          dispatch({
            type: CONSTANTS.MEMBERSHIP.SET_LIST,
            payload: [],
          })
          dispatch(addToast('getMembershipsError', 'error'))
          return reject(err)
        })
    })
  }
}

export function getPartnerships(token, query, activeFilters) {
  return (dispatch, getState) => {
    const clubId = getState().golfClub.selectedId
    const getQuery = query || `?club.id[]=${clubId}&page=1`

    return new Promise((resolve, reject) => {
      _getPartnerships(getQuery)
        .then((res) => {
          let filteredPartnerships = PartnershipHelpers.formatPartnerships(res['hydra:member'])

          const validFilters = activeFilters?.filter((filter) => filter in filtersMap)

          if (validFilters?.length > 0) {
            filteredPartnerships = filteredPartnerships.filter((partnership) =>
              validFilters.some((filter) => filtersMap[filter] === partnership.status.text)
            )
          }

          dispatch({
            type: CONSTANTS.PARTNERSHIP.SET_LIST,
            payload: filteredPartnerships,
          })
          dispatch({
            type: CONSTANTS.PARTNERSHIP.SET_TOTAL_PAGES,
            payload: {
              totalItems: res['hydra:totalItems'],
              itemsPerPage: res['hydra:itemsPerPage'],
            },
          })
          return resolve(res)
        })
        .catch((err) => {
          dispatch({
            type: CONSTANTS.PARTNERSHIP.SET_LIST,
            payload: [],
          })
          dispatch({
            type: CONSTANTS.PARTNERSHIP.SET_TOTAL_PAGES,
            payload: { totalItems: 0, itemsPerPage: 50 },
          })
          dispatch(addToast('getPartnershipError', 'error'))
          return reject(err)
        })
    })
  }
}

export function getWebBookingSetting(token, clubId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _getWebBookingSetting(clubId)
        .then((res) => {
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('getWebBookingSettingError', 'error'))
          return reject(err)
        })
    })
  }
}

export function setWebBookingSetting(token, clubId, payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      _setWebBookingSetting(clubId, payload)
        .then((res) => {
          dispatch(addToast('updateWebBookingSettingSuccess', 'success'))
          return resolve(res)
        })
        .catch((err) => {
          dispatch(addToast('updateWebBookingSettingError', 'error'))
          return reject(err)
        })
    })
  }
}
