import React from 'react'
import { capitalize } from '@sweetspot/sweetspot-js/common/functions/utils'

const HEADERS = {
  bay_number: {
    name: '.settings.bays.bayNumber',
    type: 'label',
    valueType: 'number',
    width: '250px',
    selected: true,
    disabled: true,
    isSortable: true,
  },
  tracking_technology: {
    name: '.settings.bays.trackingTech',
    type: 'label',
    valueType: 'string',
    width: '70px',
    selected: true,
    render: (trackingTech: string) => trackingTech && capitalize(trackingTech),
  },
  floor: {
    name: '.settings.bays.floor',
    type: 'label',
    valueType: 'number',
    width: '250px',
    selected: true,
  },
  roof: {
    name: '.settings.bays.roof',
    type: 'label',
    valueType: 'boolean',
    width: '180px',
    selected: true,
    render: (roof: boolean) =>
      roof ? (
        <i key={`roof - ${roof}`} className="fa-regular fa-check" />
      ) : (
        <i key={`roof - ${roof}`} className="fa-regular fa-xmark" />
      ),
  },
}

export default HEADERS
