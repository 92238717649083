import Header from './Header'
import CreateRangeSideBar from './CreateRangeSideBar/CreateRangeSideBar'
import { ManageRangeProvider } from './ManageRangeProvider'

const ManageRange = () => {
  return (
    <ManageRangeProvider>
      <CreateRangeSideBar />
      <Header />
    </ManageRangeProvider>
  )
}

export default ManageRange
