import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import { createRange, CreateRangePayload } from '@sweetspot/sweetspot-js/features/ranges/services'
import { ViolationError } from '@sweetspot/shared/types'
import { isViolationError } from '../utils'
import { useManageRangeContext } from '../ManageRangeProvider'

const useCreateRangeMutation = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { toggleCreateRangeSideBar, refetchRanges } = useManageRangeContext()

  const createRangeMutation = useMutation((data: CreateRangePayload) => createRange(data), {
    onSuccess: () => {
      addToast(t('sentences.drivingRangeCreated'), { appearance: 'success' })
      refetchRanges()
      toggleCreateRangeSideBar()
    },
    onError: (error: { error: string } | ViolationError) => {
      if (isViolationError(error)) {
        addToast(t('toast.defaultError'), { appearance: 'error' })
      } else {
        if (error?.error?.includes('name: Range with this name already exists.')) {
          addToast(t('sentences.rangeNameAlreadyExists'), { appearance: 'error' })
        } else {
          addToast(t('toast.defaultError'), { appearance: 'error' })
        }
      }
    },
  })

  return createRangeMutation
}

export default useCreateRangeMutation
