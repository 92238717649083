import { getPaymentLinks } from '@sweetspot/shared/data-access/api-platform'
import { useToasts } from 'react-toast-notifications'
import { useInfiniteQuery } from 'react-query'
import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import { Hydra, PaymentLink } from '@sweetspot/shared/types'

export const useGetPaymentLinks = () => {
  const { addToast } = useToasts()
  const { t } = useTranslation()
  const currentClubId = useSelector(({ golfClub }: RootState) => golfClub.selectedId)

  const [searchTerm, setSearchTerm] = useState<string | undefined>('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('')

  const debouncedSearch = useCallback(
    debounce((term: string) => {
      setDebouncedSearchTerm(term)
    }, 500),
    []
  )

  useEffect(() => {
    debouncedSearch(searchTerm)
  }, [searchTerm, debouncedSearch])

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery<
    Hydra<PaymentLink>
  >({
    queryKey: ['PAYMENT_LINKS', debouncedSearchTerm],
    queryFn: async ({ pageParam = 1 }) => {
      return await getPaymentLinks(debouncedSearchTerm, currentClubId, pageParam)
    },
    getNextPageParam: (lastPage, pages) => {
      const next = lastPage?.['hydra:view']?.['hydra:next']
      return next ? pages?.length + 1 : undefined
    },
    select: (data) => {
      return data.pages.flatMap((page) => page['hydra:member'])
    },
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    onError: () => addToast(t('paymentLinks.errorGettingPaymentLinks'), { appearance: 'error' }),
  })

  const paymentLinks = data as PaymentLink[]

  return {
    paymentLinks,
    setSearchTerm,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  }
}
