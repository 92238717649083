import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import { useSelector } from 'react-redux'
import { useQueries, useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'

import Button from '@sweetspot/sweetspot-js/common/components/Button'
import { NavLink, useHistory } from 'react-router-dom'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import { queryCourses } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'
import moment from 'moment'
import { getSpaces } from '@sweetspot/sweetspot-js/features/spaces/services/api-platform'
import { queryRanges } from '@sweetspot/sweetspot-js/features/ranges/services'
import { queryBays } from '@sweetspot/sweetspot-js/features/bays/services'
import SlotsCalendar from './Common/components/SlotsCalendar'
import produce from 'immer'
import SimulatorsRangesBookHeader from './Common/components/SimulatorsRangesBookHeader'
import { EmptyVenuIcon } from './Common/components/EmptyVenuPageIcon'
import { EmptyVenueText } from './Common/components/EmptyVenuPageText'
import SpinnerLoader from '@sweetspot/club-portal-legacy/components/SpinnerLoader/SpinnerLoader'
import {
  fetchBookings,
  reserveBookingWithSpaces,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { useToasts } from 'react-toast-notifications'
import SpaceColumn from './Common/components/SpaceColumn'
import SimulatorRangesConflictHeader from './Common/components/SimulatorRangesConflictHeader'
import useQueryParams from '@sweetspot/sweetspot-js/common/hooks/useQueryParams'
import { setSessionStorage } from '@sweetspot/shared/util/session-storage'
import {
  SimRangeHeader,
  useHeaderController,
  HeaderOption,
  RangeFilterProvider,
} from '@sweetspot/club-portal/feature/venues'
import EditCategoriesHeader from './Common/components/EditCategoriesHeader'
import { getTeeTimesCollection } from '@sweetspot/sweetspot-js/features/teeTimes/js/getTeeTimesCollection'
import {
  getStartAndEndOfDateLocal,
  getStartAndEndOfDateTZ,
} from '@sweetspot/sweetspot-js/common/functions/dateUtils'
import { getAllPagesRequest } from '@sweetspot/sweetspot-js/common/functions/getAllPagesRequest'
import { BOOKING_GROUPS } from '@sweetspot/sweetspot-js/features/bookings/constants/bookingRelations'
import { GolfCourseTypes, RangeFilterType } from '@sweetspot/shared/types'

const Venues = ({ courseType }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()
  const currentClubId = useSelector((state) => state?.golfClub?.selectedId)
  let query = useQueryParams()
  const [filters, setFilters] = useState({
    [RangeFilterType.TECHNOLOGIES]: {},
    [RangeFilterType.FLOORS]: {},
  })

  const [state, setState] = useMergeState({
    currentVenue: null,
    currentDate: null,
    currentVenueSpaces: null,
    teeTimesPerSpace: null,
    bookingsPerSpace: null,
    currentRange: null,
  })

  const [selectedFrom, setSelectedFrom] = useState(null)
  const [selectedTo, setSelectedTo] = useState(null)
  const [selectedSpaces, setSelectedSpaces] = useState([])
  const [reservingBooking, setReservingBooking] = useState(false)
  const [teeTimesGenerated, setTeeTimesGenerated] = useState(false)
  const [pricingExists, setPricingExists] = useState(false)
  const [hoveredBookingBoxesOrderIds, setHoveredBookingsBoxesOrderIds] = useState([])
  const [shouldAnimate, setShouldAnimate] = useState(false)

  const { headerToShow, headerActions } = useHeaderController()

  const isNewRange = useMemo(() => {
    return state.currentVenue?.type === GolfCourseTypes.DRIVING_RANGE && !!state.currentRange?.id
  }, [state.currentVenue?.type, state.currentRange?.id])

  const hasTeeTimes = useMemo(
    () => Object.values(state.teeTimesPerSpace || {}).some((times) => times.length > 0),
    [state.teeTimesPerSpace]
  )

  const selectedTeeTimes = useMemo(() => {
    if (selectedSpaces?.length && selectedFrom && selectedTo && state.teeTimesPerSpace) {
      let teeTimes = []
      Object.keys(state.teeTimesPerSpace).forEach((spaceId) => {
        if (selectedSpaces.find((x) => `${x.id}` === spaceId)) {
          state.teeTimesPerSpace[spaceId].forEach((teeTime) => {
            if (state.currentVenue?.is_use_dynamic_pricing && teeTime.dynamic_price === null) return
            if (state.currentVenue?.is_use_dynamic_pricing === false && teeTime.price === null)
              return
            const { from_unix, to_unix } = teeTime
            if (from_unix >= selectedFrom && to_unix <= selectedTo) teeTimes.push(teeTime)
          })
        }
      })
      return teeTimes
    }
    return []
  }, [selectedSpaces, selectedFrom, selectedTo, state.teeTimesPerSpace, state.currentVenue])

  const { data: venues, isFetching: venuesIsFetching } = useQuery(
    [
      CLUB_QUERIES.COURSES,
      {
        'club.id': currentClubId,
        'type[]': [courseType],
        'order[name]': 'asc',
      },
    ],
    () =>
      queryCourses({
        'club.id': currentClubId,
        'type[]': [courseType],
        'order[name]': 'asc',
      }),
    {
      enabled: !!currentClubId,
    }
  )

  // fetchRanges
  const { isFetching: rangesIsFetching } = useQuery(
    [CLUB_QUERIES.RANGES, currentClubId, state.currentVenue?.id],
    async () => {
      return queryRanges(state.currentVenue?.club?.uuid)
    },
    {
      enabled:
        !!state.currentVenue?.club?.uuid &&
        state.currentVenue?.type === GolfCourseTypes.DRIVING_RANGE,
      onSuccess: (data) => {
        if (data) {
          const range = data.ranges?.find(
            (range) => range?.external_reference === state.currentVenue?.uuid
          )
          setState({ currentRange: range })
        }
      },
      onError: () => {
        setState({ currentRange: null })
      },
    }
  )

  // fetchBays
  const { isFetching: baysIsFetching } = useQuery(
    [CLUB_QUERIES.BAYS, state.currentVenue?.id],
    () => queryBays({ drivingRangeId: state.currentRange?.id }),
    {
      enabled: !!state.currentVenue?.id && !!isNewRange && !rangesIsFetching,
      onSuccess: (data) => {
        const { bays } = data

        setState({ currentVenueSpaces: bays?.map((bay) => ({ ...bay, uuid: bay.id })) })
      },
    }
  )

  // fetchSpaces
  const { isFetching: spacesIsFetching } = useQuery(
    [CLUB_QUERIES.SPACES, { course: state.currentVenue?.id }],
    () => getSpaces({ course: state.currentVenue?.id }),
    {
      enabled: !!state.currentVenue?.id && !isNewRange && !rangesIsFetching,
      onSuccess: (data) => {
        setState({ currentVenueSpaces: data, teeTimesPerSpace: null, bookingsPerSpace: null })
      },
    }
  )

  const teeTimesResults = useQueries(
    state.currentVenueSpaces?.map((space) => ({
      queryKey: [
        CLUB_QUERIES.TEE_TIMES,
        space.id,
        state.currentDate,
        currentClubId,
        state.currentVenue,
      ],
      queryFn: () => {
        const [startDay, endDay] = getStartAndEndOfDateTZ(
          state.currentDate,
          state.currentVenue?.timezone ?? 'Europe/Stockholm'
        )

        return getTeeTimesCollection({
          courseUuid: state.currentVenue?.uuid,
          spaceUuid: space.uuid,
          startDate: startDay,
          endDate: endDay,
          is_use_dynamic_pricing: state.currentVenue.is_use_dynamic_pricing,
        })
      },
      enabled: !!space?.id && !!state.currentVenue?.id && !!state.currentDate,
      onSuccess: (data) => {
        if (data?.length) setTeeTimesGenerated(true)
        if (
          (state.currentVenue?.is_use_dynamic_pricing &&
            (data?.[0]?.dynamic_price || data?.[0]?.price)) ||
          !state.currentVenue?.is_use_dynamic_pricing
        ) {
          setPricingExists(true)
        } else {
          setPricingExists(false)
        }

        setState((prev) => ({
          teeTimesPerSpace: {
            ...prev.teeTimesPerSpace,
            [space.id]: data,
          },
        }))
      },
      onError: (error) => {
        setPricingExists(false)
        if (error?.detail === 'Price period not found') {
          setTeeTimesGenerated(true)
        } else {
          setTeeTimesGenerated(false)
        }
      },
    })) || []
  )

  // Get bookings
  useQueries(
    state.currentVenueSpaces?.map((space) => ({
      queryKey: [
        CLUB_QUERIES.BOOKINGS,
        space.uuid,
        state.currentDate,
        state.currentVenue,
        {
          'state[]': ['new', 'fulfilled', 'reopened', 'partially_paid', 'partially_refunded'],
        },
      ],
      queryFn: () => {
        const [startDay, endDay] = getStartAndEndOfDateLocal(new Date(state.currentDate))
        return getAllPagesRequest(fetchBookings, {
          'course.uuid': state.currentVenue?.uuid,
          'booking.start_time[after]': startDay.toISOString(),
          'booking.start_time[before]': endDay.toISOString(),
          'spaces.uuid': space.uuid,
          'state[]': ['new', 'fulfilled', 'reopened', 'partially_paid', 'partially_refunded'],
          'groups[]': [BOOKING_GROUPS.LEGACY_BOOKING, BOOKING_GROUPS.CUSTOMER],
          page: 1,
          limit: 50,
        })
      },
      enabled: !!state.currentVenue?.uuid && !!state.currentDate && !!space?.uuid,
      refetchInterval: 30000,
      onSuccess: (data) => {
        setState((prev) => ({
          bookingsPerSpace: {
            ...prev.bookingsPerSpace,
            [space.id]: data,
          },
        }))
      },
    })) || []
  )

  const teeTimesIsLoading = useMemo(() => {
    if (teeTimesResults) {
      return teeTimesResults.some((el) => el.isFetching)
    }
    return true
  }, [teeTimesResults])

  useEffect(() => {
    if (state.currentVenue && !state.currentDate) {
      const urlDate = query.get('date')
      let date = moment.tz(state.currentVenue.timezone)
      if (urlDate) {
        date = moment.unix(parseInt(urlDate)).tz(state.currentVenue.timezone)
      }
      setState({ currentDate: date })
    }
  }, [state.currentVenue, state.currentDate])

  useEffect(() => {
    if (venues?.length) {
      const urlVenue = query.get('venue')
      const matchingVenue = urlVenue
        ? venues.find((venue) => venue.id === parseInt(urlVenue))
        : null
      if (urlVenue && matchingVenue) {
        setState({ currentVenue: matchingVenue, currentDate: null })
      } else {
        setState({ currentVenue: venues[0], currentDate: null })
      }
    }
  }, [venues])

  const categories = useMemo(() => {
    if (state.teeTimesPerSpace) {
      let cats = []
      Object.keys(state.teeTimesPerSpace)
        .map((key) => state.teeTimesPerSpace[key])
        .flat()
        .map((teeTime) => {
          const teeTimeCategory = teeTime.category
          if (!cats.find((x) => x.id === teeTimeCategory.id)) {
            cats.push(teeTimeCategory)
          }
        })
      return cats
    }
    return []
  }, [state.teeTimesPerSpace])

  const filteredSpacesToRender = useMemo(() => {
    if (!isNewRange || !state.currentVenueSpaces || !filters) {
      return state.currentVenueSpaces
    }
    let filteredSpaces = [...state.currentVenueSpaces]
    const filteredFloors = Object.keys(filters.floors).filter(
      (floor) => filters.floors[parseInt(floor)]
    )
    const filteredTechnologies = Object.keys(filters.technologies).filter(
      (technology) => filters.technologies[technology]
    )

    if (filteredFloors.length > 0) {
      filteredSpaces = filteredSpaces.filter((space) =>
        filteredFloors.includes(space.floor.toString())
      )
    }
    if (filteredTechnologies.length > 0) {
      filteredSpaces = filteredSpaces.filter((space) =>
        filteredTechnologies.includes(space.tracking_technology)
      )
    }
    return filteredSpaces
  }, [filters, state.currentVenueSpaces, isNewRange])

  const showSheet = useMemo(() => {
    return (
      !rangesIsFetching &&
      !spacesIsFetching &&
      !baysIsFetching &&
      !venuesIsFetching &&
      venues?.length &&
      filteredSpacesToRender?.length &&
      teeTimesGenerated &&
      pricingExists
    )
  }, [
    rangesIsFetching,
    spacesIsFetching,
    baysIsFetching,
    venuesIsFetching,
    venues,
    filteredSpacesToRender?.length,
    teeTimesGenerated,
    pricingExists,
  ])

  // Show loader if fetching data for the first time
  const showLoader = useMemo(() => {
    return (
      (venuesIsFetching ||
        spacesIsFetching ||
        teeTimesIsLoading ||
        baysIsFetching ||
        rangesIsFetching) &&
      Object.keys(state.teeTimesPerSpace || {}).length === 0
    )
  }, [
    venuesIsFetching,
    spacesIsFetching,
    teeTimesIsLoading,
    baysIsFetching,
    rangesIsFetching,
    state.teeTimesPerSpace,
  ])

  useEffect(() => {
    if (!selectedSpaces?.length) {
      setSelectedFrom(null)
      setSelectedTo(null)
    }
  }, [selectedSpaces])

  const handleSelectFrom = (from) => {
    setSelectedFrom(from)
  }
  const handleSelectTo = (to) => {
    setSelectedTo(to)
  }

  const handleSelectSpace = (space) => {
    setSelectedSpaces(
      produce((draft) => {
        const index = draft.findIndex((x) => x.id === space.id)

        if (index !== -1) {
          draft.splice(index, 1)
        } else {
          draft.push(space)
        }
      })
    )
  }

  const onCategoryChanged = (teeTimePeriodArray) => {
    setShouldAnimate(false)
    const tempTeeTimes = { ...state.teeTimesPerSpace }
    Object.keys(tempTeeTimes).forEach((spaceId) => {
      for (let teeTime of tempTeeTimes[spaceId]) {
        delete teeTime.category.playAnimation
        delete teeTime.category.previousColor
      }
    })

    for (let periodData of teeTimePeriodArray) {
      tempTeeTimes[periodData.space.id].forEach((teeTime) => {
        const { from_unix, to_unix } = teeTime
        if (from_unix >= selectedFrom && to_unix <= selectedTo) {
          setShouldAnimate(true)
          teeTime.category = {
            ...teeTime.category,
            ...periodData.category,
            playAnimation: true,
            previousColor: teeTime.category.color,
          }
          teeTime.is_prime_time = periodData.is_prime_time ?? teeTime.is_prime_time
        }
      })
    }
    setState({ teeTimesPerSpace: { ...tempTeeTimes } })
  }

  const resetSheetSelection = () => {
    setSelectedSpaces([])
    setSelectedTo(null)
    setSelectedFrom(null)
  }

  const onCancelHandler = () => {
    resetSheetSelection()
    headerActions.reset()
  }

  const onBookHandler = async () => {
    setReservingBooking(true)
    const dateFormat = 'YYYY-MM-DD HH:mm:ss'
    const teeTimesPerSpace = state.teeTimesPerSpace

    let payload = {
      from: moment.unix(selectedFrom).utc().format(dateFormat),
      to: moment.unix(selectedTo).utc().format(dateFormat),
      data: [],
    }

    try {
      selectedSpaces.forEach((space) => {
        const { uuid, id } = space
        let teeTimes = []
        teeTimesPerSpace[id].forEach((teeTime) => {
          if (selectedTeeTimes.some((x) => x.id === teeTime.id)) {
            teeTimes.push({
              uuid: teeTime.uuid,
            })
          }
        })

        payload.data.push({
          space_uuid: uuid,
          tee_times: teeTimes,
        })
      })
    } catch (error) {
      setReservingBooking(false)
      addToast(t('sentences.failedToReserveBooking'), { appearance: 'error' })
      // Refetch teetimes in case our optimistic updates of UI caused problems
      for (let teetime of teeTimesResults) {
        teetime.refetch()
      }
      return
    }

    const [res] = await to(
      reserveBookingWithSpaces(payload, (headers, data) => {
        setSessionStorage(`cancel-${data.order.uuid}`, JSON.stringify(headers))
      })
    )

    setReservingBooking(false)
    if (res) {
      history.push(`/simulators-ranges/orders/${res.order.uuid}`)
    } else {
      addToast(t('sentences.failedToReserveBooking'), { appearance: 'error' })
    }
  }

  const handleOnMouseEnterBookingBox = (orderId) => {
    setHoveredBookingsBoxesOrderIds(
      produce((draft) => {
        const index = draft.indexOf(orderId)
        if (index === -1) {
          draft.push(orderId)
        }
      })
    )
  }
  const handleOnMouseLeaveBookingBox = (orderId) => {
    setHoveredBookingsBoxesOrderIds(
      produce((draft) => {
        const index = draft.indexOf(orderId)
        if (index !== -1) {
          draft.splice(index, 1)
        }
      })
    )
  }

  const handleOnRefreshTeeTimes = useCallback(() => {
    teeTimesResults.forEach((teeTime) => teeTime.refetch())
  }, [teeTimesResults])

  const renderVenues = () => (
    <div className={cx(styles.container)}>
      <div className={cx(styles.headersContainer)}>
        <SimRangeHeader
          venues={venues}
          venuesIsLoading={venuesIsFetching}
          currentVenue={{ ...(state.currentVenue || {}), rangeId: state.currentRange?.id }}
          setVenue={(venue) => setState({ currentVenue: venue })}
          currentDate={state.currentDate ? new Date(state.currentDate) : null}
          setDate={(date) => setState({ currentDate: moment(date) })}
          isVisible={headerToShow === HeaderOption.DEFAULT}
          setHeader={headerActions.setHeader}
          courseType={courseType}
          hasTeeTimes={hasTeeTimes}
          teeTimesIsLoading={teeTimesIsLoading}
          onRefreshTeeTimes={handleOnRefreshTeeTimes}
        />

        <SimulatorsRangesBookHeader
          selectedFrom={selectedFrom}
          selectedTo={selectedTo}
          selectedSpaces={selectedSpaces}
          selectedTeeTimes={selectedTeeTimes}
          currentVenue={state.currentVenue}
          currentDate={state.currentDate}
          onCancel={onCancelHandler}
          onBook={onBookHandler}
          reservingBooking={reservingBooking}
          isBay={isNewRange}
          isVisible={!!selectedTeeTimes?.length && headerToShow === HeaderOption.DEFAULT}
        />

        <EditCategoriesHeader
          selectedFrom={selectedFrom}
          selectedTo={selectedTo}
          selectedSpaces={selectedSpaces}
          currentVenue={state.currentVenue}
          currentDate={state.currentDate}
          selectedTeeTimes={selectedTeeTimes}
          isVisible={headerToShow === HeaderOption.EDIT_CATEGORY}
          onCancel={onCancelHandler}
          setDate={(date) => setState({ currentDate: date })}
          resetSheet={resetSheetSelection}
          updateSelected={onCategoryChanged}
        />

        <SimulatorRangesConflictHeader
          selectedFrom={selectedFrom}
          selectedTo={selectedTo}
          selectedSpaces={selectedSpaces}
          bookingsPerSpace={state.bookingsPerSpace}
          onCancel={resetSheetSelection}
        />

        {categories?.length && headerToShow === HeaderOption.DEFAULT ? (
          <div className={cx(styles.categoriesRow)}>
            {categories.map((category) => (
              <div className={cx(styles.category)} key={category.id}>
                <div
                  className={cx(styles.categoryDot)}
                  style={{ backgroundColor: category.color }}
                ></div>
                <p className={cx(styles.categoryName)}>{category.name}</p>
              </div>
            ))}
          </div>
        ) : null}
      </div>

      {showLoader && (
        <div className={cx(styles.centeredContainer)}>
          <SpinnerLoader text={t('sentences.loadingTimeSlots')} />
        </div>
      )}

      {!venuesIsFetching &&
      !spacesIsFetching &&
      !baysIsFetching &&
      !teeTimesIsLoading &&
      !rangesIsFetching &&
      (venues?.length <= 0 ||
        state.currentVenueSpaces?.length <= 0 ||
        filteredSpacesToRender?.length <= 0 ||
        !teeTimesGenerated ||
        !pricingExists) ? (
        <div className={cx(styles.centeredContainer)}>
          <EmptyVenuIcon courseType={courseType} customStyle={cx(styles.calendarIcon)} />

          {!venues?.length ? (
            <React.Fragment>
              <EmptyVenueText
                customStyle={cx(styles.noVenuesAvailableYet)}
                courseType={courseType}
              />
              <div className={cx(styles.row)}>
                <NavLink style={{ textDecoration: 'none' }} to="/settings/create-course">
                  <Button text={t('sentences.createCourse')} width="auto" size="larger" />
                </NavLink>
                <NavLink style={{ textDecoration: 'none' }} to="/settings/edit-course">
                  <Button text={t('sentences.modifyCourse')} width="auto" size="larger" />
                </NavLink>
              </div>
            </React.Fragment>
          ) : !state.currentVenueSpaces?.length ? (
            <React.Fragment>
              <p className={cx(styles.noVenuesAvailableYet)}>
                {t('sentences.noSpacesOnCurrentCourse')}.
              </p>
              <NavLink style={{ textDecoration: 'none' }} to="/settings/create-space">
                <Button text={t('sentences.createSpace_plural')} width="auto" size="larger" />
              </NavLink>
            </React.Fragment>
          ) : !teeTimesGenerated ? (
            <React.Fragment>
              <p className={cx(styles.noVenuesAvailableYet)}>
                {t('sentences.noTimesGeneratedOnSpacesYet')}
                {'. '}
                {t('sentences.ifYouHaveAlreadyCreatedPeriodsPleaseWaitWhileTheyAreGenerated')}.
              </p>
              <NavLink style={{ textDecoration: 'none' }} to="/settings/tee-time-periods">
                <Button text={t('sentences.createPeriod')} width="auto" size="larger" />
              </NavLink>
            </React.Fragment>
          ) : !pricingExists ? (
            <React.Fragment>
              <p className={cx(styles.noVenuesAvailableYet)}>
                {t('sentences.noPricingPeriodsCreated')}.
              </p>
              <NavLink style={{ textDecoration: 'none' }} to="/settings/pricing">
                <Button text={t('sentences.managePricing')} width="auto" size="larger" />
              </NavLink>
            </React.Fragment>
          ) : !filteredSpacesToRender?.length ? (
            <div className="flex w-full items-center justify-center text-center">
              {t('sentences.noAvailableBays')}
            </div>
          ) : null}
        </div>
      ) : null}

      {showSheet ? (
        <SlotsCalendar
          currentVenue={state.currentVenue}
          className={cx(styles.spacesContainer)}
          spaces={filteredSpacesToRender}
          teeTimesPerSpace={state.teeTimesPerSpace || {}}
          render={(slotWidth, slotHeight, slotsPerHour, intervalMinutes) =>
            filteredSpacesToRender?.map((space) => (
              <SpaceColumn
                slotWidth={slotWidth}
                slotHeight={slotHeight}
                slotsPerHour={slotsPerHour}
                intervalMinutes={intervalMinutes}
                key={space.id}
                space={space}
                onSelectFrom={handleSelectFrom}
                onSelectTo={handleSelectTo}
                onSelectSpace={handleSelectSpace}
                selectedFrom={selectedFrom}
                selectedTo={selectedTo}
                isSpaceSelected={!!selectedSpaces.find((x) => x.id === space.id)}
                teeTimes={state.teeTimesPerSpace?.[space.id]}
                bookings={state.bookingsPerSpace?.[space.id] || []}
                venue={state.currentVenue}
                onMouseEnterBookingBox={handleOnMouseEnterBookingBox}
                onMouseLeaveBookingBox={handleOnMouseLeaveBookingBox}
                hoveredOrderIds={hoveredBookingBoxesOrderIds}
                editingCategory={headerToShow === HeaderOption.EDIT_CATEGORY}
                shouldAnimate={shouldAnimate}
                isBay={isNewRange}
                bays={state.currentVenueSpaces}
              />
            ))
          }
        />
      ) : null}
    </div>
  )

  if (isNewRange) {
    return (
      <RangeFilterProvider
        rangeId={state.currentRange?.id}
        bays={state.currentVenueSpaces}
        filters={filters}
        setFilters={setFilters}
      >
        {renderVenues()}
      </RangeFilterProvider>
    )
  } else {
    return renderVenues()
  }
}

Venues.propTypes = {}

Venues.defaultProps = {}

export default Venues
