import { Range } from '@sweetspot/shared/types'

export type BallPricingContextValue = {
  areBallPricingLoading: boolean
  areRangesLoading: boolean
  selectedRange?: Range
  setSelectedRange?: (range: Range) => void
  ranges: Range[]
  bucketConfiguration: BucketConfiguration
}

export type BucketConfiguration = {
  openingHours: OpeningHours
  setOpeningHours?: (openingHours: OpeningHours) => void
  bucketSizes: BucketSize[]
  setBucketSizes?: (bucketSize: Partial<BucketSize>, index: number) => void
  addBucketSize?: () => void
  removeBucketSize?: (index: number) => void
  maxNrOfBalls: number
  maxNrOfBuckets: number
  discountsOptions: number[]
  ballPrice: BallPrice
  setBallPrice?: (ballPrice: BallPrice) => void
  ballPriceData?: {
    technologies: string[]
    demands?: BucketConfigurationDemand[]
    maxBallPrice?: number
  }
  priceRounding?: number
  setPriceRounding?: (priceRounding: number) => void
  priceRoundingData?: number[]
  onSave?: (cb: () => void) => void
  getBucketConfiguration?: () => void
  isFetching?: boolean
  isUpdating?: boolean
}

export type OpeningHours = {
  [key: string]: { open?: string; close?: string; disabled?: boolean }
}

export type BucketSize = {
  nrOfBalls: number
  discount: number
}

export type BallPrice = {
  [key: string]: { low?: number; mid?: number; high?: number }
}

export enum BucketConfigurationDemand {
  LOW = 'low',
  MID = 'mid',
  HIGH = 'high',
}
