import React, { useMemo, useState, useCallback, useEffect } from 'react'
import {
  Button,
  Input,
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemMainContent,
  ListItemParagraph,
  Textarea,
  TextareaContainer,
  TextareaCounter,
  Toggle,
} from '@sweetspot/scramble-ds'
import { useTranslation } from 'react-i18next'
import { useTimeZone } from '@sweetspot/sweetspot-js/common/react-query/hooks/useTimeZone'
import { Membership, Timezone, ViolationError } from '@sweetspot/shared/types'
import {
  getNonConfirmedBookingDurationOptions,
  getUnpaidBookingDurationOptions,
  LAST_CANCELLATION_POINT,
  MINUTES_180_MS,
  MINUTES_5_MS,
} from '@sweetspot/sweetspot-js/common/constants/autoCancelSettingsOptions'
import { useMembership } from '@sweetspot/sweetspot-js/common/react-query/hooks/useMembership'
import { useSelector } from 'react-redux'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import ExpandingPills from '@sweetspot/club-portal-legacy/components/ExpandingPills'
import Pill from '@sweetspot/sweetspot-js/common/components/Pill'
import COURSE_TYPES from '@sweetspot/sweetspot-js/features/courses/constants/courseTypes'
import { useToasts } from 'react-toast-notifications'
import { useMutation } from 'react-query'
import { createRange, UpdateRangePayload } from '@sweetspot/sweetspot-js/features/ranges/services'
import { DEFAULT_CANCEL_POINT } from './constants'
import { isViolationError } from './utils'

type FormatedTimeZone = {
  id: string
  name: string
  offset: number
  timezone_id: string
}

// TODO: delete this file after manage range is ready
const CreateDrivingRange = () => {
  const { t } = useTranslation()
  const { timezones } = useTimeZone()
  const { addToast } = useToasts()

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [displayDays, setDisplayDays] = useState('')
  const [bookingInfo, setBookingInfo] = useState('')
  const [importantBookingInfo, setImportantBookingInfo] = useState('')
  const [customBookingEmailInfo, setCustomBookingEmailInfo] = useState('')
  const [timezone, setTimezone] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [vat, setVat] = useState('')
  const [active, setActive] = useState(true)
  const [enablePayment, setEnablePayment] = useState(false)
  const [enableArrivalRegistration, setEnableArrivalRegistration] = useState(true)
  const [lastCancelPoint, setLastCancelPoint] = useState<number>(DEFAULT_CANCEL_POINT)
  const [enableARAfterSchedule, setEnableARAfterSchedule] = useState(false)
  const [unpaidBookingAutoCancellationEnabled, setUnpaidBookingAutoCancellationEnabled] =
    useState(false)
  const [enableStubPlayers, setEnableStubPlayers] = useState(false)
  const [enablePayOnSite, setEnablePayOnSite] = useState(false)
  const [enableMembershipSignup, setEnableMembershipSignup] = useState(false)
  const [customPayOnSiteTitle, setCustomPayOnSiteTitle] = useState<string | null>(null)
  const [customPayOnSiteDesc, setCustomPayOnSiteDesc] = useState<string | null>(null)
  const [unpaidBookingAutoCancellationDuration, setUnpaidBookingAutoCancellationDuration] =
    useState<number>(MINUTES_180_MS)
  const [unpaidBookingAutoCancellationDays, setUnpaidBookingAutoCancellationDays] = useState<
    string | number
  >('')
  const [
    nonConfirmedBookingAutoCancellationEnabled,
    setNonConfirmedBookingAutoCancellationEnabled,
  ] = useState(true)
  const [
    nonConfirmedBookingAutoCancellationDuration,
    setNonConfirmedBookingAutoCancellationDuration,
  ] = useState<number>(MINUTES_5_MS)
  const [memberships, setMemberships] = useState<Membership[]>([])
  const [allowedMemberships, setAllowedMemberships] = useState<Membership[]>([])

  const createRangeMutation = useMutation((data: UpdateRangePayload) => createRange(data), {
    onSuccess: () => {
      addToast(t('sentences.drivingRangeCreated'), { appearance: 'success' })
    },
    onError: (error: { error: string } | ViolationError) => {
      if (isViolationError(error)) {
        if (error?.violations) {
          error.violations?.forEach((err) => {
            if (err?.propertyPath === 'non_confirmed_booking_auto_cancellation_duration') {
              addToast(
                `"${t('sentences.nonConfirmedBookingAutoCancellationTime')}" ${t(
                  'errors.fieldInvalid'
                )}`,
                { appearance: 'error' }
              )
            } else if (err?.propertyPath === 'unpaid_booking_auto_cancellation_duration') {
              addToast(
                `"${t('sentences.unpaidBookingAutoCancellationTimeFromApp')}" ${t(
                  'errors.fieldInvalid'
                )}`,
                { appearance: 'error' }
              )
            } else {
              addToast(t('toast.defaultError'), { appearance: 'error' })
            }
          })
        } else {
          addToast(t('toast.defaultError'), { appearance: 'error' })
        }
      } else {
        if (error?.error?.includes('name: Range with this name already exists.')) {
          addToast(t('sentences.rangeNameAlreadyExists'), { appearance: 'error' })
        } else {
          addToast(t('toast.defaultError'), { appearance: 'error' })
        }
      }
    },
  })

  const [currentClubId, currentClubUuid] = useSelector((state: RootState) => {
    const clubUuId = state?.golfClub?.list?.find(
      (club) => club.id === state?.golfClub?.selectedId || ''
    )?.uuid
    const clubId = state?.golfClub?.selectedId || 0

    return [clubId, clubUuId] as [number, string]
  })

  const { data: membershipData } = useMembership({
    clubId: currentClubId,
    state: 'published',
    totalFee: 0,
    sortOrder: 'asc',
    isActive: true,
    enabled: true,
  })
  useEffect(() => {
    setMemberships(membershipData as Membership[])
  }, [membershipData])

  const sortByName = (a: string, b: string) => {
    const aName = a.toUpperCase()
    const bName = b.toUpperCase()
    if (aName < bName) return -1
    if (aName === bName) return 0
    if (aName > bName) return 1
    return 0
  }

  const handleMembershipChange = (id: string, action: 'add' | 'remove') => {
    // Add
    if (action === 'add') {
      const newMember = memberships.find((item) => item.id === +id)
      if (newMember) {
        const newMembers = [...allowedMemberships, newMember]
        setMemberships(memberships.filter((item) => item.id !== +id))
        setAllowedMemberships(newMembers)
      }
    }
    // Remove
    else if (action === 'remove') {
      const tempMembership = allowedMemberships.find((item) => item.id === +id)
      const tempMemberships = memberships.slice()
      tempMembership && tempMemberships.push(tempMembership)
      tempMemberships.sort((a, b) => sortByName(a.name, b.name))
      setMemberships(tempMemberships)
      setAllowedMemberships(allowedMemberships.filter((item) => item.id !== +id))
    }
  }

  const formatedTimeZones: FormatedTimeZone[] = useMemo(() => {
    if (timezones) {
      const filteredData = timezones.map((zone: Timezone) => {
        const offset = Math.floor(zone.offset / 3600)
        const offsetString = offset > 0 ? `+${offset}` : offset
        return {
          id: zone.timezone_id,
          name: `(UTC ${offsetString}) ${zone.timezone_id}`,
          ...zone,
        }
      })
      return filteredData
    }
    return []
  }, [timezones])

  const lastCancelPointOptions: { value: number; label: string }[] = useMemo(() => {
    return LAST_CANCELLATION_POINT.map((option) => ({
      ...option,
      label: t(option.label.translation, option.label.options),
    }))
  }, [t])

  const NON_CONFIRMED_BOOKING_DURATION_OPTIONS = useMemo(
    () => getNonConfirmedBookingDurationOptions(),
    []
  )
  const UNPAID_BOOKING_DURATION_OPTIONS = useMemo(() => getUnpaidBookingDurationOptions(), [])

  const handleUpdateUnpaidBookingAutoCancellationDuration = useCallback((newValue: string) => {
    setUnpaidBookingAutoCancellationDuration(+newValue)
  }, [])

  const handelUnpaidBookingAutoCancellationDaysChange = (value: string) => {
    const durationValue = value.split('.')[0]
    if (+durationValue > 0 && +durationValue <= 90) {
      setUnpaidBookingAutoCancellationDays(+durationValue)
      setUnpaidBookingAutoCancellationDuration(0)
      return
    }
    setUnpaidBookingAutoCancellationDays('')
  }

  const checkForErrors = useCallback(
    ({
      name,
      payOnSiteTitle,
      payOnSiteDescription,
      displayTeeTimesDays,
      vat,
    }: Pick<
      UpdateRangePayload,
      'name' | 'payOnSiteTitle' | 'payOnSiteDescription' | 'displayTeeTimesDays' | 'vat'
    >): string => {
      if (name?.trim() === '') return 'settings.nameRequired'
      if (payOnSiteTitle?.length && payOnSiteTitle.length > 24)
        return 'settings.payOnSiteTitleError'
      if (payOnSiteDescription?.length && payOnSiteDescription.length > 160)
        return 'settings.payOnSiteDescError'
      if (displayTeeTimesDays !== null && displayTeeTimesDays < 0)
        return 'sentences.invalidDisplayTeeTimeDays'
      if (!vat || vat < 1 || vat > 100)
        return `${t('words.vat')}: ${t('errors.valueMustBeBetweenThese', { this: 1, that: 100 })}`
      return ''
    },
    [t]
  )

  const handleSubmit = useCallback(() => {
    const payload: UpdateRangePayload = {
      organizationId: currentClubUuid,
      name,
      description,
      displayTeeTimesDays: displayDays === '' ? null : parseInt(displayDays),
      bookingInformation: bookingInfo,
      importantBookingInformation: importantBookingInfo,
      customEmailInformation: customBookingEmailInfo,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      vat: parseFloat(vat),
      isActive: active,
      isCanPay: enablePayment,
      isArrivalRegistration: enableArrivalRegistration,
      isArrivalRegistrationAfterSchedule: enableARAfterSchedule,
      bookingCancellationLimitHours: lastCancelPoint,
      nonConfirmedBookingAutoCancellationEnabled: nonConfirmedBookingAutoCancellationEnabled,
      nonConfirmedBookingAutoCancellationDuration: nonConfirmedBookingAutoCancellationEnabled
        ? nonConfirmedBookingAutoCancellationDuration
        : null,
      unpaidBookingAutoCancellationEnabled: unpaidBookingAutoCancellationEnabled,
      unpaidBookingAutoCancellationDuration:
        unpaidBookingAutoCancellationEnabled && unpaidBookingAutoCancellationDuration !== 0
          ? unpaidBookingAutoCancellationDuration
          : null,
      isStubPlayersEnabled: enableStubPlayers,
      isPayOnSiteEnabled: enablePayOnSite,
      payOnSiteTitle: enablePayOnSite ? customPayOnSiteTitle : null,
      payOnSiteDescription: enablePayOnSite ? customPayOnSiteDesc : null,
      is_enabled: allowedMemberships?.length > 0 ? enableMembershipSignup : false,
      membershipSignUpSettings: {
        isEnabled: !!allowedMemberships.length,
        memberships: allowedMemberships.map((item) => {
          return item.uuid
        }),
      },
      unpaidBookingAutoCancellationDays:
        unpaidBookingAutoCancellationEnabled && unpaidBookingAutoCancellationDays !== ''
          ? unpaidBookingAutoCancellationDays
          : null,
      type: COURSE_TYPES.DRIVING_RANGE.value,
      ...(timezone ? { timezone } : {}),
    }

    const errorText = checkForErrors(payload)

    if (errorText) {
      addToast(t(errorText), { appearance: 'error' })
    } else {
      createRangeMutation.mutate(payload)
    }
  }, [
    active,
    addToast,
    allowedMemberships,
    bookingInfo,
    checkForErrors,
    createRangeMutation,
    currentClubUuid,
    customBookingEmailInfo,
    customPayOnSiteDesc,
    customPayOnSiteTitle,
    description,
    displayDays,
    enableARAfterSchedule,
    enableArrivalRegistration,
    enableMembershipSignup,
    enablePayOnSite,
    enablePayment,
    enableStubPlayers,
    importantBookingInfo,
    lastCancelPoint,
    latitude,
    longitude,
    name,
    nonConfirmedBookingAutoCancellationDuration,
    nonConfirmedBookingAutoCancellationEnabled,
    t,
    timezone,
    unpaidBookingAutoCancellationDays,
    unpaidBookingAutoCancellationDuration,
    unpaidBookingAutoCancellationEnabled,
    vat,
  ])

  return (
    <div className="m-10 flex w-1/3 flex-col gap-6">
      <h3 className={'flex-1 text-lg font-bold'}>{t('sentences.createDrivingRange')}</h3>
      <InputBase>
        <InputLabelContainer>{t('settings.name')}</InputLabelContainer>
        <InputContainer>
          <Input value={name} onChange={(event) => setName(event.target.value)} />
        </InputContainer>
      </InputBase>

      <InputBase>
        <InputLabelContainer>{t('settings.description')}</InputLabelContainer>
        <InputContainer>
          <Input value={description} onChange={(event) => setDescription(event.target.value)} />
        </InputContainer>
      </InputBase>

      <InputBase>
        <InputLabelContainer>{t('settings.displayDays')}</InputLabelContainer>
        <InputContainer>
          <Input value={displayDays} onChange={(event) => setDisplayDays(event.target.value)} />
        </InputContainer>
      </InputBase>

      <InputBase>
        <InputLabelContainer>{t('settings.bookingInfo')}</InputLabelContainer>
        <TextareaContainer>
          <Textarea value={bookingInfo} onChange={(event) => setBookingInfo(event.target.value)} />
        </TextareaContainer>
      </InputBase>

      <InputBase>
        <InputLabelContainer>{t('settings.importantBookingInfo')}</InputLabelContainer>
        <TextareaContainer>
          <Textarea
            value={importantBookingInfo}
            onChange={(event) => setImportantBookingInfo(event.target.value)}
          />
        </TextareaContainer>
      </InputBase>

      <InputBase>
        <InputLabelContainer>{t('settings.customBookingEmailInfo')}</InputLabelContainer>
        <TextareaContainer>
          <Textarea
            value={customBookingEmailInfo}
            onChange={(event) => setCustomBookingEmailInfo(event.target.value)}
          />
        </TextareaContainer>
      </InputBase>

      <InputSelect onValueChange={(selectedZone) => setTimezone(selectedZone)}>
        <InputBase>
          <InputLabelContainer> {t('words.timezone')}</InputLabelContainer>
          <InputSelectTrigger asChild>
            <InputContainer>
              <InputSelectStatefulInput
                value={formatedTimeZones.find((zone) => zone.id === timezone)?.name || ''}
              />
              <InputSelectTrailingContainer />
            </InputContainer>
          </InputSelectTrigger>
        </InputBase>

        <InputSelectContent>
          <InputSelectContentView>
            {formatedTimeZones.map((zone) => (
              <InputSelectItem key={zone.id} value={zone.id}>
                <ListItem className="ml-2 h-full justify-center gap-2">
                  <ListItemMainContent className="justify-center">
                    <ListItemParagraph className="text-content-base">{zone.name}</ListItemParagraph>
                  </ListItemMainContent>
                </ListItem>
              </InputSelectItem>
            ))}
          </InputSelectContentView>
        </InputSelectContent>
      </InputSelect>

      <InputBase>
        <InputLabelContainer>{t('settings.latitude')}</InputLabelContainer>
        <InputContainer>
          <Input value={latitude} onChange={(event) => setLatitude(event.target.value)} />
        </InputContainer>
      </InputBase>

      <InputBase>
        <InputLabelContainer>{t('settings.longitude')}</InputLabelContainer>
        <InputContainer>
          <Input value={longitude} onChange={(event) => setLongitude(event.target.value)} />
        </InputContainer>
      </InputBase>

      <InputBase>
        <InputLabelContainer>{t('words.vat')}</InputLabelContainer>
        <InputContainer>
          <Input value={vat} onChange={(event) => setVat(event.target.value)} />
        </InputContainer>
      </InputBase>

      <div className="flex min-h-12 flex-col">
        <label className="text-text-dark text-content-sm px-md font-bold">
          {t('settings.active')}
        </label>
        <Toggle
          className={'ml-2'}
          checked={active}
          onCheckedChange={(newValue) => setActive(newValue)}
        />
      </div>

      <div className="flex min-h-12 flex-col">
        <label className="text-text-dark text-content-sm px-md font-bold">
          {t('settings.enablePayment')}
        </label>
        <Toggle
          className={'ml-2'}
          checked={enablePayment}
          onCheckedChange={(newValue) => setEnablePayment(newValue)}
        />
      </div>

      <div className="flex min-h-12 flex-col">
        <label className="text-text-dark text-content-sm px-md font-bold">
          {t('settings.enableArrivalRegistration')}
        </label>
        <Toggle
          className={'ml-2'}
          checked={enableArrivalRegistration}
          onCheckedChange={(newValue) => setEnableArrivalRegistration(newValue)}
        />
      </div>

      <div className="flex min-h-12 flex-col">
        <label className="text-text-dark text-content-sm px-md font-bold">
          {t('settings.enableArrivalRegistrationAfterSchedule')}
        </label>
        <Toggle
          className={'ml-2'}
          checked={enableARAfterSchedule}
          onCheckedChange={(newValue) => setEnableARAfterSchedule(newValue)}
        />
      </div>

      <InputSelect onValueChange={(newValue) => setLastCancelPoint(+newValue)}>
        <InputBase>
          <InputLabelContainer> {t('golfcourseForm.labels.lastCancelPoint')}</InputLabelContainer>
          <InputSelectTrigger asChild>
            <InputContainer>
              <InputSelectStatefulInput
                value={
                  lastCancelPointOptions.find(
                    (option) => String(option.value) === String(lastCancelPoint)
                  )?.label
                }
              />
              <InputSelectTrailingContainer />
            </InputContainer>
          </InputSelectTrigger>
        </InputBase>

        <InputSelectContent>
          <InputSelectContentView>
            {lastCancelPointOptions.map((option) => (
              <InputSelectItem key={option.value} value={String(option.value)}>
                <ListItem className="ml-2 h-full justify-center gap-2">
                  <ListItemMainContent className="justify-center">
                    <ListItemParagraph className="text-content-base">
                      {option.label}
                    </ListItemParagraph>
                  </ListItemMainContent>
                </ListItem>
              </InputSelectItem>
            ))}
          </InputSelectContentView>
        </InputSelectContent>
      </InputSelect>

      <div className="flex min-h-12 flex-col">
        <label className="text-text-dark text-content-sm px-md font-bold">
          {t('sentences.enableAutocancelOnNonConfirmedBookings')}
        </label>
        <Toggle
          className={'ml-2'}
          checked={nonConfirmedBookingAutoCancellationEnabled}
          onCheckedChange={(newValue) => setNonConfirmedBookingAutoCancellationEnabled(newValue)}
        />
      </div>

      {nonConfirmedBookingAutoCancellationEnabled && (
        <InputSelect
          onValueChange={(newValue) => setNonConfirmedBookingAutoCancellationDuration(+newValue)}
        >
          <InputBase>
            <InputLabelContainer>
              {' '}
              {t('sentences.nonConfirmedBookingAutoCancellationTime')}
            </InputLabelContainer>
            <InputSelectTrigger asChild>
              <InputContainer>
                <InputSelectStatefulInput
                  value={
                    NON_CONFIRMED_BOOKING_DURATION_OPTIONS.find(
                      (x) => String(x.value) === String(nonConfirmedBookingAutoCancellationDuration)
                    )?.label || ''
                  }
                />
                <InputSelectTrailingContainer />
              </InputContainer>
            </InputSelectTrigger>
          </InputBase>

          <InputSelectContent>
            <InputSelectContentView>
              {NON_CONFIRMED_BOOKING_DURATION_OPTIONS.map((option) => (
                <InputSelectItem key={option.value} value={String(option.value || 0)}>
                  <ListItem className="ml-2 h-full justify-center gap-2">
                    <ListItemMainContent className="justify-center">
                      <ListItemParagraph className="text-content-base">
                        {option.label}
                      </ListItemParagraph>
                    </ListItemMainContent>
                  </ListItem>
                </InputSelectItem>
              ))}
            </InputSelectContentView>
          </InputSelectContent>
        </InputSelect>
      )}

      <div className="flex min-h-12 flex-col">
        <label className="text-text-dark text-content-sm px-md font-bold">
          {t('sentences.enableAutocancelOnUnpaidBookingsFromApp')}
        </label>
        <Toggle
          className={'ml-2'}
          checked={unpaidBookingAutoCancellationEnabled}
          onCheckedChange={(newValue) => setUnpaidBookingAutoCancellationEnabled(newValue)}
        />
      </div>

      {unpaidBookingAutoCancellationEnabled && (
        <>
          <InputSelect onValueChange={handleUpdateUnpaidBookingAutoCancellationDuration}>
            <InputBase>
              <InputLabelContainer>
                {' '}
                {t('sentences.unpaidBookingAutoCancellationTimeFromApp')}
              </InputLabelContainer>
              <InputSelectTrigger asChild>
                <InputContainer>
                  <InputSelectStatefulInput
                    value={
                      UNPAID_BOOKING_DURATION_OPTIONS.find(
                        (x) => String(x.value) === String(unpaidBookingAutoCancellationDuration)
                      )?.label || t('dateTime.words.custom')
                    }
                  />
                  <InputSelectTrailingContainer />
                </InputContainer>
              </InputSelectTrigger>
            </InputBase>

            <InputSelectContent>
              <InputSelectContentView>
                {UNPAID_BOOKING_DURATION_OPTIONS.map((option) => (
                  <InputSelectItem key={option.value} value={String(option.value || 0)}>
                    <ListItem className="ml-2 h-full justify-center gap-2">
                      <ListItemMainContent className="justify-center">
                        <ListItemParagraph className="text-content-base">
                          {option.label}
                        </ListItemParagraph>
                      </ListItemMainContent>
                    </ListItem>
                  </InputSelectItem>
                ))}
              </InputSelectContentView>
            </InputSelectContent>
          </InputSelect>
          {!unpaidBookingAutoCancellationDuration && (
            <InputBase>
              <InputLabelContainer>
                {t('sentences.unpaidBookingCustomAutoCancellationTimeLabel')}
              </InputLabelContainer>
              <InputContainer>
                <Input
                  type="number"
                  value={unpaidBookingAutoCancellationDays}
                  onChange={(event) =>
                    handelUnpaidBookingAutoCancellationDaysChange(event.target.value)
                  }
                />
              </InputContainer>
            </InputBase>
          )}
        </>
      )}

      <div className="flex min-h-12 flex-col">
        <label className="text-text-dark text-content-sm px-md font-bold">
          {t('settings.enableStubPlayers')}
        </label>
        <Toggle
          className={'ml-2'}
          checked={enableStubPlayers}
          onCheckedChange={(newValue) => setEnableStubPlayers(newValue)}
        />
      </div>

      <div className="flex min-h-12 flex-col">
        <label className="text-text-dark text-content-sm px-md font-bold">
          {t('settings.enablePayOnSite')}
        </label>
        <Toggle
          className={'ml-2'}
          checked={enablePayOnSite}
          onCheckedChange={(newValue) => setEnablePayOnSite(newValue)}
        />
      </div>

      {enablePayOnSite && (
        <>
          <InputBase>
            <InputLabelContainer>{t('settings.customPayOnSiteTitle')}</InputLabelContainer>
            <TextareaContainer maxCharacters={24}>
              <Textarea
                rows={1}
                value={customPayOnSiteTitle || ''}
                onChange={(event) => setCustomPayOnSiteTitle(event.target.value)}
              />
              <TextareaCounter />
            </TextareaContainer>
          </InputBase>
          <InputBase>
            <InputLabelContainer>{t('settings.customPayOnSiteDesc')}</InputLabelContainer>
            <TextareaContainer maxCharacters={160}>
              <Textarea
                value={customPayOnSiteDesc || ''}
                onChange={(event) => setCustomPayOnSiteDesc(event.target.value)}
              />
              <TextareaCounter />
            </TextareaContainer>
          </InputBase>
        </>
      )}

      <div className="flex min-h-12 flex-col">
        <label className="text-text-dark text-content-sm px-md font-bold">
          {t('settings.allowSignupDuringBooking')}
        </label>
        <Toggle
          className={'ml-2'}
          checked={enableMembershipSignup}
          onCheckedChange={(newValue) => setEnableMembershipSignup(newValue)}
        />
      </div>

      {enableMembershipSignup && (
        <InputSelect
          onValueChange={(selectedValue) => handleMembershipChange(selectedValue, 'add')}
        >
          <InputBase>
            <InputLabelContainer> {t('settings.allowedMembership')}</InputLabelContainer>
            <InputSelectTrigger asChild>
              <InputContainer>
                <InputSelectStatefulInput />
                <InputSelectTrailingContainer />
              </InputContainer>
            </InputSelectTrigger>
          </InputBase>

          <InputSelectContent>
            <InputSelectContentView>
              {memberships?.map((membership) => (
                <InputSelectItem key={membership.id} value={String(membership.id)}>
                  <ListItem className="ml-2 h-full justify-center gap-2">
                    <ListItemMainContent className="justify-center">
                      <ListItemParagraph className="text-content-base">
                        {membership.name}
                      </ListItemParagraph>
                    </ListItemMainContent>
                  </ListItem>
                </InputSelectItem>
              ))}
            </InputSelectContentView>
          </InputSelectContent>
          <ExpandingPills className="-mt-4">
            {allowedMemberships?.map((item) => (
              <Pill
                key={item.id}
                text={item.name}
                useCloseButton={true}
                onClose={() => handleMembershipChange(String(item.id), 'remove')}
              />
            ))}
          </ExpandingPills>
        </InputSelect>
      )}

      <div className="flex justify-end">
        <Button onClick={handleSubmit}>{t('words.create')}</Button>
      </div>
    </div>
  )
}

export default CreateDrivingRange
