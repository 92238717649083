import {
  useCallback,
  createContext,
  useMemo,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'
import { ManageRangeContextValue } from '@sweetspot/club-portal-legacy/pages/Settings/components/ManageRange/types'
import useRanges from '@sweetspot/club-portal-legacy/hooks/useRanges'

export const ManageRangeContext = createContext<ManageRangeContextValue>({
  areRangesLoading: false,
  ranges: [],
  openCreateRangeSidebar: false,
  refetchRanges: () => {
    return
  },
  toggleCreateRangeSideBar: () => {
    return
  },
})

export const ManageRangeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openCreateRangeSidebar, setOpenBaySideBar] = useState(false)
  const { ranges, areRangesLoading, selectedRange, setSelectedRange, refetchRanges } = useRanges()

  const toggleCreateRangeSideBar = useCallback(() => {
    setOpenBaySideBar((prev) => !prev)
  }, [])

  const value = useMemo(
    () => ({
      areRangesLoading,
      ranges,
      selectedRange,
      setSelectedRange,
      refetchRanges,
      openCreateRangeSidebar,
      toggleCreateRangeSideBar,
    }),
    [
      areRangesLoading,
      ranges,
      selectedRange,
      setSelectedRange,
      refetchRanges,
      openCreateRangeSidebar,
      toggleCreateRangeSideBar,
    ]
  )

  return <ManageRangeContext.Provider value={value}>{children}</ManageRangeContext.Provider>
}

export const useManageRangeContext = () => useContext(ManageRangeContext)
