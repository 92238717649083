import {
  BallPricePayload,
  BucketConfigurationPayload,
  RangeWithConfiguration,
} from '@sweetspot/shared/data-access/api-platform'
import { BallPrice, BucketSize, OpeningHours } from '../types'
import { TrackingTech } from '@sweetspot/shared/types'
import {
  BALL_PRICE_TECHNOLOGIES_DEFAULT,
  BUCKET_SIZES_DEFAULT,
  PRICE_ROUNDING_DEFAULT,
} from '../constants'

// Function to find the earliest open hour and the latest close hour
function findTimeRange(openingHours) {
  let earliestOpen = '23:59'
  let latestClose = '00:00'

  for (const day in openingHours) {
    if (openingHours[day].open < earliestOpen) {
      earliestOpen = openingHours[day].open
    }
    if (openingHours[day].close > latestClose) {
      latestClose = openingHours[day].close
    }
  }

  return { earliestOpen, latestClose }
}

// Function to format time in HH:MM format
function formatTime(hour: number, minute: number) {
  return hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0')
}

// Function to generate one-hour sections between the earliest open and latest close hours
export function generateTimeSections(openingHours) {
  const { earliestOpen, latestClose } = findTimeRange(openingHours)

  const startHour = parseInt(earliestOpen.split(':')[0])
  const endHour = parseInt(latestClose.split(':')[0])

  const timeSections = []

  for (let hour = startHour; hour < endHour; hour++) {
    const start = formatTime(hour, 0)
    const end = formatTime(hour, 59)
    timeSections.push([start, end])
  }

  return timeSections
}

export const constructBucketConfigurationData = (
  data: RangeWithConfiguration
): {
  bucketSizes: BucketSize[]
  ballPrice: BallPrice
  priceRounding: number
  trackingTechnologies: TrackingTech[]
} => {
  const { tracking_technologies, range } = data || {}
  const { bucket_configuration, price_model } = range || {}

  const bucketSizes =
    bucket_configuration?.bucket_sizes?.map((bucketSize) => ({
      nrOfBalls: bucketSize.number_of_balls,
      discount: bucketSize.discount_percentage,
    })) || BUCKET_SIZES_DEFAULT

  const ballPrice =
    price_model?.ball_prices?.reduce<BallPrice>(
      (acc, ballPrice) => ({
        ...acc,
        [ballPrice.tracking_technology]: {
          ...(acc[ballPrice.tracking_technology] || {}),
          [ballPrice.demand_level]: (ballPrice.price_per_ball / 100).toFixed(2),
        },
      }),
      {}
    ) || {}

  return {
    bucketSizes,
    priceRounding: (bucket_configuration?.price_rounding || PRICE_ROUNDING_DEFAULT) / 100,
    ballPrice,
    trackingTechnologies:
      tracking_technologies?.map(({ id }) => id) || BALL_PRICE_TECHNOLOGIES_DEFAULT,
  }
}

export const constructBucketConfigurationPayload = (bucketConfiguration: {
  bucketSizes: BucketSize[]
  ballPrice: BallPrice
  priceRounding: number
  openingHours: OpeningHours
}): BucketConfigurationPayload => {
  const bucket_sizes =
    bucketConfiguration?.bucketSizes?.map((bucketSize) => ({
      number_of_balls: bucketSize.nrOfBalls,
      discount_percentage: bucketSize.discount,
    })) || []

  const ball_prices: BallPricePayload[] = []
  Object.entries(bucketConfiguration?.ballPrice || {}).forEach(([tracking_technology, prices]) => {
    Object.entries(prices).forEach(([demand_level, price_per_ball]) => {
      ball_prices.push({
        tracking_technology: tracking_technology as TrackingTech,
        demand_level,
        price_per_ball: parseInt(`${price_per_ball * 100}`),
      })
    })
  })

  const price_rounding = bucketConfiguration.priceRounding * 100

  return {
    price_rounding,
    bucket_sizes,
    ball_prices,
    opening_hours: bucketConfiguration.openingHours,
  }
}

export const validateBucketConfiguration = ({
  bucket_sizes,
  opening_hours,
  ball_prices,
}: Pick<BucketConfigurationPayload, 'bucket_sizes' | 'opening_hours' | 'ball_prices'>): string => {
  // validate opening hours
  const openingHoursError = Object.values(opening_hours).reduce(
    (acc, { open, close, disabled }) => {
      if (!disabled && !acc) {
        if (!open || !close) {
          return 'errors.missingOpeningHours'
        } else if (open >= close) {
          return 'errors.invalidOpeningHours'
        }
      }
      return acc
    },
    ''
  )
  if (openingHoursError) return openingHoursError

  // validate bucket sizes
  const hasDuplicatedBucketSizes = bucket_sizes.reduce((acc, { number_of_balls }, index) => {
    const duplicated = bucket_sizes.findIndex(
      (size, i) => size.number_of_balls === number_of_balls && i !== index
    )
    return acc || duplicated !== -1
  }, false)
  if (hasDuplicatedBucketSizes) return 'errors.duplicatedBucketSizes'

  // validate ball prices
  const ballPricesError = ball_prices.reduce((acc, { price_per_ball }) => {
    if (!acc && price_per_ball <= 0) {
      return 'errors.invalidBallPrices'
    }
    return acc
  }, '')
  if (ballPricesError) return ballPricesError

  return ''
}
