import { createRoot } from 'react-dom/client'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { store, persistor } from '@sweetspot/club-portal-legacy/store'
import * as amplitude from '@amplitude/analytics-browser'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/browser'
import packageJson from '../package.json'

import Root from '@sweetspot/club-portal-legacy/elements/Root'

import { unregister as unregisterServiceWorker } from './serviceWorker'

import './index.scss'
import '@sweetspot/club-portal-legacy/core/defaultStyle.scss'
import 'rc-time-picker/assets/index.css'
import 'react-table/react-table.css'
import '@sweetspot/sweetspot-js/ui-kit/scss/global.scss'
import { ErrorBoundaryFallback } from '@sweetspot/shared/util/error-handling'
import { API_ENV_KEYS, APP_NAMES } from '@sweetspot/shared/util/constants'

import { initClubPortalI18next } from '@sweetspot/shared/translations'
import { FlagProvider } from '@sweetspot/shared/util/feature-flag'

initClubPortalI18next()

const appVersion = packageJson?.version || '0.0.0'
global.appVersion = appVersion

const amplitudeApiKey =
  process.env.REACT_APP_API_ENV === API_ENV_KEYS.PROD
    ? process.env.REACT_APP_AMPLITUDE_API_KEY
    : process.env.REACT_APP_AMPLITUDE_API_KEY_DEV

amplitude.init(amplitudeApiKey, { defaultTracking: false })

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://dc2a4ae922394b31a0f8767854f4090a@o250597.ingest.sentry.io/5638760',
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    environment: process.env.REACT_APP_API_ENV,
    release: `club-portal@${appVersion}`,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0,

    // Replay settings
    blockAllMedia: false,
    maskAllText: false,
  })
}

const container = document.getElementById('root')
const root = createRoot(container)

const mount = async () => {
  root.render(
    <Sentry.ErrorBoundary
      fallback={(props) => <ErrorBoundaryFallback appOrigin={APP_NAMES.CLUB_PORTAL} {...props} />}
      beforeCapture={(scope) => {
        scope.setTag('location', 'club-portal')
      }}
    >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <FlagProvider>
            <Root />
          </FlagProvider>
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  )
}

mount()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
unregisterServiceWorker()
