import React from 'react'
import { useTranslation } from 'react-i18next'
import { Tag } from '@sweetspot/scramble-ds'
import {
  calculateCreatedDate,
  calculateDuration,
  calculateTimeLeft,
  calculateTotalAmount,
} from '../../../utils'
import { PaymentLinkDetailsRow } from './PaymentLinkDetailsRow'
import { useClubCurrency } from '@sweetspot/sweetspot-js/common/hooks/useClubCurrency'
import { PaymentStateTag } from '../../../components/common/PaymentStateTag'
import { SinglePaymentDetailsProps } from '../../types'

export const SinglePaymentDetails = ({
  firstPayment,
  fromPaymentSummary,
  booking,
}: SinglePaymentDetailsProps) => {
  const { t } = useTranslation()

  const [currencyCode] = useClubCurrency()

  const { currency, amount } = calculateTotalAmount(
    [firstPayment],
    booking?.currency_code || currencyCode
  )
  const isUnpaid = firstPayment?.payment?.state === 'new'

  return (
    <div className="flex w-full flex-wrap">
      <PaymentLinkDetailsRow title={t('Status')}>
        <PaymentStateTag paymentLink={firstPayment} className="mt-1 w-max" />
      </PaymentLinkDetailsRow>
      <PaymentLinkDetailsRow
        title={
          fromPaymentSummary ? t('paymentLinks.paymentDuration') : t('paymentLinks.finalPayment')
        }
      >
        <Tag variant="primary" className="mt-1 w-max px-2 font-semibold">
          {calculateDuration(firstPayment?.created_at, firstPayment?.expires_at)}
        </Tag>
      </PaymentLinkDetailsRow>
      <PaymentLinkDetailsRow title={t('paymentLinks.totalAmount')}>
        <p>
          {currency}
          {amount}
        </p>
      </PaymentLinkDetailsRow>
      {isUnpaid && (
        <PaymentLinkDetailsRow title={t('paymentLinks.dueTime')}>
          <Tag variant="primary" className="mt-1 w-max px-2 font-semibold">
            {firstPayment?.expires_at && calculateTimeLeft(firstPayment?.expires_at)}
          </Tag>
        </PaymentLinkDetailsRow>
      )}
      <PaymentLinkDetailsRow title={t('paymentLinks.createdOn')}>
        <p>{calculateCreatedDate(firstPayment?.created_at)}</p>
      </PaymentLinkDetailsRow>
    </div>
  )
}
