import {
  InputBase,
  InputContainer,
  InputLabelContainer,
  InputLeadingContainer,
  InputSelect,
  InputSelectContent,
  InputSelectContentHeader,
  InputSelectContentView,
  InputSelectItem,
  InputSelectStatefulInput,
  InputSelectTrailingContainer,
  InputSelectTrigger,
  ListItem,
  ListItemLeading,
  ListItemMainContent,
  ListItemParagraph,
} from '@sweetspot/scramble-ds'
import { CourseSelectProps } from '../types'
import { useTranslation } from 'react-i18next'

export const CourseSelect = ({
  setSelectedCourse,
  selectedCourse,
  golfCourses,
}: CourseSelectProps) => {
  const { t } = useTranslation()

  return (
    <InputSelect onValueChange={setSelectedCourse} value={selectedCourse}>
      <InputBase>
        <InputLabelContainer>{t('words.course')} *</InputLabelContainer>
        <InputSelectTrigger asChild>
          <InputContainer>
            <InputLeadingContainer>
              <i className="fa-kit fa-tee-regular" />
            </InputLeadingContainer>

            <InputSelectStatefulInput value={selectedCourse} className="pl-10" readOnly />
            <InputSelectTrailingContainer />
          </InputContainer>
        </InputSelectTrigger>
      </InputBase>

      <InputSelectContent>
        <InputSelectContentView>
          <InputSelectContentHeader className="mt-2">
            {t('sentences.pickCourse')}
          </InputSelectContentHeader>
          {golfCourses?.map((course) => (
            <InputSelectItem value={course?.name}>
              <ListItem className="ml-2 h-full justify-center gap-2">
                <ListItemLeading>
                  <i className="fa-kit fa-tee-regular" />
                </ListItemLeading>
                <ListItemMainContent className="justify-center">
                  <ListItemParagraph className="text-content-base">
                    {course?.name}
                  </ListItemParagraph>
                </ListItemMainContent>
              </ListItem>
            </InputSelectItem>
          ))}
        </InputSelectContentView>
      </InputSelectContent>
    </InputSelect>
  )
}
