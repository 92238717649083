import { useTranslation } from 'react-i18next'
import { track } from '@amplitude/analytics-browser'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'
import { Button, Divider } from '@sweetspot/scramble-ds'
import { PaymentCourseSettingsSheet } from '@sweetspot/club-portal/feature/payment-links'

export const Header = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="bg-background-base-clean flex h-[60px] items-center justify-between px-8 py-3">
        <h4 className="itmes-start text-content-lg font-bold"> {t('words.payment_plural')}</h4>
        <PaymentCourseSettingsSheet>
          <Button
            variant="link-dark"
            className="text-content-sm"
            onClick={() => track(AMPLITUDE_EVENTS.PAYMENT_LINKS.SETTINGS_TAPPED)}
          >
            <i className="fa-regular fa-cog" />
            {t('words.settings')}
          </Button>
        </PaymentCourseSettingsSheet>
      </div>
      <Divider variant="basic" />
    </>
  )
}
