// @ts-expect-error Imports from non-ts modules
import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'

/**
 * Generate a Hubspot token
 */
export const generateHubspotToken = (): Promise<unknown> => {
  const url = `${getBaseUrlPlatform()}/chat/hubspot/token`

  return request(url, {
    method: 'get',
  })
}
