import { useEffect, useRef, useState } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import { useToasts } from 'react-toast-notifications'

import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeaderLeftIcon,
  SheetTitle,
  SheetHeader,
  SheetCustomContent,
  SheetFooter,
  Button,
  SheetClose,
  EmptyState,
  InputBase,
  InputLabelContainer,
  InputContainer,
  Input,
  InputTrailingContainer,
  EmptyStateTitle,
  EmptyStateDescription,
} from '@sweetspot/scramble-ds'

import { UserInformationFormSchema } from './validation'
import { EditableInput, PaymentLinkDetails, PaymentLinkSettings } from './components'
import { INPUT_TYPES } from './constants'
import { usePaymentLinkSplit } from '../../hooks/usePaymentLinkSplit'

import { usePaymentLinksCreate } from '../../hooks/usePaymentLinksCreate'

import { SettingsFormScema } from '../../components/Settings/validation'
import {
  setFirstPaymentDurationFromSeconds,
  setLastPaymentDurationFromSeconds,
} from '../../components/Settings/utils'

import { FIRST_PAYMENT_PAY } from '../../consts'
import {
  calculateAmount,
  calculateExpiringDate,
  copyToClipboard,
  extractBookingFields,
  secondsToDays,
} from '../../utils'
import { track } from '@amplitude/analytics-browser'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'
import { PaymentCreateSheetProps, PaymentSubmitData } from '../types'
import { useGetPaymentLinksDetails } from '../../hooks/useGetPaymentLinksDetails'
import { PaymentLink } from '@sweetspot/shared/types'

export const PaymentCreateSheet = ({
  booking,
  course,
  children,
  handleUpdateBooking,
}: PaymentCreateSheetProps) => {
  const [editModes, setEditModes] = useState<{
    email: boolean
    phone: boolean
  }>({
    email: false,
    phone: false,
  })

  const [loadingStates, setLoadingStates] = useState<{
    email: boolean
    phone: boolean
  }>({
    email: false,
    phone: false,
  })

  const [checkedStates, setCheckedStates] = useState<{
    email: boolean
    phone: boolean
  }>({
    email: true,
    phone: false,
  })

  const [paymentResults, setPaymentResults] = useState<PaymentLink[]>([])

  const { t } = useTranslation()
  const { addToast } = useToasts()
  const bookingFields = extractBookingFields(booking)

  const bookingPayments = booking?.payments?.filter((payment) => !!payment?.payment_link) || []
  const paymentLinkData = bookingPayments[0]?.payment_link

  const { down_payment_percent, first_payment_duration, second_payment_duration } =
    course?.payment_link_configuration || {}

  const defaultSettingsFormValues = {
    partial_payments: down_payment_percent > 0,
    first_payment_pay: FIRST_PAYMENT_PAY.PERCENTAGE,
    percentage: down_payment_percent || 10,
    amount: calculateAmount(bookingFields.amount, down_payment_percent || 10),
    first_payment_duration: setFirstPaymentDurationFromSeconds(first_payment_duration),
    first_payment_duration_days: secondsToDays(first_payment_duration) || 10,
    last_payment_duration: setLastPaymentDurationFromSeconds(second_payment_duration),
    last_payment_duration_days: secondsToDays(second_payment_duration) || 30,
    note: '',
  }

  const defaultSettingsFormValuesRef = useRef(defaultSettingsFormValues)

  const {
    createPaymentLink,
    createPartialPaymentLink,
    isPaymentLoading,
    isPaymentIdle,
    isPaymentSuccess,
  } = usePaymentLinksCreate()

  const splitPayment = usePaymentLinkSplit(bookingFields)

  const { payment: firstPayment } = useGetPaymentLinksDetails({
    paymentResults,
    bookingPayments,
    handleUpdateBooking,
    paymentIndex: 0,
  })

  const { payment: secondPayment } = useGetPaymentLinksDetails({
    paymentResults,
    bookingPayments,
    handleUpdateBooking,
    paymentIndex: 1,
  })

  const userInformationForm = useForm<z.infer<typeof UserInformationFormSchema>>({
    resolver: zodResolver(UserInformationFormSchema),
    defaultValues: {
      email: bookingFields.ownerEmail,
      phone: bookingFields.ownerPhone,
    },
    mode: 'onChange',
    shouldUnregister: false,
  })

  const settingsForm = useForm<z.infer<typeof SettingsFormScema>>({
    resolver: zodResolver(SettingsFormScema),
    defaultValues: defaultSettingsFormValues,
    mode: 'onChange',
  })

  useEffect(() => {
    userInformationForm.reset({
      email: bookingFields.ownerEmail,
      phone: bookingFields.ownerPhone,
    })
  }, [bookingFields.ownerEmail, bookingFields.ownerPhone])

  const settingsValues = settingsForm.watch()
  const inputValues = userInformationForm.watch()

  const onSinglePaymentSubmit = async (formData: z.infer<typeof UserInformationFormSchema>) => {
    const paymentData = {
      deliveryMethods: formData,
      expiringDate: calculateExpiringDate(settingsValues.last_payment_duration_days),
      note: settingsValues.note,
    } as PaymentSubmitData

    try {
      const paymentResult = await createPaymentLink(paymentData, bookingFields.paymentId)

      setPaymentResults([paymentResult])
    } catch (e) {
      addToast(t('paymentLinks.singlePaymentFailed'), { appearance: 'error' })
    }
  }

  const onPartialPaymentSubmit = async (
    deliveryData: z.infer<typeof UserInformationFormSchema>
  ) => {
    const amount = calculateAmount(bookingFields.amount, settingsValues.percentage)

    const partialPaymentData = {
      deliveryMethods: deliveryData,
      expiringDate: calculateExpiringDate(settingsValues.first_payment_duration_days),
      note: settingsValues.note,
    } as PaymentSubmitData

    const singlePaymentData = {
      deliveryMethods: deliveryData,
      expiringDate: calculateExpiringDate(settingsValues.last_payment_duration_days),
      note: settingsValues.note,
    } as PaymentSubmitData

    try {
      const splitPaymentData = await splitPayment.send(amount)

      const paymentResults = await Promise.all([
        createPartialPaymentLink(partialPaymentData, bookingFields.paymentId),
        createPaymentLink(singlePaymentData, splitPaymentData?.uuid),
      ])

      setPaymentResults(paymentResults)
    } catch (e) {
      addToast(t('paymentLinks.partialPaymentFailed'), { appearance: 'error' })
    }
  }

  const onSubmit = async (formData: z.infer<typeof UserInformationFormSchema>) => {
    const deliveryData = {
      email: checkedStates?.email ? formData.email : null,
      phone: checkedStates?.phone ? formData.phone : null,
    }
    if (settingsValues.partial_payments) {
      await onPartialPaymentSubmit(deliveryData)
    } else {
      await onSinglePaymentSubmit(deliveryData)
    }

    const hasChanged = [
      'percentage',
      'amount',
      'first_payment_duration_days',
      'last_payment_duration_days',
    ].some((key) => settingsValues[key] !== defaultSettingsFormValuesRef.current[key])

    track({
      event_type: AMPLITUDE_EVENTS.PAYMENT_LINKS.SENT,
      event_properties: {
        [AMPLITUDE_EVENTS.PAYMENT_LINKS.DEFAULT_SETTINGS_OVERRIDEN]: hasChanged ? 'Yes' : 'No',
        [AMPLITUDE_EVENTS.PAYMENT_LINKS.DELIVERY_METHOD]:
          checkedStates.email && checkedStates.phone
            ? 'Email, SMS'
            : checkedStates.email
            ? 'Email'
            : 'SMS',
        [AMPLITUDE_EVENTS.PAYMENT_LINKS.RECIEVER_EDITED]:
          inputValues.email !== bookingFields.ownerEmail &&
          inputValues.phone !== bookingFields.ownerPhone
            ? 'Email, SMS'
            : inputValues.email !== bookingFields.ownerEmail
            ? 'Email'
            : inputValues.phone !== bookingFields.ownerPhone
            ? 'SMS'
            : undefined,
        [AMPLITUDE_EVENTS.PAYMENT_LINKS.NOTE_ADDED]: settingsValues.note?.length > 0 ? 'Yes' : 'No',
      },
    })
  }

  const handleToggleEdit = (type: string) => {
    setEditModes((prev) => ({ ...prev, [type]: !prev[type] }))
    if (editModes[type]) {
      setLoadingStates((prev) => ({ ...prev, [type]: true }))
      setTimeout(() => {
        setLoadingStates((prev) => ({ ...prev, [type]: false }))
      }, 1000)
    }
  }

  const handleToggleChecked = (type: string) => {
    setCheckedStates((prev) => {
      const otherType = type === INPUT_TYPES.PHONE ? INPUT_TYPES.PHONE : INPUT_TYPES.EMAIL
      if (!prev[type] || prev[otherType]) {
        return { ...prev, [type]: !prev[type] }
      }
      return prev
    })
  }

  const handleCopyToClipboard = () => {
    const url =
      firstPayment?.payment?.state === 'completed' ? secondPayment?.url : firstPayment?.url

    copyToClipboard({
      text: url,
      onSuccess: () => addToast(t('teeSheet.copied'), { appearance: 'success' }),
    })
  }

  const isEitherEmailOrPhoneChecked = checkedStates.email || checkedStates.phone

  return (
    <Sheet>
      <SheetTrigger asChild className="cursor-pointer">
        {children}
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetClose asChild>
            <SheetHeaderLeftIcon>
              <i className="fa-regular fa-close" />
            </SheetHeaderLeftIcon>
          </SheetClose>
          <SheetTitle className="text-lg">
            {isPaymentIdle && t('paymentLinks.newPaymentLink')}
            {isPaymentLoading && t('paymentLinks.creatingPaymentLink')}
            {isPaymentSuccess && !isPaymentLoading && t('paymentLinks.paymentActive')}
          </SheetTitle>
        </SheetHeader>

        <SheetCustomContent>
          {isPaymentIdle &&
            (!paymentLinkData || !paymentLinkData?.is_active || paymentLinkData?.is_expired) && (
              <>
                <InputBase className="mb-lg">
                  <InputLabelContainer className="pl-1">{t('words.amount')} *</InputLabelContainer>
                  <InputContainer>
                    <Input
                      rounding="rectangular"
                      className="text-content-base focus:border-border-stroke-clean cursor-default pl-2 focus:outline-none"
                      readOnly
                      type="text"
                      value={bookingFields.amount}
                    />
                    <InputTrailingContainer>
                      <p className="text-content-xs font-semibold">{bookingFields.currency}</p>
                    </InputTrailingContainer>
                  </InputContainer>
                </InputBase>
                <p className="text-text-dark text-content-sm mb-lg font-bold">
                  {t('paymentLinks.settingChangesInfo')}
                </p>
                <div className="mb-6">
                  <p className="text-text-dark text-content-base mb-lg font-semibold">
                    {t('paymentLinks.deliverPayment')}
                  </p>
                  <EditableInput
                    handleToggleChecked={() => handleToggleChecked(INPUT_TYPES.EMAIL)}
                    checked={checkedStates.email}
                    control={userInformationForm.control}
                    isEditMode={editModes.email}
                    error={userInformationForm.formState.errors.email}
                    handleToggleEdit={() => handleToggleEdit(INPUT_TYPES.EMAIL)}
                    isLoading={loadingStates.email}
                    name={INPUT_TYPES.EMAIL}
                    inputHintText="@"
                    hintText={t('paymentLinks.changePlayersEmail')}
                  />
                  <EditableInput
                    handleToggleChecked={() => handleToggleChecked(INPUT_TYPES.PHONE)}
                    checked={checkedStates.phone}
                    control={userInformationForm.control}
                    isEditMode={editModes.phone}
                    error={userInformationForm.formState.errors.phone}
                    handleToggleEdit={() => handleToggleEdit(INPUT_TYPES.PHONE)}
                    isLoading={loadingStates.phone}
                    name={INPUT_TYPES.PHONE}
                    inputHintText="SMS"
                    hintText={t('paymentLinks.changePlayersPhone')}
                  />
                </div>
                <PaymentLinkSettings settingsForm={settingsForm} bookingFields={bookingFields} />
              </>
            )}
          {isPaymentLoading && (
            <div className="mt-40">
              <EmptyState className="max-w-full" iconName="fa-loader" loading>
                <EmptyStateTitle>{t('paymentLinks.gearingUp')}</EmptyStateTitle>
                <EmptyStateDescription>
                  {t('paymentLinks.virtualCaddiesText')}
                </EmptyStateDescription>
              </EmptyState>
            </div>
          )}
          {(firstPayment || secondPayment) && (
            <PaymentLinkDetails
              firstPayment={firstPayment}
              secondPayment={secondPayment}
              booking={booking}
            />
          )}
        </SheetCustomContent>
        <SheetFooter className="pt-0">
          {(firstPayment || secondPayment) && (
            <Button
              className="w-full focus:!ring-2"
              variant="ghost-dark"
              disabled={firstPayment?.is_expired || secondPayment?.is_expired}
              onClick={() => {
                track(AMPLITUDE_EVENTS.PAYMENT_LINKS.COPY_TAPPED)
                handleCopyToClipboard()
              }}
            >
              <i className="fa-regular fa-copy" />
              {t('paymentLinks.copyPaymentLink')}
            </Button>
          )}
          {isPaymentIdle && !secondPayment && !firstPayment && (
            <Button
              className="w-full"
              onClick={async () => {
                const isValid = await userInformationForm.trigger()

                const { errors } = userInformationForm.formState

                if (
                  (!checkedStates.phone && errors.phone) ||
                  (!checkedStates.email && errors.email)
                ) {
                  onSubmit(userInformationForm.getValues())
                } else if (isValid) {
                  userInformationForm.handleSubmit(onSubmit)()
                }
              }}
              disabled={
                editModes.email ||
                editModes.phone ||
                loadingStates.email ||
                loadingStates.phone ||
                !isEitherEmailOrPhoneChecked ||
                Object.keys(settingsForm.formState.errors).length > 0
              }
            >
              {t('paymentLinks.sendPaymentLink')}
            </Button>
          )}
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
