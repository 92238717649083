import React, { useReducer, useEffect, useCallback, useState, useRef, useMemo } from 'react'
import { Prompt } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import m from 'moment'
import cx from 'classnames'
import PropTypes from 'prop-types'
import _uniqBy from 'lodash/uniqBy'
import { differenceInHours, isPast } from 'date-fns'
import { track } from '@amplitude/analytics-browser'

import styles from './styles.module.scss'

import BookingHeader from '@sweetspot/sweetspot-js/features/bookings/components/BookingHeader'
import ReserveNewBookingButton from '@sweetspot/sweetspot-js/features/bookings/components/ReserveNewBookingButton'
import BookingPaymentsTable from '@sweetspot/sweetspot-js/features/bookings/components/BookingPaymentsTable'
import PlayersControls from '@sweetspot/sweetspot-js/features/players/components/PlayersControls'
import PartnershipBookingSelect from '@sweetspot/sweetspot-js/features/bookings/components/PartnershipBookingSelect'

import CloseIcon from '@sweetspot/sweetspot-js/common/components/CloseIcon'
import Button from '@sweetspot/sweetspot-js/common/components/Button'
import ConfirmPopup from '@sweetspot/sweetspot-js/common/components/ConfirmPopup'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'

import DateHelpers from '@sweetspot/sweetspot-js/common/functions/DateHelpers'

import { ReactComponent as TrashIcon } from '@sweetspot/sweetspot-js/assets/svgs/trash-icon.svg'
import { ReactComponent as CaretDown } from '@sweetspot/sweetspot-js/assets/svgs/caret-down.svg'
import ScoreCardPrint from '@sweetspot/club-portal-legacy/modals/PrintScoreCardModal'
import BookingPlayersGrid from '@sweetspot/club-portal-legacy/components/BookingPlayersGrid'

import {
  to,
  objectToArray,
  arrayToObject,
  getNextChar,
  priceToLocal,
} from '@sweetspot/sweetspot-js/common/functions/utils'
import { APP_NAMES, STORAGE_KEYS } from '@sweetspot/shared/util/constants'
import { getCurrentAppName } from '@sweetspot/shared/util/functions'

import { renewDelayedMessage } from '@sweetspot/sweetspot-js/features/messages/services/api-platform'
import {
  setSessionStorage,
  getSessionStorage,
  removeSessionStorage,
} from '@sweetspot/shared/util/session-storage'

import {
  confirmOrderBooking,
  cancelOrderBooking,
  markBookingAsPaid,
  reserveBooking,
  removeSlot,
  reserveExtraSlots,
  assignPlayerToSlot,
  assignStubPlayerToSlot,
  setSlotOwner,
  updateBookingPayOnSiteStatus,
} from '@sweetspot/sweetspot-js/features/bookings/services/api-platform'
import { getCourseById } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'
import { getPartnerTypes } from '@sweetspot/sweetspot-js/features/partnerTypes/services/api-platform'
import { getGolfClubById } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import {
  saveNotes,
  getTeeTimeByUuid,
  getTeeTimePriceByUUID,
} from '@sweetspot/sweetspot-js/features/teeTimes/services/api-platform'
import { queryPaginatedPartnerships } from '@sweetspot/sweetspot-js/features/partnerships/services/api-platform'
import {
  getProducts as getProductsApi,
  getProductAvailability,
  addUniqueProductToOrder,
} from '@sweetspot/sweetspot-js/features/products/services/api-platform'

import {
  initialState,
  reducer,
  actions,
} from '@sweetspot/sweetspot-js/features/bookings/functions/bookingsState'

import {
  isBookingConfirmed,
  itemIsAssigned,
  bookingHasAtleastOneAssigned,
  getPlayerName,
  bookingHasOwner,
  getNumberOfSlots,
  getBookingTotal,
  getBookingSlotItems,
  getBookingCartItems,
  isBookingPartiallyRefunded,
  isBookingCancelled,
  isBookingPartiallyPaid,
  isBookingPaid,
  timeAllowsCancelBooking,
} from '@sweetspot/sweetspot-js/features/bookings/functions/utils'
import { getPriceObject } from '@sweetspot/sweetspot-js/features/teeTimes/js/utils'

import useStatusArray from '@sweetspot/sweetspot-js/common/hooks/useStatusArray'
import useKeyPress from '@sweetspot/sweetspot-js/common/hooks/useKeyPress'
import useViolationToasts from '@sweetspot/sweetspot-js/common/hooks/useViolationToasts'
import { useQueries, useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/sweetspot-js/common/react-query/constants/queries'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import useRoles from '@sweetspot/sweetspot-js/common/hooks/useRoles'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import BasicBox from '@sweetspot/sweetspot-js/common/components/BasicBox'
import BookingNotesCourses from '@sweetspot/sweetspot-js/features/bookings/components/BookingNotesCourses'
import getBookingWithRelations from '@sweetspot/sweetspot-js/features/bookings/functions/getBookingWithRelations'
import getMultipleBookingsWithRelations from '@sweetspot/sweetspot-js/features/bookings/functions/getMultipleBookingsWithRelations'

import {
  BOOKING_GROUPS,
  BOOKING_RELATIONS,
} from '@sweetspot/sweetspot-js/features/bookings/constants/bookingRelations'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import { ITEM_GROUPS } from '@sweetspot/sweetspot-js/features/bookings/constants/itemRelations'
import { ORDER_STATES } from '@sweetspot/sweetspot-js/features/bookings/constants/orderStates'
import { AMPLITUDE_EVENTS } from '@sweetspot/shared/util/constants'
import { BookingRow } from './components/BookingRow'

const BookingModal = ({
  teeTime,
  teeTimeUuid: teeTimeUuidProp,
  onClose,
  reserveSlots,
  teeTimeCategories = [],
  reserve = true,
  openHistorical = false,
  role,
  fromBookingsPage = false,
  EditTeeTimeComponent,
  singleBooking,
}) => {
  const { addToast } = useToasts()
  const { displayToasts } = useViolationToasts()
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const isFirstRender = useRef(true)

  const [state, dispatch] = useReducer(reducer, initialState)
  const [showPrintScoreCard, setShowPrintScoreCard] = useState(false)
  const [showGolfCartSelectionModal, setShowGolfCartSelectionModal] = useState(false)
  const [confirmingBooking, setConfirmingBooking] = useState(false)
  const [cancelingBooking, setCancelingBooking] = useState(false)
  const [showConfirmCancelBooking, setShowConfirmCancelBooking] = useState(false)
  const [showConfirmClose, setShowConfirmClose] = useState(false)
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(true)
  const [showBookingConfirmationPopup, setShowBookingConfirmationPopup] = useState(false)
  const [showBookingsCancelledPopup, setShowBookingsCancelledPopup] = useState(false)
  const [showHistorical, setShowHistorical] = useState(openHistorical)
  const [isOnReservation, setOnReservation] = useState(false)
  const [isAddingGolfCartToBooking, setAddGolfCartButtonState] = useState(false)
  const [removedGolfCart, setRemovedGolfCart] = useState(null)
  const [booking, setBooking] = useState(null)

  const [autoCancelData, setAutoCancelData] = useMergeState({})

  const { add: addOpenBooking, remove: removeOpenBooking, array: openBookings } = useStatusArray()
  const {
    add: addLoadingExtraSlots,
    remove: removeLoadingExtraSlots,
    hasItem: hasLoadingExtraSlots,
  } = useStatusArray()
  const {
    add: addLoadingGolfCartSlots,
    remove: removeLoadingGolfCartSlots,
    hasItem: hasLoadingGolfCartSlots,
  } = useStatusArray()
  const {
    add: addLoadingSlots,
    remove: removeLoadingSlots,
    array: loadingSlots,
    reset: clearLoadingSlots,
  } = useStatusArray()

  const keyEvent = useKeyPress()

  const roles = useRoles()
  const accessTable = useMemo(() => {
    const role = getFirstMatchingRole(roles)
    if (!role)
      return {
        SET_PARTNERSHIP: false,
        EDIT_TEE_TIMES: false,
      }
    return {
      SET_PARTNERSHIP: hasAccess(ACCESS_KEYS.FEATURES.BOOKING.EDIT.SET_PARTNERSHIP, role?.value),
      EDIT_TEE_TIMES: hasAccess(ACCESS_KEYS.FEATURES.BOOKING.EDIT.EDIT_TEE_TIMES, role?.value),
    }
  }, [roles])

  /** ------------------------------------ */
  /** ------------- CALLBACKS ------------ */
  /** ------------------------------------ */

  /**
   * Get single booking
   */
  const getBooking = useCallback(
    async (bookingUuid, bookings) => {
      const [bookingRes, err] = await to(
        getBookingWithRelations({
          bookingUuid,
          deserialize: true,
          includedRelations: [
            BOOKING_RELATIONS.BOOKING,
            BOOKING_RELATIONS.ITEMS,
            BOOKING_RELATIONS.PAYMENTS,
            BOOKING_RELATIONS.REFUNDS,
            BOOKING_RELATIONS.INVENTORY_SCHEDULES,
          ],
          includedBookingGroups: [BOOKING_GROUPS.ALL],
          includedItemGroups: [ITEM_GROUPS.ALL],
        })
      )
      if (bookingRes) {
        dispatch({
          type: actions.BOOKING_UPDATED,
          payload: {
            ...bookingRes,
            muteNotification: bookings[bookingRes.id]?.muteNotification,
          },
        })

        const cancelData = autoCancelData[bookingRes.uuid]

        if (cancelData) {
          if (isBookingConfirmed(bookingRes)) {
            setAutoCancelData({ [bookingRes.uuid]: null })
            removeSessionStorage(`${STORAGE_KEYS.DELAYED_MESSAGED_STORE_KEY}-${bookingRes.uuid}`)
            return
          }

          const inFiveMinutes = m.utc().add(5, 'minutes')
          const extendByMs = m.utc(inFiveMinutes).diff(m.utc(cancelData.xDelayTo))

          const [res] = await to(renewDelayedMessage(cancelData.xDelayRenewalToken, extendByMs))

          if (res?.delayed_to) {
            const newCancelData = { ...cancelData, xDelayTo: res.delayed_to }
            setAutoCancelData({
              [bookingRes.uuid]: newCancelData,
            })
            setSessionStorage(
              `${STORAGE_KEYS.DELAYED_MESSAGED_STORE_KEY}-${bookingRes.uuid}`,
              JSON.stringify(newCancelData)
            )
          }
        }
      }
      if (err) {
        addToast(t('sentences.couldNotLoadBooking'), { appearance: 'error' })
      }
      return
    },
    [addToast, t, autoCancelData, state.bookings]
  )

  /** ---------------------------------- */
  /** ------------- MEMOS -------------- */
  /** ---------------------------------- */
  const teeTimeUuid = useMemo(() => {
    return teeTimeUuidProp || teeTime?.uuid || null
  }, [teeTimeUuidProp, teeTime])

  /** ---------------------------------- */
  /** ------------- EFFECTS ------------ */
  /** ---------------------------------- */

  useEffect(() => {
    track(AMPLITUDE_EVENTS.COURSE_BOOKING_SCREEN_SEEN)
  }, [])

  useEffect(() => {
    if (singleBooking) {
      if (showHistorical) {
        dispatch({ type: actions.CANCELED_BOOKING_UPDATED, payload: singleBooking })
      } else {
        dispatch({ type: actions.BOOKING_UPDATED, payload: singleBooking })
      }
      dispatch({ type: actions.BOOKINGS_SET_LOADER, payload: false })
    }
  }, [singleBooking, showHistorical])

  const { refetch: getTeeTime, isFetching } = useQuery(
    [CLUB_QUERIES.TEE_TIMES, teeTimeUuid],
    () => {
      dispatch({ type: actions.TEE_TIME_UPDATING })
      return getTeeTimeByUuid(teeTimeUuid)
    },
    {
      enabled: !!teeTimeUuid,
      onError: () => {
        addToast(t('sentences.couldNotLoadTeeTime'), { appearance: 'error' })
      },
      onSuccess: (data) => {
        dispatch({ type: actions.TEE_TIME_UPDATED, payload: data })
      },
    }
  )

  useQuery(
    [CLUB_QUERIES.TEE_TIME_PRICE, state?.teeTime?.id],
    () => {
      dispatch({ type: actions.TEE_TIME_PRICE_UPDATING })
      if (state.course?.is_use_dynamic_pricing) {
        return getTeeTimePriceByUUID(state?.teeTime?.uuid)
      } else {
        return Promise.resolve(teeTime)
      }
    },
    {
      enabled: !!state?.course && !!state?.teeTime?.id,
      onError: () => {
        addToast(t('sentences.couldNotLoadTeeTimePrice'), { appearance: 'error' })
      },
      onSuccess: (data) => {
        dispatch({ type: actions.TEE_TIME_PRICE_UPDATED, payload: data })
      },
    }
  )

  useQuery(
    [CLUB_QUERIES.PARTNERSHIPS, state?.golfClub?.id],
    () => {
      dispatch({ type: actions.PARTNERSHIPS_UPDATING })
      return queryPaginatedPartnerships({
        'club.id[]': state.golfClub.id,
        'course.id': state.course.id,
        limit: 50,
      })
    },
    {
      enabled: !!state?.golfClub?.id,
      onError: () => {
        addToast(t('sentences.couldNotLoadPartnerships'), { appearance: 'error' })
      },
      // NOTE: Backend sends dublicate objects for some reason, that breaks search
      select: (res) => _uniqBy([...res.map((r) => r[0]['hydra:member'])].flat(), 'name'),
      onSuccess: (data) => {
        dispatch({
          type: actions.PARTNERSHIPS_UPDATED,
          payload: data.filter((x) => x.status === 'active' || x.status === 'upcoming'),
        })
      },
    }
  )

  useQuery(
    [CLUB_QUERIES.COURSES, state?.teeTime?.course?.id],
    () => {
      dispatch({ type: actions.COURSE_UPDATING })
      return getCourseById(state.teeTime.course.id)
    },
    {
      enabled: !!state?.teeTime?.course?.id,
      onError: () => {
        addToast(t('sentences.couldNotLoadGolfCourse'), { appearance: 'error' })
      },
      onSuccess: (data) => {
        dispatch({ type: actions.COURSE_UPDATED, payload: data })
      },
    }
  )

  useQuery(
    [CLUB_QUERIES.CLUBS, state?.course?.club?.id],
    () => {
      dispatch({ type: actions.GOLF_CLUB_UPDATING })
      return getGolfClubById(state.course.club.id)
    },
    {
      enabled: !!state?.course?.club?.id,
      onError: () => {
        addToast(t('sentences.couldNotLoadGolfClub'), { appearance: 'error' })
      },
      onSuccess: (data) => {
        dispatch({ type: actions.GOLF_CLUB_UPDATED, payload: data })
      },
    }
  )

  const allOrderBookings = useMemo(() => {
    if (teeTime) {
      return [...(teeTime?.orderBookings || []), ...(teeTime?.cancelledOrderBookings || [])]
    }
    return []
  }, [teeTime])

  useQueries(
    allOrderBookings.map((booking) => ({
      queryKey: [CLUB_QUERIES.BOOKINGS, booking.uuid, teeTime, state.teeTime],
      queryFn: () => {
        return getBookingWithRelations({
          bookingUuid: booking.uuid,
          deserialize: true,
          includedRelations: [
            BOOKING_RELATIONS.BOOKING,
            BOOKING_RELATIONS.ITEMS,
            BOOKING_RELATIONS.PAYMENTS,
            BOOKING_RELATIONS.REFUNDS,
            BOOKING_RELATIONS.INVENTORY_SCHEDULES,
          ],
          includedBookingGroups: [BOOKING_GROUPS.ALL],
          includedItemGroups: [ITEM_GROUPS.ALL],
        })
      },
      enabled:
        !!teeTime &&
        allOrderBookings.length &&
        !!state.teeTime &&
        !fromBookingsPage &&
        !singleBooking,
      onSuccess: (data) => {
        let loadedBooking

        if (!isBookingConfirmed(data)) {
          const cancelData = JSON.parse(
            getSessionStorage(`${STORAGE_KEYS.DELAYED_MESSAGED_STORE_KEY}-${data.uuid}`)
          )
          if (cancelData) {
            setAutoCancelData({ [data.uuid]: cancelData })
          }
        }

        if (state.bookings && state.bookings[data.id]) {
          loadedBooking = { ...data, muteNotification: state.bookings[data.id].muteNotification }
        } else {
          loadedBooking = data
        }

        if (isBookingCancelled(loadedBooking)) {
          if (bookingHasAtleastOneAssigned(loadedBooking)) {
            dispatch({ type: actions.CANCELED_BOOKING_UPDATED, payload: loadedBooking })
          }
        } else {
          dispatch({ type: actions.BOOKING_UPDATED, payload: loadedBooking })
          dispatch({ type: actions.BOOKINGS_SET_LOADER, payload: false })
        }
        dispatch({ type: actions.CANCELED_BOOKINGS_SET_LOADER, payload: false })
      },
      onError: () => {
        addToast(t('sentences.couldNotLoadBookings'), { appearance: 'error' })
      },
    })) || []
  )

  useQuery(
    [
      CLUB_QUERIES.BOOKINGS,
      state?.course?.uuid,
      state?.teeTime?.from,
      state?.teeTime?.to,
      { 'state[]': ['new', 'fulfilled', 'reopened', 'partially_paid', 'partially_refunded'] },
    ],
    () => {
      return getMultipleBookingsWithRelations({
        courseUuid: state?.course?.uuid,
        startDate: new Date(state.teeTime.from),
        endDate: new Date(state.teeTime.to),
        includedRelations: [
          BOOKING_RELATIONS.PAYMENTS,
          BOOKING_RELATIONS.ITEMS,
          BOOKING_RELATIONS.BOOKING,
          BOOKING_RELATIONS.INVENTORY_SCHEDULES,
        ],
        includedItemGroups: [ITEM_GROUPS.ALL],
        includedBookingGroups: [
          BOOKING_GROUPS.LEGACY_BOOKING,
          BOOKING_GROUPS.PARTNERSHIP,
          BOOKING_GROUPS.CLUB,
          BOOKING_GROUPS.COURSE,
          BOOKING_GROUPS.CUSTOMER,
        ],
        useStrictEnd: true,
      })
    },
    {
      enabled:
        !!teeTimeUuid && !!state?.course && !!state?.teeTime && fromBookingsPage && !singleBooking,
      onError: () => {
        addToast(t('sentences.couldNotLoadBookings'), { appearance: 'error' })
      },
      onSuccess: (data) => {
        let filteredBookings = data

        const bookingsObject = arrayToObject(
          filteredBookings.map((booking) => {
            if (!isBookingConfirmed(booking)) {
              const cancelData = JSON.parse(
                getSessionStorage(`${STORAGE_KEYS.DELAYED_MESSAGED_STORE_KEY}-${booking.uuid}`)
              )
              if (cancelData) {
                setAutoCancelData({ [booking.uuid]: cancelData })
              }
            }

            if (state.bookings && state.bookings[booking.id]) {
              return { ...booking, muteNotification: state.bookings[booking.id].muteNotification }
            } else {
              return booking
            }
          })
        )
        dispatch({ type: actions.BOOKINGS_UPDATED, payload: bookingsObject })
      },
    }
  )

  const { isFetching: isLoadingHistorical } = useQuery(
    [
      CLUB_QUERIES.BOOKINGS,
      state?.course?.uuid,
      state?.teeTime?.from,
      state?.teeTime?.to,
      {
        'state[]': ['canceled', 'refunded'],
      },
    ],
    () => {
      return getMultipleBookingsWithRelations({
        courseUuid: state?.course?.uuid,
        startDate: new Date(state.teeTime.from),
        endDate: new Date(state.teeTime.to),
        orderStates: [ORDER_STATES.CANCELED, ORDER_STATES.REFUNDED],
        includedRelations: [
          BOOKING_RELATIONS.PAYMENTS,
          BOOKING_RELATIONS.ITEMS,
          BOOKING_RELATIONS.REFUNDS,
          BOOKING_RELATIONS.PROMOTIONS,
          BOOKING_RELATIONS.INVENTORY_SCHEDULES,
          BOOKING_RELATIONS.BOOKING,
        ],
        includedItemGroups: [ITEM_GROUPS.ALL],
        includedBookingGroups: [
          BOOKING_GROUPS.LEGACY_BOOKING,
          BOOKING_GROUPS.PARTNERSHIP,
          BOOKING_GROUPS.CLUB,
          BOOKING_GROUPS.COURSE,
          BOOKING_GROUPS.CUSTOMER,
        ],
        useStrictEnd: true,
      })
    },
    {
      enabled:
        (!!teeTime || !!teeTimeUuid) &&
        !!state?.course &&
        !!state?.teeTime &&
        showHistorical &&
        !singleBooking,
      onSuccess: (data) => {
        let filtered = data.filter((x) => bookingHasAtleastOneAssigned(x))

        dispatch({ type: actions.CANCELED_BOOKINGS_UPDATED, payload: filtered })
        if (fromBookingsPage) {
          dispatch({ type: actions.BOOKINGS_SET_LOADER, payload: false })
        }
      },
      keepPreviousData: true,
    }
  )

  const { data: golfCartProduct } = useQuery(
    [CLUB_QUERIES.PRODUCTS, state?.golfClub?.id, 'single'],
    () => {
      dispatch({ type: actions.GOLF_CARTS_UPDATING })
      return getProductsApi({ golf_club_id: state.golfClub.id })
    },
    {
      enabled: !!state?.golfClub?.id,
      onError: () => {
        addToast(t('sentences.couldNotLoadAvailableGolfCarts'), { appearance: 'warning' })
        dispatch({ type: actions.GOLF_CARTS_UPDATED, payload: null })
      },
      onSuccess: (data) => {
        if (!data) {
          dispatch({ type: actions.GOLF_CARTS_UPDATED, payload: null })
        }
      },
      select: (data) => {
        const golfCartProduct = data.find((product) => product?.code === 'golf_cart')
        if (!golfCartProduct?.id || golfCartProduct?.product_variants?.length <= 0) {
          return null
        } else {
          return golfCartProduct
        }
      },
    }
  )

  const { refetch: refetchGolfCarts } = useQuery(
    [
      CLUB_QUERIES.PRODUCT_AVAILABILITY,
      golfCartProduct?.id,
      state?.course?.id,
      state?.teeTime?.from,
    ],
    () => {
      dispatch({ type: actions.GOLF_CARTS_UPDATING })
      return getProductAvailability(golfCartProduct.id, {
        time: m(state.teeTime.from).toISOString(),
        course: state.course.id,
      })
    },
    {
      enabled: !!golfCartProduct?.id && !!state?.course?.id && !!state?.teeTime?.from,
      onError: (err) => {
        if (err?.detail === 'Warehouse was not found') {
          dispatch({ type: actions.GOLF_CARTS_UPDATED, payload: null })
          return
        }
        addToast(t('sentences.couldNotLoadAvailableGolfCarts'), { appearance: 'warning' })
        dispatch({ type: actions.GOLF_CARTS_UPDATED, payload: null })
      },
      onSuccess: (data) => {
        dispatch({
          type: actions.GOLF_CARTS_UPDATED,
          payload: {
            product: golfCartProduct,
            variants: data,
          },
        })
      },
    }
  )

  useQuery(
    [CLUB_QUERIES.PARTNER_TYPES, state?.golfClub?.id],
    () => {
      dispatch({ type: actions.TAGS_UPDATING })
      return getPartnerTypes(state.golfClub.id)
    },
    {
      enabled: !!state?.golfClub?.id,
      onSuccess: (data) => {
        dispatch({ type: actions.TAGS_UDPATED, payload: data })
      },
    }
  )

  useEffect(() => {
    if (
      reserve &&
      state.teeTime &&
      state.teeTimePrice &&
      state.teeTime?.available_slots &&
      isFirstRender.current === true &&
      ((state.bookings && teeTimeUuidProp && !teeTime) ||
        (!teeTimeUuidProp && !teeTime.orderBookings?.length))
    ) {
      if (
        (state.bookings &&
          teeTimeUuidProp &&
          !teeTime &&
          Object.keys(state.bookings)?.length > 0) ||
        state.teeTime.available_slots <= 0
      ) {
        if (reserveSlots) handleOnReserveBooking(reserveSlots)
        isFirstRender.current = false
      } else {
        handleOnReserveBooking(reserveSlots || state.teeTime.available_slots)
        isFirstRender.current = false
      }
    }
  }, [
    state.bookings,
    state.teeTime,
    state.teeTimePrice,
    reserve,
    teeTimeUuidProp,
    teeTime,
    reserveSlots,
  ])

  /**
   * handle key press events
   */
  useEffect(() => {
    if (
      keyEvent &&
      keyEvent?.altKey &&
      keyEvent.code.includes('Digit') &&
      keyEvent.srcElement?.nodeName !== 'INPUT'
    ) {
      handleOnReserveBooking(parseInt(keyEvent.code[5]))
    }
    if (keyEvent?.code === 'Escape') {
      handleOnRequestClose()
    }
  }, [keyEvent])

  /** ---------------------------------- */
  /** ------------- METHODS ------------ */
  /** ---------------------------------- */

  const handleUpdateBooking = useCallback(
    async (bookingUuid, updateTeeTime) => {
      await getBooking(bookingUuid, state.bookings)
      if (updateTeeTime) {
        getTeeTime()
      }
    },
    [state.bookings]
  )

  const onNotesUpdate = useCallback(
    async (notes, cb) => {
      const [res, err] = await to(saveNotes(state.teeTime.uuid, { notes: JSON.stringify(notes) }))
      if (res) {
        getTeeTime()
      }
      if (err) {
        addToast(t('sentences.couldNotSaveNotes'), { appearance: 'error' })
      }
    },
    [state, addToast, getTeeTime, t]
  )

  const handleOnReserveBooking = useCallback(
    async (numberOfSlots) => {
      if (isOnReservation) return
      if (numberOfSlots > state?.teeTime?.available_slots) {
        addToast(t('sentences.notEnoughSlots'), { appearance: 'error' })
        return
      }
      if (state?.teeTime?.id) {
        setOnReservation(true)
        dispatch({ type: actions.BOOKING_RESERVING })

        let cancelData = null

        const [res, err] = await to(
          reserveBooking(
            state.teeTime.uuid,
            {
              slots_number: numberOfSlots,
            },
            (headers) => {
              cancelData = headers
            }
          )
        )
        setOnReservation(false)

        const [bookingRes, bookingErr] = await to(
          getBookingWithRelations({
            bookingUuid: res?.uuid,
            deserialize: true,
            includedRelations: [
              BOOKING_RELATIONS.BOOKING,
              BOOKING_RELATIONS.ITEMS,
              BOOKING_RELATIONS.PAYMENTS,
              BOOKING_RELATIONS.REFUNDS,
              BOOKING_RELATIONS.INVENTORY_SCHEDULES,
            ],
            includedBookingGroups: [BOOKING_GROUPS.ALL],
            includedItemGroups: [ITEM_GROUPS.ALL],
          })
        )

        if (res && bookingRes) {
          setAutoCancelData({ [res.uuid]: cancelData })
          setSessionStorage(
            `${STORAGE_KEYS.DELAYED_MESSAGED_STORE_KEY}-${res.uuid}`,
            JSON.stringify(cancelData)
          )

          dispatch({ type: actions.BOOKING_RESERVED, payload: { ...res, ...bookingRes } })
          dispatch({ type: actions.CANCELED_BOOKINGS_SET_LOADER, payload: false })
          dispatch({ type: actions.BOOKINGS_SET_LOADER, payload: false })
          getTeeTime()
        }
        if (err || bookingErr) {
          dispatch({ type: actions.BOOKING_RESERVED, payload: null })
          if (err?.violations) {
            displayToasts(err?.violations)
          } else if (
            err?.message === 'Number of rounds is out of limit' ||
            err?.detail === 'Number of rounds is out of limit'
          ) {
            addToast(t('sentences.maxNumberOfRoundsReached'), { appearance: 'error' })
          } else if (
            err?.message === 'Play value is out of limit' ||
            err?.detail === 'Play value is out of limit'
          ) {
            addToast(t('sentences.maxPlayValueReached'), { appearance: 'error' })
          } else {
            addToast(t('sentences.couldNotReserveBooking'), { appearance: 'error' })
          }
        }
      }
    },
    [isOnReservation, state?.teeTime, state?.bookings]
  )

  const handleOnConfirmBooking = useCallback(
    async (booking) => {
      const unassignedItems = getBookingSlotItems(booking).filter((item) => !itemIsAssigned(item))

      track({
        event_type: AMPLITUDE_EVENTS.CONFIRM_BOOKING_TAPPED,
        event_properties: {
          [AMPLITUDE_EVENTS.PLAYERS_ADDED]:
            booking?.booking?.booking_participants.length - unassignedItems.length,
        },
      })

      if (unassignedItems?.length > 0) {
        setShowBookingConfirmationPopup(booking)
      } else {
        confirmBooking(booking)
      }
    },
    [state.bookings, setShowBookingConfirmationPopup, getBookingSlotItems]
  )

  const handleRemoveCart = useCallback(
    (cart) => {
      setRemovedGolfCart(cart)
      refetchGolfCarts()
    },
    [state.bookings]
  )

  const handleMarkAsPaid = useCallback(
    async (booking) => {
      track(AMPLITUDE_EVENTS.MARKED_AS_PAID_BOOKING_TAPPED)
      const [resOne, errOne] = await to(updateBookingPayOnSiteStatus(booking.uuid, true))
      if (!resOne || errOne) {
        addToast(t('sentences.couldNotMarkAsPaid'), { appearance: 'error' })
        return
      }

      const [res, err] = await to(markBookingAsPaid(booking.uuid))
      if (!res || err) {
        addToast(t('sentences.couldNotMarkAsPaid'), { appearance: 'error' })
        return
      }

      handleUpdateBooking(booking.uuid)
    },
    [state.bookings]
  )

  const removeUnassignedSlotsAndConfirm = async (booking) => {
    setConfirmingBooking(true)

    const unassignedItems = getBookingSlotItems(booking).filter((item) => !itemIsAssigned(item))

    const [res, err] = await to(
      Promise.all(
        unassignedItems.map(async (item, index) => {
          return await new Promise((resolve, reject) => {
            let isOnMute = booking.muteNotification
            if (typeof isOnMute !== 'boolean') {
              isOnMute = DateHelpers.isPastBooking(new Date(booking.booking.start_time))
            }
            setTimeout(() => {
              removeSlot(item?.slot?.uuid, isOnMute)
                .then((res) => resolve(res))
                .catch((err) => reject(err))
            }, 1500 * index)
          })
        })
      )
    )
    if (err || !res) {
      addToast(t('sentences.couldNotRemoveUnassignedSlots'), { appearance: 'error' })
      setConfirmingBooking(false)
      return
    }

    confirmBooking(booking)
  }

  const confirmBooking = async (booking) => {
    setConfirmingBooking(true)
    const { muteNotification, uuid } = booking
    let isOnMute = muteNotification
    if (typeof isOnMute !== 'boolean') {
      isOnMute = DateHelpers.isPastBooking(new Date(booking.booking.start_time))
    }
    const [res, err] = await to(confirmOrderBooking(uuid, null, isOnMute))
    if (res) {
      handleUpdateBooking(uuid).then(() => {
        addToast(t('sentences.bookingConfirmed'), { appearance: 'success' })
        getTeeTime()
        setConfirmingBooking(false)
        setShowBookingConfirmationPopup(false)
      })
    }
    if (err) {
      setConfirmingBooking(false)
      setShowBookingConfirmationPopup(false)
      if (err?.violations?.length > 0) {
        err.violations.forEach((err) => addToast(t(err.message), { appearance: 'error' }))
      } else {
        addToast(t('sentences.couldNotConfirmBooking'), { appearance: 'error' })
      }
    }
    return
  }

  const handleOnCancelBooking = useCallback(
    async (uuid, autoCanceled = false) => {
      setShowConfirmCancelBooking(false)
      if (!uuid) return
      const booking = getBookingByUuid(uuid)

      if (isBookingCancelled(booking)) {
        setAutoCancelData({ [booking.uuid]: null })
        removeSessionStorage(`${STORAGE_KEYS.DELAYED_MESSAGED_STORE_KEY}-${booking.uuid}`)
        return
      }

      if (booking) {
        setAutoCancelData({ [booking.uuid]: null })
        removeSessionStorage(`${STORAGE_KEYS.DELAYED_MESSAGED_STORE_KEY}-${booking.uuid}`)

        if (autoCanceled) {
          setShowBookingsCancelledPopup(true)
        }

        setCancelingBooking(true)
        const { uuid, muteNotification } = booking
        let isOnMute = muteNotification
        if (typeof isOnMute !== 'boolean') {
          isOnMute = DateHelpers.isPastBooking(new Date(booking.booking.start_time))
        }

        const overridePolicyViolations =
          isPast(new Date(booking?.booking?.last_cancellation_point_at)) &&
          isPast(new Date(booking?.booking?.grace_period_end))
            ? true
            : undefined

        const [res, err] = await to(cancelOrderBooking(uuid, isOnMute, overridePolicyViolations))
        if (res) {
          setCancelingBooking(false)
          getTeeTime()
          dispatch({
            type: actions.CANCELED_BOOKING_UPDATED,
            payload: res,
          })
          let newBookings = { ...state.bookings }
          delete newBookings[res.id]
          dispatch({
            type: actions.BOOKINGS_UPDATED,
            payload: newBookings,
          })
          addToast(t('sentences.bookingCanceled'), { appearance: 'warning' })
        }
        if (err) {
          setCancelingBooking(false)
          getTeeTime()
          addToast(t('sentences.bookingCanceled'), { appearance: 'warning' })
        }
      }
    },
    [state.bookings, t]
  )

  const handleOnRequestClose = (continueTo) => {
    let numberOfUnconfirmed = 0
    if (state.bookings) {
      numberOfUnconfirmed = objectToArray(state.bookings).filter(
        (x) => !isBookingConfirmed(x)
      ).length
    }

    if (numberOfUnconfirmed > 0) {
      setShowConfirmClose({ count: numberOfUnconfirmed, continueTo: continueTo || null })
    } else {
      onClose()
    }
  }

  const handleCancelUnconfirmedBookings = (continueTo) => {
    if (state.bookings) {
      const unconfirmedBookings = objectToArray(state.bookings).filter(
        (x) => !isBookingConfirmed(x)
      )
      unconfirmedBookings.forEach((booking) => {
        const { uuid } = booking
        cancelOrderBooking(uuid)
      })
    }

    if (continueTo && (location.pathname !== '/' || continueTo !== '/')) {
      history.push(continueTo)
    } else {
      onClose()
    }
  }

  const getBookingByUuid = (uuid) => {
    if (state.bookings) {
      return objectToArray(state.bookings).find((x) => x.uuid === uuid)
    }
    return null
  }
  const handleOnAddExtraSlot = useCallback(
    async (booking) => {
      const priceObject = getPriceObject(teeTime, state?.course?.is_use_dynamic_pricing)
      if (!priceObject) return
      const availableSlots = state.teeTime.available_slots

      if (availableSlots <= 0) {
        addToast(t('sentences.tooFewSlotLeftOnTeeTime'), { appearance: 'error' })
        return
      }

      addLoadingExtraSlots(booking.id)
      const [res, err] = await to(reserveExtraSlots(booking.uuid, 1, priceObject.amount))
      if (res) {
        addToast(t('sentences.slotAdded'), { appearance: 'success' })
      }
      if (err?.violations) {
        displayToasts(err?.violations)
      } else if (
        err?.message === 'Number of rounds is out of limit' ||
        err?.detail === 'Number of rounds is out of limit'
      ) {
        addToast(t('sentences.maxNumberOfRoundsReached'), { appearance: 'error' })
      } else if (
        err?.message === 'Play value is out of limit' ||
        err?.detail === 'Play value is out of limit'
      ) {
        addToast(t('sentences.maxPlayValueReached'), { appearance: 'error' })
      } else if (err) {
        addToast(t('sentences.couldNotReserveExtraSlot'), { appearance: 'error' })
      }

      handleUpdateBooking(booking.uuid, true).then(() => {
        removeLoadingExtraSlots(booking.id)
      })
    },
    [
      state.course?.is_use_dynamic_pricing,
      state.teeTime?.available_slots,
      t,
      handleUpdateBooking,
      teeTime,
    ]
  )

  const getPlayersForCopy = useCallback(
    (booking) => {
      let players = []

      getBookingSlotItems(booking).forEach(({ slot }) => {
        if (!slot) return
        const { type, stub_player, player, is_owner } = slot
        let playerType
        if (type === 'stub_player') {
          playerType = 'stub'
        } else if (player && player.uuid) {
          playerType = 'player'
        } else {
          return
        }

        players.push({
          type: playerType,
          player:
            playerType === 'stub'
              ? {
                  name: stub_player?.name || t('words.anonymous'),
                  email: stub_player?.email || '',
                  phone: stub_player?.phone || '',
                }
              : { ...player },
          isOwner: is_owner,
          source: 'copy',
        })
      })

      return players
    },
    [t]
  )

  const handleAssignCopiedPlayers = useCallback(
    async (booking, players) => {
      let availableSlots = []
      const availableTeeTimeSlots = state.teeTime.available_slots
      let neededSlots = 0

      let playersToAdd = players

      getBookingSlotItems(booking).forEach(({ slot }) => {
        if (itemIsAssigned(slot)) {
          players.forEach(({ player, type }) => {
            if (type === 'player' && player.uuid === slot?.player?.uuid) {
              playersToAdd = playersToAdd.filter((p) => p.player.uuid !== slot.player.uuid)
              addToast(
                t('sentences.thisPlayerAlreadyExistInThisBooking', {
                  name: `${player?.first_name || ''} ${player?.last_name || ''}`,
                }),
                { appearance: 'info' }
              )
            }
          })
          return
        }
        availableSlots.push(slot)
      })

      const numberOfPlayers = playersToAdd.length

      if (numberOfPlayers <= 0) {
        addToast(t('sentences.noPlayersToAdd'), { appearance: 'info' })
        return
      }

      if (availableSlots.length < numberOfPlayers) {
        neededSlots = numberOfPlayers - availableSlots.length

        if (neededSlots > availableTeeTimeSlots) {
          addToast(t('sentences.tooFewAvailableSlotsToAddPlayer'), { appearance: 'error' })
          return
        }

        const priceObject = getPriceObject(teeTime, state?.course?.is_use_dynamic_pricing)
        const [res, err] = await to(
          reserveExtraSlots(booking.uuid, neededSlots, priceObject?.amount)
        )
        if (res) {
          availableSlots = []
          getBookingSlotItems(res).forEach(({ slot }) => {
            if (itemIsAssigned(slot)) return
            availableSlots.push(slot)
          })
        }
        if (err || !res) {
          addToast(t('sentences.couldNotAddPlayers'), { appearance: 'error' })
        }
      }

      try {
        for (const [index, player] of playersToAdd.entries()) {
          await handleAssignPlayer(booking, availableSlots[index], player, false)
        }
      } catch (error) {
        // Nothing to do
      }

      addToast(t('sentences.playersAdded'), { appearance: 'success' })
      handleUpdateBooking(booking.uuid, true).then(() => {
        clearLoadingSlots()
      })
    },
    [state.teeTime?.available_slots, t, teeTime, state, handleUpdateBooking]
  )

  const handleAssignPlayer = async (booking, slot, playerObject, updateBooking = true) => {
    const { type, player, isOwner, source } = playerObject
    const { uuid: slotUuid } = slot
    let playerUuid

    addLoadingSlots(slotUuid)

    if (type === 'player') {
      playerUuid = player?.uuid
      let isOnMute = booking.muteNotification
      if (typeof isOnMute !== 'boolean') {
        isOnMute = DateHelpers.isPastBooking(new Date(booking.booking.start_time))
      }
      const [res, err] = await to(assignPlayerToSlot(slotUuid, playerUuid, false, isOnMute))
      if (err || !res) {
        if (err?.violations) {
          displayToasts(err?.violations)
        } else if (err?.detail === 'Player has reached a limit of simultaneous bookings') {
          addToast(
            t('sentences.thisPlayerHasReachedMaximumSimultaneousBookings', {
              name: getPlayerName(player),
            }),
            {
              appearance: 'error',
            }
          )
        } else if (err?.detail === 'Player has already been added to a tee time') {
          addToast(
            t('sentences.thisPlayerIsAlreadyInABookingInThisTeeTime', {
              name: getPlayerName(player),
            }),
            {
              appearance: 'error',
            }
          )
        } else {
          addToast(t('sentences.couldNotAssignPlayerToSlot'), { appearance: 'error' })
        }
        removeLoadingSlots(slotUuid)
        return
      }
    } else if (type === 'stub') {
      const { name, email, phone } = player

      let isOnMute = booking.muteNotification
      if (typeof isOnMute !== 'boolean') {
        isOnMute = DateHelpers.isPastBooking(new Date(booking.booking.start_time))
      }
      const [res, err] = await to(
        assignStubPlayerToSlot(slotUuid, { name, email, phone }, isOnMute)
      )
      if (err || !res) {
        addToast(t('sentences.couldNotAssignPlayerToSlot'), { appearance: 'error' })
        removeLoadingSlots(slotUuid)
        return
      }
    }

    if (
      (source === 'copy' && isOwner && !bookingHasOwner(booking)) ||
      (source !== 'copy' && !bookingHasOwner(booking))
    ) {
      const [resOwner, errOwner] = await to(setSlotOwner(slotUuid))
      if (errOwner || !resOwner) {
        addToast(t('sentences.couldNotAssignPlayerToSlot'), { appearance: 'error' })
        removeLoadingSlots(slotUuid)
        return
      }
    }
    if (updateBooking) {
      handleUpdateBooking(booking.uuid).then(() => {
        removeLoadingSlots(slotUuid)
      })
    }
  }

  const shouldShowConfirmCancel = (booking) => {
    const assginedItems = getBookingSlotItems(booking).filter((item) => itemIsAssigned(item))

    if (assginedItems?.length > 0) {
      setShowConfirmCancelBooking(booking.uuid)
      setBooking(getBookingByUuid(booking.uuid))
    } else {
      handleOnCancelBooking(booking.uuid)
    }
  }

  const handleAddGolfCart = useCallback(
    async (booking, golfCartId) => {
      setShowGolfCartSelectionModal(false)
      if (!booking && !golfCartId) return
      addLoadingGolfCartSlots(booking.id)
      setAddGolfCartButtonState(true)

      const [res, err] = await to(
        addUniqueProductToOrder(booking.uuid, { product_variant: golfCartId })
      )

      if (!res || err) {
        removeLoadingGolfCartSlots(booking.id)
        setAddGolfCartButtonState(false)

        if (err?.violations) {
          displayToasts(err?.violations)
        } else if (err?.detail === 'There is no available product variant') {
          addToast(t('sentences.thereAreNoAvailableGolfCarts'), { appearance: 'error' })
          dispatch({ type: actions.GOLF_CARTS_UPDATED, payload: null })
        } else {
          addToast(t('sentences.couldNotAddGolfCartToOrder'), { appearance: 'error' })
        }
        return
      }

      handleUpdateBooking(booking.uuid).then(() => {
        removeLoadingGolfCartSlots(booking.id)
        setAddGolfCartButtonState(false)
        addToast(t('sentences.golfCartAdded'), { appearance: 'success' })
        refetchGolfCarts()
      })
    },
    [t, handleUpdateBooking, dispatch, state.bookings]
  )

  const handleNavigationAway = (location) => {
    if (shouldBlockNavigation) {
      setShouldBlockNavigation(false)
      handleOnRequestClose(location.pathname)
      return false
    }
    return true
  }

  const handleChangeNotification = useCallback((booking, muteNotification) => {
    dispatch({
      type: actions.BOOKING_UPDATED,
      payload: { ...booking, muteNotification },
    })
  }, [])

  const canUseCancelBooking = useCallback(
    (booking) => {
      if (!role) {
        return true
      }
      if (role !== 'ROLE_PA') {
        return true
      }
      if (timeAllowsCancelBooking(booking)) {
        return true
      }
      return false
    },
    [role, timeAllowsCancelBooking]
  )

  const bookingCancellationLimitHours = useMemo(() => {
    if (booking) {
      return isBookingPaid(booking) || isBookingConfirmed(booking)
        ? differenceInHours(
            new Date(booking?.booking?.start_time),
            new Date(booking?.booking?.last_cancellation_point_at)
          )
        : booking?.course.booking_cancellation_limit_hours
    }
  }, [booking])

  const handleButtonClick = useCallback(
    (booking) => {
      if (!isBookingConfirmed(booking)) {
        track(AMPLITUDE_EVENTS.ABORT_BOOKING_TAPPED)
      }

      if (isBookingConfirmed(booking)) {
        if (
          isBookingPartiallyPaid(booking) ||
          isBookingPaid(booking) ||
          isBookingPartiallyRefunded(booking)
        ) {
          track(AMPLITUDE_EVENTS.REFUND_AND_CANCEL_BOOKING_TAPPED)
        } else {
          track(AMPLITUDE_EVENTS.CANCEL_BOOKING_TAPPED)
        }
      }

      shouldShowConfirmCancel(booking)
    },
    [booking]
  )

  /** --------------------------------- */
  /** ------------- RENDER ------------ */
  /** --------------------------------- */

  return (
    <React.Fragment>
      {objectToArray(state.bookings).filter((x) => !isBookingConfirmed(x)).length > 0 && (
        <Prompt when={true} message={handleNavigationAway} />
      )}
      <div className={cx(styles.container)}>
        <div className={styles.header}>
          <CloseIcon onClick={() => handleOnRequestClose()} className={styles.closeIcon} />
          <div className={styles.topRow}>
            <BookingHeader
              fromTime={state.teeTime?.from}
              loaders={state.loaders}
              bookings={state.bookings}
              course={state.course}
            />
            {getCurrentAppName() === APP_NAMES.CLUB_PORTAL &&
              state?.golfClub?.is_score_card_printing_enabled === true &&
              !state?.loaders?.bookings && (
                <Button
                  size="default"
                  width="auto"
                  className={styles.printButton}
                  text={t('sentences.printScoreCard')}
                  theme="gray"
                  onClick={() => setShowPrintScoreCard(true)}
                />
              )}
          </div>
        </div>

        <div className={cx(styles.inner)}>
          {!state.loaders.bookings &&
            !!objectToArray(state.bookings).length &&
            objectToArray(state.bookings).map((booking, index) => (
              <BookingRow
                booking={booking}
                index={index}
                openBookings={openBookings}
                state={state}
                handleUpdateBooking={handleUpdateBooking}
                accessTable={accessTable}
                autoCancelData={autoCancelData}
                cancelingBooking={cancelingBooking}
                addOpenBooking={addOpenBooking}
                removeOpenBooking={removeOpenBooking}
                handleRemoveCart={handleRemoveCart}
                hasLoadingExtraSlots={hasLoadingExtraSlots}
                hasLoadingGolfCartSlots={hasLoadingGolfCartSlots}
                loadingSlots={loadingSlots}
                handleOnAddExtraSlot={handleOnAddExtraSlot}
                confirmingBooking={confirmingBooking}
                setShowGolfCartSelectionModal={setShowGolfCartSelectionModal}
                isAddingGolfCartToBooking={isAddingGolfCartToBooking}
                showGolfCartSelectionModal={showGolfCartSelectionModal}
                removedGolfCart={removedGolfCart}
                handleAssignCopiedPlayers={handleAssignCopiedPlayers}
                getPlayersForCopy={getPlayersForCopy}
                teeTime={teeTime}
                isFetching={isFetching}
                onNotesUpdate={onNotesUpdate}
                canUseCancelBooking={canUseCancelBooking}
                handleMarkAsPaid={handleMarkAsPaid}
                handleOnConfirmBooking={handleOnConfirmBooking}
                handleButtonClick={handleButtonClick}
                handleChangeNotification={handleChangeNotification}
                handleAddGolfCart={handleAddGolfCart}
                handleOnCancelBooking={handleOnCancelBooking}
                hasCdhNumber={!!state.course?.cdh_id}
              />
            ))}
          {!state.loaders.bookings && !fromBookingsPage && (
            <div className={styles.historicalBookingsRow}>
              <Button
                className={cx(styles.confirmButton)}
                theme="default-outline"
                disabledTheme="gray-outline"
                width="auto"
                size="default"
                text={
                  showHistorical
                    ? t('sentences.hideHistoricalBookings')
                    : t('sentences.showHistoricalBookings')
                }
                onClick={() => {
                  setShowHistorical(!showHistorical)
                }}
              />
              {isLoadingHistorical && <PulseLoader showIf={true} color="#A6ACB5" />}
            </div>
          )}
          {showHistorical &&
            // !isLoadingHistorical &&
            (objectToArray(state.canceledBookings)?.length > 0
              ? objectToArray(state.canceledBookings).map((booking, index) => {
                  const bookingOpen = openBookings.includes(booking.id)
                  const numberOfSlots = getNumberOfSlots(booking)

                  return (
                    <div
                      key={booking.uuid}
                      className={cx(styles.singleBookingContainer, styles.isCancelled)}
                    >
                      <div className={cx(styles.topRow)}>
                        <p className={cx(styles.bookingName)}>
                          {getNextChar('@', index + 1)} - {numberOfSlots}{' '}
                          {t('words.player', { count: numberOfSlots })}
                        </p>
                        <PartnershipBookingSelect
                          className={cx(styles.partnershipSelect)}
                          partnerships={state.partnerships}
                          loading={state.loaders.partnerships}
                          booking={booking}
                          onRequestBookingUpdate={handleUpdateBooking}
                          canEdit={false}
                        />
                        <div className={cx(styles.statusContainer)}>
                          <div className={cx(styles.statusBox)}>
                            <div className={cx(styles.statusDot, styles.orange)}></div>
                            <p className={cx(styles.statusText)}>{t('words.canceled')}</p>
                          </div>
                        </div>
                      </div>
                      <BookingPlayersGrid
                        onExpandBooking={addOpenBooking}
                        onCloseBooking={removeOpenBooking}
                        booking={booking}
                        bookingSlotItems={getBookingSlotItems(booking)}
                        bookingCartItems={getBookingCartItems(booking)}
                        onRequestBookingUpdate={(bookingUuid, updateTeeTime) =>
                          handleUpdateBooking(bookingUuid, updateTeeTime)
                        }
                        onRequestOpenPaymentFold={() => addOpenBooking(booking.id)}
                        addingExtraSlot={hasLoadingExtraSlots(booking.id)}
                        addingGolfCart={hasLoadingGolfCartSlots(booking.id)}
                        loadingSlots={loadingSlots}
                        canEdit={false}
                        isCancelled={true}
                        tags={state.tags}
                        tagsLoading={state.loaders.tags}
                        hasCdhNumber={!!state.course?.cdh_id}
                      />
                      <div className={cx(styles.actionsRowsOne)}>
                        <PlayersControls
                          className={cx(styles.playerControls)}
                          keyModifier="bookingPlayerGrid"
                          players={getPlayersForCopy(booking)}
                          canEdit={false}
                          availableSlots={state.teeTime?.available_slots}
                        />
                        <p
                          className={cx(styles.bookingTotal)}
                          onClick={() =>
                            openBookings.includes(booking.id)
                              ? removeOpenBooking(booking.id)
                              : addOpenBooking(booking.id)
                          }
                        >
                          {t('sentences.bookingTotal')}:{' '}
                          <span>
                            {priceToLocal(
                              getBookingTotal(booking, true),
                              booking?.currency_code,
                              true
                            )}
                          </span>
                        </p>
                      </div>
                      <div
                        className={cx(styles.bookingFold, {
                          [styles.open]: bookingOpen,
                        })}
                      >
                        <BasicBox
                          title={t('bookingNotes.title')}
                          styles={{
                            container: cx(styles.notesBox),
                          }}
                        >
                          {!booking || !teeTime ? null : (
                            <BookingNotesCourses
                              isLoading={isFetching}
                              notes={state.teeTime?.notes}
                              onNotesUpdate={onNotesUpdate}
                              isCancelled={true}
                            />
                          )}
                        </BasicBox>
                        <BookingPaymentsTable
                          className={cx(styles.paymentsBox)}
                          booking={booking}
                          canEdit={false}
                        />
                      </div>

                      <div className={cx(styles.bookingFooter)}>
                        <div className={cx(styles.leftContainer)}>
                          <div
                            className={cx(styles.openBookingWrapper)}
                            onClick={() =>
                              openBookings.includes(booking.id)
                                ? removeOpenBooking(booking.id)
                                : addOpenBooking(booking.id)
                            }
                          >
                            <CaretDown
                              className={cx(styles.icon, { [styles.open]: bookingOpen })}
                            />

                            <p className={cx(styles.text)}>
                              {t('words.notes')} &nbsp;{' | '}&nbsp; {t('words.payment_plural')}
                            </p>
                          </div>
                        </div>
                        <div className={cx(styles.rightContainer)}></div>
                      </div>
                    </div>
                  )
                })
              : !isLoadingHistorical &&
                objectToArray(state.canceledBookings)?.length === 0 &&
                showHistorical && (
                  <div className={cx(styles.loaderContainer)}>
                    <div className={cx(styles.column, styles.noCancels)}>
                      <div>{t('sentences.noCanceledBookings')}</div>
                    </div>
                  </div>
                ))}
          {state.loaders.bookings && (
            <div className={cx(styles.loaderContainer)}>
              <div className={cx(styles.column)}>
                <PulseLoader showIf={true} color="#A6ACB5" />
                <p>{t('sentences.loadingBookings')}</p>
              </div>
            </div>
          )}
        </div>

        {!state.loaders.bookings && !singleBooking && (
          <div className={cx(styles.footer)}>
            <ReserveNewBookingButton
              className={styles.newBooking}
              teeTime={state.teeTime}
              teeTimePrice={state.teeTimePrice}
              loaders={state.loaders}
              onClick={handleOnReserveBooking}
            />
            {accessTable.EDIT_TEE_TIMES && EditTeeTimeComponent && (
              <EditTeeTimeComponent
                teeTime={state.teeTime}
                teeTimeCategories={teeTimeCategories}
                course={state.course}
                onRequestUpdateTeeTime={getTeeTime}
              />
            )}
          </div>
        )}
      </div>
      {getCurrentAppName() === APP_NAMES.CLUB_PORTAL &&
        showPrintScoreCard &&
        state.bookings &&
        state.course &&
        state?.golfClub?.is_score_card_printing_enabled === true &&
        state.teeTime && (
          <ScoreCardPrint
            bookings={state.bookings}
            golfCourse={state.course}
            golfClub={state.golfClub}
            teeTime={state.teeTime}
            onRequestClose={() => setShowPrintScoreCard(false)}
          />
        )}
      <ConfirmPopup
        visible={!!showConfirmCancelBooking}
        onClose={() => setShowConfirmCancelBooking(false)}
        onReject={() => setShowConfirmCancelBooking(false)}
        title={t('sentences.confirmCancellation')}
        titleIcon={TrashIcon}
        text={`${t('sentences.theBookingWillBeCanceledAndRefunded')} ${
          isPast(new Date(booking?.booking?.last_cancellation_point_at)) &&
          isPast(new Date(booking?.booking?.grace_period_end))
            ? t('sentences.lastCancellationPointPassed', {
                bookingCancellationLimit: bookingCancellationLimitHours,
              })
            : ''
        }`}
        acceptText={t('words.confirm')}
        onAccept={() => handleOnCancelBooking(showConfirmCancelBooking)}
        rejectText={t('words.abort')}
      />
      <ConfirmPopup
        visible={showConfirmClose && showConfirmClose?.count > 0}
        onClose={() => {
          setShowConfirmClose(null)
          setShouldBlockNavigation(true)
        }}
        title={t('sentences.unconfirmedBooking', { count: showConfirmClose?.count || 0 })}
        text={t('sentences.youHaveUnconfirmedBookingsThatWillCancel', {
          count: showConfirmClose?.count || 0,
        })}
        acceptText={t('sentences.closeAnyway')}
        acceptTheme="default"
        rejectText={t('words.cancel')}
        rejectTheme="none"
        onAccept={() => handleCancelUnconfirmedBookings(showConfirmClose?.continueTo || null)}
        onReject={() => {
          setShowConfirmClose(null)
          setShouldBlockNavigation(true)
        }}
      />
      <ConfirmPopup
        visible={showBookingConfirmationPopup}
        onClose={() => setShowBookingConfirmationPopup(false)}
        text={t('sentences.thereAreReservedSlotsThatWillBeRemoved')}
        options={[
          {
            text: t('words.cancel'),
            onClick: () => setShowBookingConfirmationPopup(false),
            theme: 'none',
          },
          {
            text: t('words.confirm'),
            onClick: () => removeUnassignedSlotsAndConfirm(showBookingConfirmationPopup),
            buttonProps: {
              loading: confirmingBooking,
              loaderStyle: 'pulse',
            },
          },
        ]}
      />
      <ConfirmPopup
        title={t('sentences.bookingsCancelled')}
        visible={showBookingsCancelledPopup}
        onClose={() => setShowBookingsCancelledPopup(false)}
        text={t('sentences.oneOrMoreBookingsHasBeenCancelledDueToInactivity')}
        onAccept={() => setShowBookingsCancelledPopup(false)}
        acceptText={t('words.ok')}
        acceptTheme="default"
      />
    </React.Fragment>
  )
}

BookingModal.propTypes = {
  teeTime: PropTypes.shape({
    orderBookings: PropTypes.array.isRequired,
    cancelledOrderBookings: PropTypes.array,
    id: PropTypes.number,
  }),
  teeTimeUuid: PropTypes.string,
  teeTimeCategories: PropTypes.array,
  onClose: PropTypes.func,
  reserveSlots: PropTypes.number,
  reserve: PropTypes.bool,
  openHistorical: PropTypes.bool,
  role: PropTypes.string,
  fromBookingsPage: PropTypes.bool,
}

export default BookingModal
